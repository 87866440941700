define("chimaera/pods/components/dashboard/bookmark-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4DoXNeT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"q-name\"],[12],[2,\"\\n  \"],[8,\"input\",[[24,\"placeholder\",\"Type a name for your bookmark...\"]],[[\"@type\",\"@value\",\"@classBinding\"],[\"text\",[32,0,[\"item\",\"bookmarkName\"]],\"changeDetected\"]],null],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"bc_button btn-save \",[30,[36,0],[[32,0,[\"showSaveButton\"]],\"show\"],null]]]],[24,4,\"button\"],[4,[38,1],[[32,0],\"saveNameChange\"],null],[12],[1,[30,[36,2],[\"dashboard.tabs.bookmarkTable.row.save\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"q-type\"],[12],[1,[30,[36,3],[[32,0,[\"item\",\"loadType\"]]],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"q-from\"],[12],[1,[32,0,[\"item\",\"from\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"q-to\"],[12],[1,[32,0,[\"item\",\"to\"]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"q-cta\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"k-button cta icon icon_arrowright\"],[24,4,\"button\"],[4,[38,1],[[32,0],[32,0,[\"doQuote\"]],[32,0,[\"item\",\"queryString\"]]],null],[12],[1,[30,[36,2],[\"dashboard.tabs.bookmarkTable.row.quote\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn_trash\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"delBookmark\"],null],[12],[10,\"span\"],[14,0,\"display-none\"],[12],[1,[30,[36,2],[\"dashboard.tabs.bookmarkTable.row.del\"],null]],[13],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\",\"uppercase\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/dashboard/bookmark-item/template.hbs"
    }
  });

  _exports.default = _default;
});