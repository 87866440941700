define("chimaera/services/schedule-with-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    selected: null,
    quantity: null,
    setSelected: function setSelected(swr, quantity) {
      this.set('selected', swr);
      this.set('quantity', quantity);
    }
  });

  _exports.default = _default;
});