define("chimaera/mirage/utils/status", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusTimestamps = _exports.statusTimestamp = void 0;

  var statusTimestamp = function statusTimestamp(status, changedAt) {
    var statusTimestamp = {
      status: status
    };

    if (!Ember.isBlank(changedAt)) {
      statusTimestamp.changedAt = changedAt;
    }

    return statusTimestamp;
  };

  _exports.statusTimestamp = statusTimestamp;

  var statusTimestamps = function statusTimestamps(createdAt, required) {
    var date = _luxon.DateTime.fromISO(createdAt);

    var created = date.toISO();
    var bookingReceived = required.includes('Booking received') ? date.plus({
      days: 1
    }).toISO() : null;
    var containerInGate = required.includes('Container in Gate') ? date.plus({
      days: 3
    }).toISO() : null;
    var atd = required.includes('ATD') ? date.plus({
      days: 4
    }).toISO() : null;
    var outForDelivery = required.includes('Out for Delivery') ? date.plus({
      days: 5
    }).toISO() : null;
    return [statusTimestamp('created', created), statusTimestamp('Booking received', bookingReceived), statusTimestamp('Container in Gate', containerInGate), statusTimestamp('ATD', atd), statusTimestamp('Out for Delivery', outForDelivery)];
  };

  _exports.statusTimestamps = statusTimestamps;
});