define("chimaera/mirage/endpoints/trip", ["exports", "chimaera/config/environment", "ember-cli-mirage/response"], function (_exports, _environment, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    // Get suppliers
    server.get("".concat(_environment.default.APP.TRIP_URL, "/suppliers"), function (schema) {
      return schema.suppliers.find(1);
    }); // Get equipment types

    server.get("".concat(_environment.default.APP.TRIP_URL, "/equipmentTypes"), function (schema) {
      return schema.equipment.all();
    }); // Get equipment list

    server.get("".concat(_environment.default.APP.TRIP_URL, "/equipment"), function (schema) {
      return schema.equipment.all();
    }); // Get LCL schedules with rates (POST)

    server.post("".concat(_environment.default.APP.TRIP_URL, "/lclScheduleWithRates"), function (schema) {
      return schema.schedules.where({
        type: 'lcl'
      });
    }); // Get FCL schedules with rates (POST)

    server.post("".concat(_environment.default.APP.TRIP_URL, "/fclScheduleWithRates"), function (schema) {
      return schema.schedules.where({
        type: 'fcl'
      });
    }); // Get schedules

    server.get("".concat(_environment.default.APP.TRIP_URL, "/multiLegScheduleWithRates"), function (schema) {
      return schema.schedules.where({
        type: 'fcl'
      });
    }); // Get additional services

    server.get("".concat(_environment.default.APP.TRIP_URL, "/chargeCodes"), function (schema) {
      return schema.chargeCodes.all();
    }); // Get locations

    server.get("".concat(_environment.default.APP.TRIP_URL, "/locations"), function (schema, request) {
      var query = request.queryParams.query;
      return schema.locations.where(function (location) {
        return location.matched.match(new RegExp(query, 'gi'));
      });
    }); // Share/Resend shared quote

    server.post("".concat(_environment.default.APP.TRIP_URL, "/storedQuote/share"), function (schema, request) {
      var _request$requestBody = request.requestBody,
          email = _request$requestBody.email,
          reference = _request$requestBody.reference;

      if (!email || !reference) {
        return new _response.default(400, {
          'Content-Type': 'application/json'
        }, {
          reason: 'Request was formatted incorrectly'
        });
      }

      return new _response.default(200, {
        'Content-Type': 'application/json'
      }, {
        reason: 'Quote shared'
      });
    }); // Get all shared quotes

    server.get("".concat(_environment.default.APP.TRIP_URL, "/storedQuote"), function (schema
    /*, request*/
    ) {
      return schema.storedQuotes.all();
    }); // Get specific shared quote

    server.get("".concat(_environment.default.APP.TRIP_URL, "/storedQuote/:id"), function (schema, request) {
      var id = request.params.id;

      if (!id) {
        return new _response.default(400, {
          'Content-Type': 'application/json'
        }, {
          reason: 'Request was formatted incorrectly'
        });
      }

      return new _response.default(200, {
        'Content-Type': 'application/json'
      }, {
        reason: 'Quote retrieved'
      });
    }); // Delete specific shared quote

    server.del("".concat(_environment.default.APP.TRIP_URL, "/storedQuote/:id"), function (schema, request) {
      var id = request.params.id;

      if (!id) {
        return new _response.default(400, {
          'Content-Type': 'application/json'
        }, {
          reason: 'Request was formatted incorrectly'
        });
      }

      return new _response.default(200, {
        'Content-Type': 'application/json'
      }, {
        reason: 'Delete successful'
      });
    });
  }
});