define("chimaera/validations/reset-password", ["exports", "ember-changeset-validations/validators", "chimaera/validators/password-contents"], function (_exports, _validators, _passwordContents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    password: [(0, _passwordContents.default)({
      min: 8
    }), (0, _validators.validatePresence)({
      presence: true,
      message: 'Password cannot be blank'
    })],
    confirmPassword: [(0, _validators.validateConfirmation)({
      on: 'password'
    })]
  };
  _exports.default = _default;
});