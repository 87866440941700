define("chimaera/mirage/factories/user", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    firstName: 'Ross',
    lastName: 'Haggart',
    email: 'ross@kontainers.co.uk',
    token: 'xxxxxxxxxx',
    accessToken: 'default token CHANGEME',
    companyId: null,
    avatar: null,
    company: null,
    isPrimary: true,
    kyc: true,
    kycRejected: false,
    notes: '',
    userCode: '0000',
    intercomHash: '',
    newUser: false
  });

  _exports.default = _default;
});