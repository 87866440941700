define("chimaera/components/core/integrations/chat/salesforce/script-override", ["exports", "@cetus/kontainers-ui-core/components/core/integrations/chat/salesforce/script-override/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _component.default;
    }
  });
});