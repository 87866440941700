define("chimaera/pods/components/login/oidc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2kuuiHwF",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[3,\" SSO button / erro \"],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"bclever-modal bc-access-modal bc-sso bclever-modal-open \",[32,0,[\"customLogoClass\"]]]]],[15,5,[32,0,[\"loginBackground\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"k-nav-bar\"],[12],[2,\"\\n    \"],[10,\"nav\"],[14,0,\"nav\"],[12],[2,\" \"],[10,\"a\"],[14,0,\"k-nav-toggle\"],[12],[10,\"span\"],[12],[13],[13],[2,\"\\n      \"],[10,\"ul\"],[12],[2,\"\\n        \"],[10,\"li\"],[12],[2,\"\\n          \"],[8,\"language-selector\",[],[[],[]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"row access-container sign-in \",[30,[36,0],[[32,0,[\"isLoading\"]],\"k-loading\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n      \"],[3,\" error \"],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"alert-box general-error \",[30,[36,0],[[32,0,[\"isError\"]],\"\",\"display-none\"],null]]]],[14,\"data-bindattr-10\",\"10\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,1],[\"login.error.oauth2\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[3,\" /error \"],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"bc_button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"followRedirect\"],null],[12],[1,[30,[36,1],[\"login.actions.oauth2\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"shared/powered-by\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/login/oidc/template.hbs"
    }
  });

  _exports.default = _default;
});