define("chimaera/pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Services
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // State
    queryParamsAtLogin: {},
    // Lifecycle Hooks
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var to = transition.to;
      var queryParams = to.queryParams;
      Ember.set(this, 'queryParamsAtLogin', queryParams);

      if (window.location.href.includes('/login#')) {
        window.location = window.location.href.replace('/login#', '/login?');
      }
    },
    model: function model(params) {
      if (params.reg === true) {
        this.flashMessages.success(this.intl.t('login.registerSuccess'));
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'queryParamsAtLogin', this.queryParamsAtLogin);
    }
  });

  _exports.default = _default;
});