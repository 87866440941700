define("chimaera/mirage/factories/booking-address", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    companyName: function companyName() {
      return _faker.default.company.companyName();
    },
    firstName: function firstName() {
      _faker.default.name.firstName();
    },
    lastName: function lastName() {
      return _faker.default.name.lastName();
    },
    email: function email() {
      return _faker.default.internet.email();
    },
    address1: function address1() {
      return _faker.default.address.streetAddress();
    },
    address2: function address2() {
      return _faker.default.address.streetName();
    },
    cityTown: function cityTown() {
      return _faker.default.address.city();
    },
    postCode: function postCode() {
      return _faker.default.address.zipCode();
    },
    country: function country() {
      return _faker.default.address.country();
    },
    addressText: function addressText() {
      return "\n      ".concat(this.address1, "\n\n      ").concat(this.address2, "\n\n      ").concat(this.cityTown, "\n\n      ").concat(this.postCode, "\n\n      ").concat(this.country, "\n    ");
    },
    phone: function phone() {
      return _faker.default.phone.phoneNumber();
    },
    customerCode: function customerCode() {
      return _faker.default.random.number();
    }
  });

  _exports.default = _default;
});