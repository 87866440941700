define("chimaera/pods/components/bol/bol-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZUKzv7o",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"session\",\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"address-bar\",[],[[\"@openContacts\",\"@dbStore\",\"@doSendSuccessMessage\",\"@doSendFailMessage\",\"@doSetSelectedAddy\",\"@addressContext\"],[[32,0,[\"openContacts\"]],[32,0,[\"store\"]],[30,[36,0],[[32,0],\"successMessageAction\"],null],[30,[36,0],[[32,0],\"errorMessageAction\"],null],[30,[36,0],[[32,0],\"setAddy\"],null],[32,0,[\"addressContext\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"ladding_holder animate-in\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content_holder\"],[12],[2,\"\\n      \"],[8,\"bill-template\",[],[[\"@model\",\"@supplier\",\"@saveButton\",\"@doSaveChanges\",\"@doOnSaveError\",\"@alertErrors\",\"@doToggleContactsWithContext\",\"@bolTemplate\",\"@companyLogo\",\"@supplierFullName\",\"@bookingNotes\",\"@volumeUnits\"],[[32,0,[\"model\"]],[32,0,[\"model\",\"bookingDetailMainLeg\",\"supplier\"]],[30,[36,3],[[30,[36,2],[[32,0,[\"model\",\"status\"]],[32,0,[\"allCompleteStatusses\"]]],null]],null],[30,[36,0],[[32,0],\"saveChanges\"],null],[30,[36,0],[[32,0],\"onError\"],null],[32,0,[\"alertErrors\"]],[30,[36,0],[[32,0],\"toggleContactsForBol\"],null],[32,0,[\"wp\",\"bolTemplate\"]],[32,0,[\"wp\",\"wp\",\"companyLogo\"]],[32,0,[\"supplierFullName\"]],[32,0,[\"bookingNotes\"]],[32,0,[\"volumeUnits\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"contains\",\"not\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/bol/bol-page/template.hbs"
    }
  });

  _exports.default = _default;
});