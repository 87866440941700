define("chimaera/pods/confirmation/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    bookingService: Ember.inject.service('booking'),
    queryParams: {
      bookingType: {
        refreshModel: true
      },
      bookingId: {
        refreshModel: true
      },
      storedQuoteId: {
        refreshModel: true
      },
      storedQuoteRef: {
        refreshModel: true
      },
      offlineQuoteRef: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (Ember.isBlank(this.get('bookingService.basket'))) {
        transition.abort();
        this.transitionTo('index');
      }

      this._super(transition);
    },
    model: function model() {
      return this.get('bookingService.basket');
    }
  });

  _exports.default = _default;
});