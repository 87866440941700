define("chimaera/mirage/endpoints/config", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _default(server) {
    // Get currency config
    server.get("".concat(_environment.default.APP.CONFIG_URL, "/config/kontainers/tenant/booking/currency"), function (schema) {
      var tenantName = server.tenantName;
      var key = 'kontainers.tenant.booking.currency';
      var value = schema.welcomePacks.findBy({
        tenantName: tenantName
      }).attrs.config[key];
      return _defineProperty({}, key, value);
    });
  }
});