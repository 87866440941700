define("chimaera/models/booking-file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    fileName: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    visible: _emberData.default.attr('boolean'),
    bookingId: _emberData.default.attr('string'),
    uploadedBy: _emberData.default.attr('string'),
    displayFileName: Ember.computed('fileName', function () {
      if (!this.id) {
        return this.fileName;
      }

      var extractAfterLastSlash = /^.*\/([^/]*)$/.exec(this.fileName);

      if (extractAfterLastSlash) {
        return extractAfterLastSlash[1];
      } else {
        // Fallback to legacy file naming convention
        return this.fileName.split('_').slice(1).join('_');
      }
    }),
    isInvoice: Ember.computed('description', function () {
      return this.description === 'Invoice';
    }),
    isBolVerifiedCopy: Ember.computed('description', function () {
      return this.description === 'Bill of Lading – Verified copy';
    })
  });

  _exports.default = _default;
});