define("chimaera/services/changeset-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    geti18nText: function geti18nText(key, value) {
      if (key === 'name') {
        return this.intl.t('error.fieldBlank');
      } else if (key === 'email') {
        return this.intl.t('error.changeset.email');
      } else if (key === 'password' || key === 'newPassword' || key === 'currentPassword') {
        if (value.localeKey) {
          return this.intl.t("".concat(value.localeKey));
        } // backward compatibility


        return this.intl.t('error.changeset.passwordBlank');
      } else if (key === 'username') {
        return this.intl.t('error.changeset.username');
      } else if (key === 'firstName') {
        return this.intl.t('error.changeset.firstName');
      } else if (key === 'lastName') {
        return this.intl.t('error.changeset.lastName');
      } else if (key === 'customerRole') {
        return this.intl.t('error.fieldBlank');
      } else {
        return value;
      }
    }
  });

  _exports.default = _default;
});