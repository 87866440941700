define("chimaera/services/equipment-type", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    equipmentPromise: null,
    init: function init() {
      this._super.apply(this, arguments);

      var promise = this.ajax.request("".concat(_environment.default.APP.TRIP_URL, "/equipmentTypes")).then(function (eqs) {
        return eqs.equipment;
      });
      this.set('equipmentPromise', promise);
    },
    getNames: function getNames() {
      return this.equipmentPromise.then(function (eqs) {
        return eqs.map(function (element) {
          return element.name;
        });
      });
    },
    getEquipment: function getEquipment() {
      return this.equipmentPromise;
    },
    getIsoCode: function getIsoCode(equipment, name) {
      var found = equipment.find(function (element) {
        return element.name == name;
      });
      return found ? found.isoCode : name;
    }
  });

  _exports.default = _default;
});