define("chimaera/serializers/additional-service", ["exports", "chimaera/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _application.default.extend({
    normalize: function normalize(model, hash) {
      hash.longDescriptionCode = hash.uiDescriptionCode;
      hash.shortDescriptionCode = hash.uiHintCode;

      if (hash.costs && hash.costs.firstObject) {
        var amountsArr = hash.costs.mapBy('amountTotal').map(Number); // lowest value

        hash.fromAmount = Math.min.apply(Math, _toConsumableArray(amountsArr)); // sum of all costs

        hash.costsAmountTotal = amountsArr.reduce(function (a, b) {
          return a + b;
        }, 0);
      }

      hash.isDefault = hash.additionalService === 'includeByDefault';
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});