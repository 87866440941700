define("chimaera/pods/components/booking/packaging-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "szbyeM86",
    "block": "{\"symbols\":[\"suggestItem\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"search-holder typeahead-search \",[30,[36,2],[[32,0,[\"showResults\"]],\"show-results\"],null]]]],[12],[2,\"\\n  \"],[8,\"input\",[[16,\"oninput\",[30,[36,0],[[32,0],\"onKeyPress\"],null]],[16,\"placeholder\",[30,[36,3],[\"booking.containerDetails.commodity.typeOfPackagingPlaceholder\"],null]],[24,\"data-test-input\",\"typeOfPackaging\"]],[[\"@type\",\"@id\",\"@value\",\"@focusOut\"],[\"text\",[32,0,[\"packagingFieldId\"]],[32,0,[\"inputVal\"]],[30,[36,0],[[32,0],\"onFocusOut\"],null]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"search-results capped\"],[12],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"suggestions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"li\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"selectItem\",[32,1]],null],[12],[2,\"\\n          \"],[10,\"span\"],[12],[1,[30,[36,1],[[32,1,[\"description\"]]],null]],[13],[10,\"span\"],[14,0,\"pack-type-code\"],[12],[2,\"(\"],[1,[32,1,[\"code\"]]],[2,\")\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"k-loading \",[30,[36,2],[[32,0,[\"loaded\"]],\"display-none\"],null]]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"no-search-results \",[30,[36,2],[[32,0,[\"haveResults\"]],\"display-none\"],null]]]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon icon_search\"],[12],[1,[30,[36,3],[\"booking.containerDetails.commodity.noPackagingCode\"],null]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"bc_button action-close-no-results\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"dismiss\"],null],[12],[1,[30,[36,3],[\"booking.containerDetails.commodity.dismiss\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"titleize\",\"if\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/booking/packaging-field/template.hbs"
    }
  });

  _exports.default = _default;
});