define("chimaera/pods/booking/new/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ui-shared/utils/query-params"], function (_exports, _authenticatedRouteMixin, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    flashMessages: Ember.inject.service(),
    webStorage: Ember.inject.service(),
    bookingService: Ember.inject.service('booking'),
    intl: Ember.inject.service(),
    welcomePack: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      Ember.set(this, 'lastTransition', transition);
      var _transition$to$queryP = transition.to.queryParams,
          quoteId = _transition$to$queryP.quoteId,
          scheduleId = _transition$to$queryP.scheduleId,
          bookingId = _transition$to$queryP.bookingId;

      if (!quoteId && !scheduleId && !bookingId) {
        // cant do a booking if this is blank, can we?
        this.transitionTo('quotes.index.new');
        return;
      }
      /*
        Check if draft has been saved and if we need to set bookingId
        Needs to happen before _super
      */


      var webStorage = this.webStorage,
          bookingService = this.bookingService;
      var draftBooking = webStorage.getLocal('draft-booking');

      if (draftBooking) {
        draftBooking = JSON.parse(draftBooking);
        var queryParams = transition.to.queryParams;

        if (draftBooking.id) {
          var _draftBooking = draftBooking,
              id = _draftBooking.id;
          var filteredQueryParams = (0, _queryParams.filterEmptyQueryParams)(queryParams);

          if (!bookingService.mayRestoreDraftBooking(filteredQueryParams, draftBooking)) {
            return this._super.apply(this, arguments);
          }

          if (!bookingId || bookingId && id !== bookingId) {
            var newParams = _objectSpread(_objectSpread({}, transition.to.queryParams), {}, {
              bookingId: id
            });

            transition.abort();
            return this.replaceWith(this.routeName, {
              queryParams: newParams
            });
          }
        }
      }

      this._super.apply(this, arguments);
    },
    setupController: function setupController(controller) {
      var lastTransition = this.lastTransition;
      var restoreDraft = lastTransition.to.queryParams.restoreDraft;

      if (!lastTransition.from) {
        Ember.set(controller, 'didRefresh', true);
      }

      if (lastTransition.from && lastTransition.from.name === 'login') {
        Ember.set(controller, 'restoreDraft', true);
      }

      if (restoreDraft) {
        Ember.set(controller, 'restoreDraft', true);
      }

      this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.saveBookingDraft.cancelAll();
        controller.set('model', null);
        var ratesManuallyDisabled = this.welcomePack.ratesManuallyDisabled;

        if (ratesManuallyDisabled) {
          Ember.set(this.welcomePack, 'ratesManuallyDisabled', false);
        }
      }
    }
  });

  _exports.default = _default;
});