define("chimaera/pods/components/file-uploader/dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "khJpEYkt",
    "block": "{\"symbols\":[\"dropzone\",\"@queueName\",\"@accept\",\"@multiple\",\"@locale\"],\"statements\":[[8,\"file-dropzone\",[],[[\"@class\",\"@name\"],[\"dropzone\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"dz-default dz-message\"],[14,\"data-test-data\",\"dropzoneMessage\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"active\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[32,1,[\"valid\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[30,[36,2],[[30,[36,0],[[32,5],\"label.dropToUpload\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[30,[36,2],[[30,[36,0],[[32,5],\"label.invalidType\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"file-upload\",[[24,\"data-test-input\",\"dropzoneInput\"]],[[\"@name\",\"@for\",\"@accept\",\"@multiple\",\"@onfileadd\"],[[32,2],[30,[36,0],[[32,2],\"-uploader\"],null],[32,3],[32,4],[30,[36,1],[[32,0],\"onFileAdd\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,2],[[30,[36,0],[[32,5],\"label.dropHere\"],null]],null]],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"action\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/file-uploader/dropzone/template.hbs"
    }
  });

  _exports.default = _default;
});