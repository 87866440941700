define("chimaera/mixins/dashboard/booking-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    statusArray: Ember.computed('item.status', function () {
      var _this = this;

      var resultArray = [];
      var realStatus = this.get('item.status');
      var possibleStatus = ['booked', 'confirmed', 'shipped on board', 'arrived'];

      if (realStatus === 'proof of delivery') {
        realStatus = 'arrived';
      }

      var currentStatusIndex = possibleStatus.indexOf(realStatus);
      possibleStatus.forEach(function (s) {
        if (possibleStatus.indexOf(s) < currentStatusIndex) {
          resultArray.push(_this._generateStatusObject(true, false, s));
        } else if (possibleStatus.indexOf(s) === currentStatusIndex + 1) {
          resultArray.push(_this._generateStatusObject(false, true, s));
        } else if (possibleStatus.indexOf(s) === currentStatusIndex) {
          resultArray.push(_this._generateStatusObject(true, false, s));
        } else {
          resultArray.push(_this._generateStatusObject(false, false, s));
        }
      });
      return resultArray;
    }),
    _generateStatusObject: function _generateStatusObject(complete_state, status_current, status) {
      var isImport = true;
      var statusTranslation = [{
        key: 'booked',
        value: 'booking.status.booked'
      }, {
        key: 'confirmed',
        value: 'booking.status.confirmed'
      }, {
        key: 'confirm SI',
        value: 'booking.status.confirmSI'
      }, {
        key: 'shipped on board',
        value: 'booking.status.shippedOnBoard'
      }, {
        key: 'bill of lading',
        value: 'booking.status.billOfLadingApproved'
      }, {
        key: 'invoice',
        value: 'booking.status.invoiceAvailable'
      }, {
        key: 'arrived',
        value: 'booking.status.arrived'
      }];
      return {
        status_current: status_current,
        complete_state: complete_state,
        statusSt: statusTranslation.filter(function (v) {
          return v['key'] === status;
        })[0]['value'],
        statusRaw: statusTranslation.filter(function (v) {
          return v['key'] === status;
        })[0]['key'],
        bol: status === 'confirmed' || status === 'shipped on board',
        bol2: status === 'bill of lading',
        bookDelivery: status === 'truck departed' && isImport,
        paid: this.paymentComplete === true,
        invStage: status === 'invoice',
        statusWrapClass: status === 'customs cleared' || status === 'truck departed' || status === 'vessel departed' || status == 'invoice' || status == 'shipped on board',
        id: this.id
      };
    }
  });

  _exports.default = _default;
});