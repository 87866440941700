define("chimaera/models/sea-leg-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    billType: _emberData.default.attr('string'),
    billApproveDate: _emberData.default.attr('date-dd-mmmm-yyyy'),
    billApproved: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    billIssueDate: _emberData.default.attr('date-dd-mmmm-yyyy'),
    billIssueLocation: _emberData.default.attr('string'),
    billNo: _emberData.default.attr('string'),
    lloydsNumber: _emberData.default.attr('string'),
    closingDate: _emberData.default.attr('string'),
    logisticsDetail: _emberData.default.belongsTo('logistics-detail')
  });

  _exports.default = _default;
});