define("chimaera/pods/booking/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TgBZ5EOb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"booking/booking-notes\",[],[[\"@booking\",\"@showNotesBar\"],[[32,0,[\"model\"]],[32,0,[\"showNotesBar\"]]]],null],[2,\"\\n\\n\"],[6,[37,3],[[35,2,[\"enableNotes\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"k-button simple icon icon_document btn-show-notes\"],[15,\"onclick\",[30,[36,0],[[32,0],\"showNotesBar\"],null]],[14,4,\"button\"],[12],[1,[30,[36,1],[\"booking.notes\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"booking/booking-form\",[],[[\"@model\",\"@bookingNote\",\"@isEditMode\",\"@onSave\",\"@showNewBookingNote\"],[[32,0,[\"model\"]],[32,0,[\"bookingNote\"]],[32,0,[\"isEditMode\"]],[30,[36,0],[[32,0],\"onSave\"],null],false]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"welcomePack\",\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/booking/edit/template.hbs"
    }
  });

  _exports.default = _default;
});