define("chimaera/mixins/bol/bol-actions", ["exports", "chimaera/mixins/booking/order-saving", "ui-shared/mixins/alertbox-trigger", "chimaera/config/environment"], function (_exports, _orderSaving, _alertboxTrigger, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_orderSaving.default, _alertboxTrigger.default, {
    ajax: Ember.inject.service('ajax'),
    flashMessages: Ember.inject.service(),
    loadType: Ember.computed('model.loadType', {
      get: function get() {
        var loadType = this.model.loadType;
        return loadType || 'fcl';
      }
    }),
    approve: function approve() {
      var _this = this;

      this.approveSI().then(function () {
        _this.ajax.put("".concat(_environment.default.APP.BOOKING_URL, "/logisticsDetail/approveSI/").concat(_this.model.id)).then(function () {
          _this.transitionToRoute('dashboard', {
            queryParams: {
              bookingState: 'edited'
            }
          });
        }, function (err) {
          _this.flashMessages.error(_this.intl.t('dashboard.bol.billNotSaved'), {
            sticky: true
          });

          _this.validationErrors(err);
        });
      }, function (err) {
        _this.flashMessages.error(_this.intl.t('dashboard.bol.billNotSaved'), {
          sticky: true
        });

        _this.validationErrors(err);
      });
    },
    actions: {
      shareBolModal: function shareBolModal() {// this.validationErrors([]);
        // $.get(`${Config.APP.SERVER_URL}/bol/share_token/${this.get('model.id')}`).then((tokenHash) => {
        //   this.get('appController').openShareModal(tokenHash.token);
        // }, () => {
        //   this.validationErrors([{
        //     field: 'system',
        //     message: this.get('i18n').t('dashboard.bol.error.share')
        //   }]);
        // });
      },
      transitionToEditBooking: function transitionToEditBooking(order_id) {
        this.transitionToRoute("/booking/edit/".concat(order_id));
      },
      saveChanges: function saveChanges(override) {
        var _this2 = this;

        if (override === false) {
          this.approve();
        } else if (override === 'bol') {
          this.saveOrder(false).then(function () {
            return _this2.flashMessages.success(_this2.intl.t('dashboard.bol.billSaved'));
          }, function (err) {
            _this2.flashMessages.error(_this2.intl.t('dashboard.bol.billNotSaved'), {
              sticky: true
            });

            _this2.validationErrors(err);
          });
        } else {
          this.model.set('tAndC', true);
          this.saveOrder(true).then(function () {
            return _this2.flashMessages.success(_this2.intl.t('dashboard.bol.billSaved'));
          }, function (err) {
            _this2.flashMessages.error(_this2.intl.t('dashboard.bol.billNotSaved'), {
              sticky: true
            });

            _this2.validationErrors(err);
          });
        }
      }
    }
  });

  _exports.default = _default;
});