define("chimaera/pods/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tKLYeBir",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"accounts_holder\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content_holder\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row \"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"resetPass.header\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"reset-passwords\",[],[[\"@model\"],[[32,0,[\"model\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"shared/powered-by\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});