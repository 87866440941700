define("chimaera/pods/components/notice-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['k-top-banner'],
    classNameBindings: ['show', 'isNoticeTypeWarning:warn:info'],
    store: Ember.inject.service('store'),
    noticeService: Ember.inject.service('notice'),
    allowedAudiences: null,
    noticeAdapter: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.checkNotices();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.checkNotices();
    },
    checkNotices: function checkNotices() {
      if (!Ember.isBlank(this.get('noticeService.notice.noticeText')) && !this.get('noticeService.notice.paused') && this.allowedAudiences.includes(this.get('noticeService.notice.audience'))) {
        this.bodyNode.classList.add('with-banner');
        this.set('show', true);

        if (Ember.isBlank(this.get('noticeService.notice.dismissable'))) {
          this.set('noticeService.notice.dismissable', true);
        }
      } else {
        this.bodyNode.classList.remove('with-banner');
        this.set('show', false);
      }
    },
    isNoticeTypeWarning: Ember.computed('noticeService.notice.noticeType', {
      get: function get() {
        return this.get('noticeService.notice.noticeType') === 'warn';
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bodyNode', document.getElementsByTagName('body')[0]);
      this.set('noticeAdapter', this.store.adapterFor('notice'));
      this.set('allowedAudiences', ['customer', 'both']);
    },
    dismiss: function dismiss() {
      if (!Ember.isBlank(this.get('noticeService.notice'))) {
        this.bodyNode.classList.remove('with-banner');
        this.set('show', false);
        this.noticeAdapter.dismiss();
      }
    },
    actions: {
      closeBar: function closeBar() {
        this.dismiss();
      }
    }
  });

  _exports.default = _default;
});