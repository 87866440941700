define("chimaera/mirage/serializers/booking", ["exports", "chimaera/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var paginate = function paginate(a, size, page) {
    return a.slice(page * size, (page + 1) * size);
  };

  var bookingRelationships = ['consignorBookingAddress', 'consigneeBookingAddress', 'notifyBookingAddress', 'bookedByAddress', 'brokerageAddress', 'confirmedWithAddress', 'bookingItems', 'bookingCosts', 'logisticsDetails', 'references'];
  var bookingItemRelationships = ['bookingItemDetails'];

  var setRelationships = function setRelationships(parentModel, model, relationships) {
    var _iterator = _createForOfIteratorHelper(relationships),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var relationship = _step.value;

        if (model[relationship]) {
          if (Ember.isArray(model[relationship])) {
            parentModel[relationship] = model[relationship].models.reduce(function (a, m) {
              return [].concat(_toConsumableArray(a), [m.attrs]);
            }, []);
          } else {
            parentModel[relationship] = model[relationship].attrs;
          }
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return parentModel;
  };

  var _default = _application.default.extend({
    serialize: function serialize(bookingsCollection
    /*request*/
    ) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _application.default.prototype.serialize.apply(this, arguments);

      var booking = json.booking,
          bookings = json.bookings;

      if (bookings) {
        // Pagination
        var queryParams = this.request.queryParams;
        var page = queryParams.page,
            size = queryParams.size;

        if (size) {
          bookings = paginate(bookings, parseInt(size), parseInt(page));
        } // Relationships


        var _booking = bookings.reduce(function (a, booking, i) {
          var model = bookingsCollection.models[i];
          booking = setRelationships(booking, model, bookingRelationships);
          return [].concat(_toConsumableArray(a), [booking]);
        }, []);

        return {
          booking: _booking
        };
      }

      booking = setRelationships(booking, bookingsCollection, bookingRelationships);
      booking.bookingItems.reduce(function (a, bookingItem, i) {
        var model = bookingsCollection.bookingItems.models[i];
        bookingItem = setRelationships(bookingItem, model, bookingItemRelationships);
        return [].concat(_toConsumableArray(a), [bookingItem]);
      }, []);
      return {
        booking: booking
      };
    }
  });

  _exports.default = _default;
});