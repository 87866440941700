define("chimaera/mirage/fixtures/booking-reference-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props, object-curly-spacing */
  // should be JSON formatted
  var _default = [{
    "name": "agent",
    "displayName": "Agent",
    "readOnly": false
  }, {
    "name": "billOfLading",
    "displayName": "Bill of lading",
    "readOnly": false
  }, {
    "name": "branchCode",
    "displayName": "Branch code",
    "readOnly": false
  }, {
    "name": "consignee",
    "displayName": "Consignee",
    "readOnly": false
  }, {
    "name": "contract",
    "displayName": "Contract",
    "readOnly": false
  }, {
    "name": "delivery",
    "displayName": "Delivery",
    "readOnly": false
  }, {
    "name": "exporterReference",
    "displayName": "Exporter reference",
    "readOnly": false
  }, {
    "name": "freightForwarder",
    "displayName": "Freight forwarder",
    "readOnly": false
  }, {
    "name": "invoice",
    "displayName": "Invoice",
    "readOnly": false
  }, {
    "name": "laneID",
    "displayName": "Lane ID",
    "readOnly": false
  }, {
    "name": "order",
    "displayName": "Order",
    "readOnly": true
  }, {
    "name": "organizationCode",
    "displayName": "Organization code",
    "readOnly": false
  }, {
    "name": "poNumber",
    "displayName": "PO Number",
    "readOnly": false
  }, {
    "name": "shipper",
    "displayName": "Shipper",
    "readOnly": false
  }, {
    "name": "transaction",
    "displayName": "Transaction",
    "readOnly": false
  }];
  _exports.default = _default;
});