define("chimaera/pods/components/payment-charge-end/component", ["exports", "ui-shared/mixins/hide-charge"], function (_exports, _hideCharge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hideCharge.default, {
    // Services
    intl: Ember.inject.service(),
    currencyService: Ember.inject.service('currency'),
    bookingService: Ember.inject.service('booking'),
    wp: Ember.inject.service('welcome-pack'),
    // State
    payerOptions: null,
    chargeEnd: null,
    showDropdown: false,
    selectedPayer: '',
    payerType: null,
    disableSelect: false,
    // Computed Properties
    bookByUserRole: Ember.computed.alias('wp.bookByUserRole'),
    booking: Ember.computed.alias('bookingService.basket'),
    bookingFieldsToHide: Ember.computed.alias('wp.bookingFieldsToHide'),
    currencySymbol: Ember.computed('charges.[]', {
      get: function get() {
        if (this.booking) {
          var currency = this.booking.currency;

          if (currency) {
            return this.currencyService.getCurrencySymbol(currency);
          }
        }
      }
    }),
    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      var payerOptions = [{
        value: '',
        display: this.intl.t('booking.payment.cost.selectPayer')
      }, {
        value: 'consignor',
        // shipper
        display: this.intl.t('booking.payment.cost.setPayerConsignor')
      }, {
        value: 'consignee',
        display: this.intl.t('booking.payment.cost.setPayerConsignee')
      }, {
        value: 'notifiedParty',
        display: this.intl.t('booking.payment.cost.setPayerNotifiedParty')
      }, {
        value: 'bookedByParty',
        display: this.intl.t('booking.payment.cost.setPayerBookedByParty')
      }];
      Ember.set(this, 'payerOptions', payerOptions);

      this._setPayerOptions();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        chargeEnd: null,
        showDropdown: false
      });

      if (this.bookByUserRole && !Ember.isBlank(this.selectedPayer)) {
        Ember.set(this, 'disableSelect', true);
        var payerType = this.payerType,
            selectedPayer = this.selectedPayer;
        this.onPayerChange(payerType, selectedPayer);
      }
    },
    // Actions
    actions: {
      setPayer: function setPayer(item) {
        Ember.set(this, 'selectedPayer', item.value);
        var payerType = this.payerType,
            selectedPayer = this.selectedPayer;

        if (this.onPayerChange) {
          this.onPayerChange(payerType, selectedPayer);
        }
      }
    },
    // Methods
    _setPayerOptions: function _setPayerOptions() {
      var bookingFieldsToHide = this.bookingFieldsToHide,
          payerOptions = this.payerOptions;
      var options = bookingFieldsToHide.includes('notifyBookingAddress') ? payerOptions.filter(function (option) {
        return option.value !== 'notifiedParty';
      }) : payerOptions;
      Ember.set(this, 'payerOptions', options);
    }
  });

  _exports.default = _default;
});