define("chimaera/models/bookmark", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    bookmarkName: _emberData.default.attr('string'),
    queryString: _emberData.default.attr('string'),
    from: Ember.computed('queryString', function () {
      return JSON.parse(this.queryString).from;
    }),
    to: Ember.computed('queryString', function () {
      return JSON.parse(this.queryString).to;
    }),
    loadType: Ember.computed('queryString', function () {
      return JSON.parse(this.queryString).loadType;
    })
  });

  _exports.default = _default;
});