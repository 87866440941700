define("chimaera/mirage/fixtures/welcome-packs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props */
  // should be JSON formatted
  var _default = [{
    "id": "cd4813f2-f874-11e8-a2f8-878dc27f8782",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/cd4813f2-f874-11e8-a2f8-878dc27f8782/logo/500484f1-9cef-4d20-8b7d-c705057c8de6",
    "faviconPath": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/cd4813f2-f874-11e8-a2f8-878dc27f8782/favicon/f097e61d-5aab-4700-b05a-0b61fcd43c77",
    "tenantName": "Developer Staging",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.integrations.captcha.google.customer.enabled": "true",
      "kontainers.integrations.captcha.google.customer.siteKey": "6LeRCfwUAAAAAKQT1zYvX--fLJJMdJd9JHO32XWo",
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/privayc-and-conditionsFF",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-and-conditionsFF",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.tenant.booking.autoFillConsignor": "false",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.website.customer.url": "http://localhost:4200",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions#booking",
      "kontainers.liveperson.customer.code": "",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.booking.references": "contract:Contract:false;agent:Agent:false;exporterReference:Exporter Reference:false;freightForwarder:Freight Forwarder:false;transaction:Transaction:false;order:Quote:true;organizationCode:Organization Code:true;branchCode:Branch Code:true",
      "kontainers.tenant.booking.companyCopiedReferences": "organizationCode;branchCode",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "By clicking here you accept and confirm that you have read our {URL_REPLACE} including the acceptance to enter into a Confidential Negotiated Rate Agreement (NRA) with Pyramid Lines",
      "kontainers.tenant.booking.offsetDays.fcl": "1",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.tenant.customer.sharedQuotes.lcl": "false",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.website.color.primary": "#00ff",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#37c091",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "http://google.com",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial"
    }
  }, {
    "id": "b4a4e444-e9ad-11e9-86c7-abbe5265ca45",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/b4a4e444-e9ad-11e9-86c7-abbe5265ca45/logo/cb73e348-7f3b-4481-87fe-6c05bbe0bd06",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "Ceva Staging",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.tenant.booking.edit.disable.ourStatus": "confirmed",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.website.customer.privacyURL": "https://global.cevalogistics.com/PublishingImages/CEVA_Ocean_Freight_Forwarding_Terms_and_Conditions_Platform_111319.pdf",
      "kontainers.tenant.customer.localChargesLinks": "united states,https://kontainers-share-files.s3-eu-west-1.amazonaws.com/ceva/Additional_Charges.pdf;china,https://kontainers-share-files.s3-eu-west-1.amazonaws.com/ceva/Additional_Charges.pdf",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.website.admin.updateTariffs.lcl": "false",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.website.customer.shareQuoteTandCURL": "https://global.cevalogistics.com/PublishingImages/CEVA_Ocean_Freight_Forwarding_Terms_and_Conditions_Platform_111319.pdf",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "mtiwmzcn",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.tenant.booking.documentDropdown": "Commercial Invoice:Commercial Invoice;Packing List:Packing List;Certificate of Origin:Certificate of Origin;Export Declaration:Export Declaration;Fumigation:Fumigation",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://ceva.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "true",
      "kontainers.liveperson.customer.code": "",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.tenant.booking.references": "contract:Contract:false;agent:Agent:false;exporterReference:Exporter Reference:false;freightForwarder:Freight Forwarder:false;transaction:Transaction:false;order:Quote:true;organizationCode:Organization Code:true;branchCode:Branch Code:true",
      "kontainers.tenant.booking.companyCopiedReferences": "organizationCode;branchCode",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "true",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "By clicking here you accept and confirm that you have read our {URL_REPLACE} including the acceptance to enter into a Confidential Negotiated Rate Agreement (NRA) with Pyramid Lines",
      "kontainers.tenant.booking.offsetDays.fcl": "10",
      "kontainers.tenant.booking.lcl": "true",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.website.color.primary": "#ed1c23",
      "kontainers.tenant.booking.hideFields": "flexitank;soc;packageCount",
      "kontainers.website.color.secondary": "#000000",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial"
    }
  }, {
    "id": "dc3f8ccc-304c-11e9-a1d9-8fb91c802fe2",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/dc3f8ccc-304c-11e9-a1d9-8fb91c802fe2/logo/f070c7e3-ae60-47e8-a64e-fa31243de20b",
    "faviconPath": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/dc3f8ccc-304c-11e9-a1d9-8fb91c802fe2/favicon/bb63525b-373f-48e1-a5c7-b121493095b8",
    "tenantName": "Hirthe, Littel and Hartmann and Sons",
    "config": {
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "",
      "kontainers.tenant.booking.edit.disable.etdPlus": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.code": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.booking.offsetDays.lcl": "3",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy/",
      "kontainers.tenant.booking.air.enabled": "false",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "",
      "kontainers.tenant.customer.label.ffOverride": "",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.tenant.booking.companyCopiedReferences": "",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.edit.onSave.transitionToPayment": "false",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.tenant.booking.note.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceScriptUrl": "",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.tenant.customer.label.bcoOverride": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "true",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "jdgkr2c8",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://qa-staging.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.integrations.chat.salesforce.customer.enabled": "false",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "6LeRCfwUAAAAAKQT1zYvX--fLJJMdJd9JHO32XWo",
      "kontainers.liveperson.customer.code": "x",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "true",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "",
      "kontainers.website.color.primary": "#48D1CC",
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.tenant.booking.hideFields": "notifyBookingAddress",
      "kontainers.website.color.secondary": "#00bd88",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.oauth2.oidcAlgorithm": "azureAD",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "ac0a6a12f89853487d8b446e0e1118e1",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.file.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.localChargesLinks": "*,https://kontainers-share-files.s3-eu-west-1.amazonaws.com/ceva/Additional+Charges.pdf",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.tenant.booking.offsetDays.fcl": "3",
      "kontainers.auth.oauth2.oidcClaimsTemplate": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": ""
    }
  }, {
    "id": "8fcfcd14-f895-11e8-bb66-db9d30af2cc3",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/8fcfcd14-f895-11e8-bb66-db9d30af2cc3/logo/113e267d-f568-48ba-ac6e-c9c6c926f16c",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "Zim Staging",
    "config": {
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "",
      "kontainers.tenant.booking.edit.disable.etdPlus": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.code": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://www.zim.com/help/bl-terms-and-conditions",
      "kontainers.tenant.booking.offsetDays.lcl": "3",
      "kontainers.website.customer.cookiePolicyURL": "http://www.google.com",
      "kontainers.tenant.booking.air.enabled": "false",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "",
      "kontainers.tenant.customer.label.ffOverride": "",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.customer.background.url": "https://kontainers-share-files.s3-eu-west-1.amazonaws.com/zim/zim-login.jpg",
      "kontainers.tenant.booking.companyCopiedReferences": "",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.edit.onSave.transitionToPayment": "false",
      "kontainers.tenant.booking.showPayment": "false",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.tenant.booking.note.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceScriptUrl": "",
      "kontainers.website.customer.locales": "us",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.tenant.customer.label.bcoOverride": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "false",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "90",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://zimstaging.kontainers.io",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.integrations.chat.salesforce.customer.enabled": "false",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "",
      "kontainers.liveperson.customer.code": "73266703",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "",
      "kontainers.website.color.primary": "#333666",
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.tenant.booking.hideFields": "brokerage;expectedCargoReady",
      "kontainers.website.color.secondary": "#37489f",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.oauth2.oidcAlgorithm": "azureAD",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://www.zim.com/help/data-protection-policy",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "false",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.file.enabled": "true",
      "kontainers.liveperson.customer.divId": "LP_DIV_1544707408389",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.tenant.booking.offsetDays.fcl": "1",
      "kontainers.auth.oauth2.oidcClaimsTemplate": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": ""
    }
  }, {
    "id": "2735f2e2-20e4-11ea-9830-7f718e5189c7",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/2735f2e2-20e4-11ea-9830-7f718e5189c7/logo/0792dbd4-4fad-43bf-b340-49ecdfa14889",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "Ceva Automation",
    "config": {
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "",
      "kontainers.tenant.booking.edit.disable.etdPlus": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.code": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.booking.offsetDays.lcl": "2",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.tenant.booking.air.enabled": "true",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "",
      "kontainers.tenant.customer.label.ffOverride": "I'm Exporter",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.tenant.booking.companyCopiedReferences": "organizationCode;branchCode",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.edit.onSave.transitionToPayment": "false",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.tenant.booking.note.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceScriptUrl": "",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "true",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.tenant.customer.label.bcoOverride": "I'm Importer",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "false",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "true",
      "kontainers.website.customer.url": "https://ceva-auto.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "true",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.integrations.chat.salesforce.customer.enabled": "false",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "",
      "kontainers.liveperson.customer.code": "",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.lcl": "true",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "5",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.oauth2.oidcAlgorithm": "azureAD",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "true",
      "kontainers.mixpanel.customer.key": "f37fa5cabc7ecc421e22c701e46896ce",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.file.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.tenant.booking.offsetDays.fcl": "2",
      "kontainers.auth.oauth2.oidcClaimsTemplate": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": ""
    }
  }, {
    "id": "71759f5a-20e0-11ea-a490-9bde69b8ed83",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/71759f5a-20e0-11ea-a490-9bde69b8ed83/logo/4e43580b-eb5d-467e-8076-9246e8be1617",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "NO RATES Developer Staging",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.tenant.customer.label.bcoOverride": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "5729E000000CbGK",
      "kontainers.integrations.chat.salesforce.customer.code": "00D9E0000009GI9",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.booking.offsetDays.lcl": "3",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.tenant.booking.air.enabled": "false",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.website.admin.updateTariffs.lcl": "true",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "https://c.la2-c1cs-fra.salesforceliveagent.com/content",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "https://d.la2-c1cs-fra.salesforceliveagent.com/chat",
      "kontainers.tenant.customer.label.ffOverride": "",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.tenant.booking.companyCopiedReferences": "",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.tenant.booking.note.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceScriptUrl": "https://ceva-com-static.s3.amazonaws.com/ceva-salesforce-chat.js",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "true",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "false",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "5739E0000008PSe",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "https://ceva--service.my.salesforce.com",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "true",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "http://localhost:4400",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "true",
      "kontainers.integrations.chat.salesforce.customer.enabled": "true",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "",
      "kontainers.liveperson.customer.code": "",
      "kontainers.tenant.booking.references": "contract:Contract:false;agent:Agent:false;exporterReference:Exporter reference:false;freightForwarder:Freight forwarder:false;transaction:Transaction:false;order:Order:true",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.lcl": "true",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "https://service-cevalogistics.cs88.force.com",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "6",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.logo.openUrlNewTab": "false",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "https://ceva--service.my.salesforce.com/embeddedservice/5.0/esw.min.js",
      "kontainers.tenant.customer.hideCharges.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "Ceva_Chat_Group",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.file.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.tenant.booking.offsetDays.fcl": "4",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": "",
      "kontainers.tenant.booking.edit.onSave.transitionToPayment": "false",
      "kontainers.tenant.booking.edit.disable.etdPlus": "2"
    }
  }, {
    "id": "ae84fbde-321a-11ea-ad53-ebae7e0d27db",
    "companyLogo": "/assets/images/logo-placeholder.png",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "MetroSSO",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.auth.oauth2.metadata.url": "https://myvt.b2clogin.com/myvt.onmicrosoft.com/B2C_1_kontainers_signin/v2.0/.well-known/openid-configuration",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.auth.oauth2.callback.url": "https://metro-sso.kclient-staging.com/login",
      "kontainers.tenant.booking.maxQuoteWindowDays": "43",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.website.admin.updateTariffs.lcl": "false",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "4ab78a17-45c6-44ab-91f6-628fd7d9c4c6",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.booking.currency": "GBP:GBP:£;EUR:EUR:€;USD:USD:$",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://metro-SSO.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.liveperson.customer.code": "",
      "kontainers.auth.oauth2.flow": "id_token",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.tenant.booking.references": "contract:Contract:false;agent:Agent:false;exporterReference:Exporter Reference:false;freightForwarder:Freight Forwarder:false;transaction:Transaction:false;order:Quote:true;organizationCode:Organization Code:true;branchCode:Branch Code:true",
      "kontainers.tenant.booking.companyCopiedReferences": "organizationCode;branchCode",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.offsetDays.fcl": "5",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial"
    }
  }, {
    "id": "ae533df0-6cf9-11ea-9dba-6b9fc337c6f6",
    "companyLogo": "/assets/images/logo-placeholder.png",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "ZimSpot Staging",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.localChargesLinks": "*,https://www.zim.com/tools/local-charges",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.website.admin.updateTariffs.lcl": "false",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://zimspot.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.liveperson.customer.code": "",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.tenant.booking.references": "contract:Contract:false;agent:Agent:false;exporterReference:Exporter Reference:false;freightForwarder:Freight Forwarder:false;transaction:Transaction:false;order:Quote:true;organizationCode:Organization Code:true;branchCode:Branch Code:true",
      "kontainers.tenant.booking.companyCopiedReferences": "organizationCode;branchCode",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.offsetDays.fcl": "5",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial"
    }
  }, {
    "id": "3d554848-ee8a-11e9-8c90-07d6444f9ad3",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/3d554848-ee8a-11e9-8c90-07d6444f9ad3/logo/e0a2d191-413a-4311-9ac7-d684e8dbed85",
    "faviconPath": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/3d554848-ee8a-11e9-8c90-07d6444f9ad3/favicon/82c4edd1-4ebe-4b85-b732-3d20d9fc66aa",
    "tenantName": "QA Staging (AUTO)",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.tenant.customer.label.bcoOverride": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "",
      "kontainers.integrations.chat.salesforce.customer.code": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.tenant.booking.air.enabled": "false",
      "kontainers.website.admin.updateTariffs.lcl": "false",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "",
      "kontainers.tenant.customer.label.ffOverride": "",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.tenant.booking.companyCopiedReferences": "organizationCode;branchCode",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "false",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "false",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://qa-staging-auto.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "true",
      "kontainers.integrations.chat.salesforce.customer.enabled": "false",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "",
      "kontainers.liveperson.customer.code": "",
      "kontainers.tenant.booking.references": "contract:Contract:false;agent:Agent:false;exporterReference:Exporter Reference:false;freightForwarder:Freight Forwarder:false;transaction:Transaction:false;order:Quote:true;organizationCode:Organization Code:true;branchCode:Branch Code:true",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.offsetDays.fcl": "5",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": ""
    }
  }, {
    "id": "2a276394-f0f7-11ea-94dd-532dd5487590",
    "companyLogo": "/assets/images/logo-placeholder.png",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "oneline staging",
    "config": {
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.auth.oauth2.metadata.url": "https://v1.api.us.janrain.com/b9641c27-5229-4555-b46a-d6d0d8846fb4/login/.well-known/openid-configuration",
      "kontainers.auth.oauth2.callback.url": "https://oneline.kclient-staging.com/login",
      "kontainers.tenant.customer.label.bcoOverride": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "",
      "kontainers.tenant.booking.edit.disable.etdPlus": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "false",
      "kontainers.integrations.chat.salesforce.customer.code": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.booking.offsetDays.lcl": "3",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.tenant.booking.air.enabled": "false",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email company customer",
      "kontainers.auth.customer.implementation": "oidc",
      //userpass
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "",
      "kontainers.tenant.customer.label.ffOverride": "",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "5449612a-725b-4abd-aab3-39106f266f96",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "company",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.tenant.booking.companyCopiedReferences": "",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.edit.onSave.transitionToPayment": "true",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.tenant.booking.note.enabled": "false",
      "kontainers.integrations.chat.salesforce.customer.salesforceScriptUrl": "",
      "kontainers.website.customer.locales": "us,cn",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "false",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "false",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "",
      "kontainers.tenant.booking.autoFillConsignor": "false",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "true",
      "kontainers.website.customer.url": "https://oneline.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.enabled": "false",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "",
      "kontainers.liveperson.customer.code": "",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFields": "flexitank;soc",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.oauth2.oidcAlgorithm": "akamai",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.file.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.tenant.booking.offsetDays.fcl": "5",
      "kontainers.auth.oauth2.oidcClaimsTemplate": "{userinfo:{company:null,customer:null},id_token:{company:null,customer:null}}",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": "customer"
    }
  }, {
    "id": "fd444284-2a8a-11eb-beac-5b7b4974ecaa",
    "companyLogo": "https://s3.eu-west-1.amazonaws.com/staging-public-cetus-tenancy-kontainers-com/tenants/fd444284-2a8a-11eb-beac-5b7b4974ecaa/logo/71a943c7-3686-4991-8938-a080b0a23afe",
    "faviconPath": "/assets/images/favicon-placeholder.ico",
    "tenantName": "Dachser Staging",
    "config": {
      "kontainers.auth.oauth2.metadata.url": "",
      "kontainers.auth.oauth2.callback.url": "",
      "kontainers.auth.oauth2.endSession.url": "",
      "kontainers.tenant.customer.showNestedSchedules.lcl": "true",
      "kontainers.integrations.chat.salesforce.customer.deploymentId": "",
      "kontainers.tenant.booking.edit.disable.etdPlus": "",
      "kontainers.tenant.booking.payment.incoterms.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.code": "",
      "kontainers.website.customer.termsAndConditionsURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.booking.offsetDays.lcl": "3",
      "kontainers.website.customer.cookiePolicyURL": "https://kontainers.com/cookie-policy",
      "kontainers.tenant.booking.air.enabled": "true",
      "kontainers.auth.oauth2.additionalClaims": "openid profile email",
      "kontainers.auth.customer.implementation": "userpass",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentContentURL": "",
      "kontainers.website.customer.shareQuoteTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.enableQuoteNotification.fcl": "false",
      "kontainers.integrations.chat.salesforce.customer.baseLiveAgentURL": "",
      "kontainers.tenant.customer.label.ffOverride": "",
      "kontainers.tenant.customer.sharedQuotes.expiresAfterDays": "30",
      "kontainers.tenant.poweredBy": "true",
      "kontainers.auth.oauth2.clientid": "",
      "kontainers.tenant.customer.measurementUnits.temperatureUnits": "°C:celsius:metric,°F:fahrenheit:imperial",
      "kontainers.auth.oauth2.companyclaim": "",
      "kontainers.tenant.customer.background.url": "",
      "kontainers.tenant.booking.companyCopiedReferences": "",
      "kontainers.tenant.booking.edit.disable.ourStatus": "",
      "kontainers.tenant.booking.edit.onSave.transitionToPayment": "false",
      "kontainers.tenant.booking.showPayment": "true",
      "kontainers.integrations.chat.salesforce.customer.gslbBaseUrl": "",
      "kontainers.tenant.booking.note.enabled": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceScriptUrl": "",
      "kontainers.website.customer.locales": "us,de,cn",
      "kontainers.tenant.customer.measurementUnits.airflowUnits": "Cbm/h:CubicMeters:metric,Cbf/h:CubicFeet:imperial",
      "kontainers.tenant.booking.bookByUserRole.enabled": "false",
      "kontainers.integrations.chat.liveChat.customer.license": "",
      "kontainers.tenant.customer.label.bcoOverride": "",
      "kontainers.tenant.booking.validateCostAmounts": "true",
      "kontainers.intercom.admin.app.id": "",
      "kontainers.tenant.customer.measurementUnits.distanceUnits": "cm:cms:metric,m:meters:metric,in:inch:imperial,ft:feet:imperial",
      "kontainers.integrations.captcha.google.customer.enabled": "false",
      "kontainers.tenant.booking.currency": "USD:USD:$;CNY:CNY:¥;INR:INR:₹",
      "kontainers.intercom.customer.app.id": "",
      "kontainers.integrations.chat.salesforce.customer.buttonId": "",
      "kontainers.tenant.booking.autoFillConsignor": "true",
      "kontainers.integrations.chat.salesforce.customer.salesforceUrl": "",
      "kontainers.tenant.booking.maxQuoteWindowDays": "42",
      "kontainers.tenant.booking.documentDropdown": "Bank Slip:Bank Slip",
      "kontainers.tenant.customer.measurementUnits.volumeUnits": "Cbm:CubicMeters:metric,Cbf:CubicFeet:imperial",
      "kontainers.integrations.chat.salesforce.customer.isOfflineSupportEnabled": "",
      "kontainers.auth.oauth2.idtoken.url": "",
      "kontainers.tenant.customer.showNestedSchedules.fcl": "false",
      "kontainers.website.customer.url": "https://dachser.kclient-staging.com",
      "kontainers.website.customer.bookingTandCURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.sharedQuotes.fcl": "false",
      "kontainers.integrations.chat.liveChat.customer.scriptUrl": "",
      "kontainers.integrations.chat.salesforce.customer.enabled": "false",
      "kontainers.auth.oauth2.flow": "code",
      "kontainers.tenant.booking.hideFieldsSI": "",
      "kontainers.tenant.customer.hideCharges.fcl": "false",
      "kontainers.integrations.captcha.google.customer.siteKey": "",
      "kontainers.liveperson.customer.code": "",
      "kontainers.tenant.customer.sharedQuotes.lcl": "true",
      "kontainers.intercom.customer.showForUnauthenticated": "false",
      "kontainers.tenant.booking.extraTermsAndConditionsText": "",
      "kontainers.tenant.booking.lcl": "false",
      "kontainers.auth.oauth2.userdetails.url": "",
      "kontainers.integrations.chat.salesforce.customer.liveAgentSetupFlowUrl": "",
      "kontainers.website.color.primary": "#37b3c0",
      "kontainers.tenant.booking.hideFieldsBI": "",
      "kontainers.tenant.booking.hideFields": "",
      "kontainers.website.color.secondary": "#38a0d0",
      "kontainers.tenant.booking.offsetDays.air": "3",
      "kontainers.auth.oauth2.jwks.url": "",
      "kontainers.auth.oauth2.oidcAlgorithm": "azureAD",
      "kontainers.auth.admin.implementation": "userpass",
      "kontainers.tenant.customer.logo.url": "https://kontainers.com",
      "kontainers.website.customer.privacyURL": "https://kontainers.com/terms-and-conditions",
      "kontainers.tenant.customer.logo.openUrlNewTab": "true",
      "kontainers.integrations.chat.salesforce.customer.embeddedServiceUrl": "",
      "kontainers.tenant.customer.hideCharges.lcl": "false",
      "kontainers.integrations.chat.salesforce.customer.eswLiveAgentDevName": "",
      "kontainers.tenant.customer.enableQuoteNotification.lcl": "false",
      "kontainers.mixpanel.customer.key": "",
      "kontainers.website.customer.rates.enabled": "true",
      "kontainers.tenant.customer.team.enabled": "true",
      "kontainers.tenant.booking.file.enabled": "true",
      "kontainers.liveperson.customer.divId": "",
      "kontainers.tenant.customer.measurementUnits.weightUnits": "kg:KG:metric,lb:LB:imperial",
      "kontainers.tenant.customer.localChargesLinks": "",
      "kontainers.integrations.chat.liveChat.customer.enabled": "false",
      "kontainers.tenant.booking.offsetDays.fcl": "5",
      "kontainers.auth.oauth2.oidcClaimsTemplate": "",
      "kontainers.integrations.chat.liveChat.customer.group": "",
      "kontainers.tenant.booking.fileDescriptions": "Bank Slip:Bank Slip;other:Other",
      "kontainers.tenant.bol": "general",
      "kontainers.auth.oauth2.userclaim": ""
    }
  }];
  _exports.default = _default;
});