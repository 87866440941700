define("chimaera/serializers/booking-item", ["exports", "ember-data", "chimaera/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      bookingItemDetails: {
        embedded: 'always'
      },
      pickupAddress: {
        embedded: 'always'
      },
      deliveryAddress: {
        embedded: 'always'
      }
    },
    normalize: function normalize(model, hash) {
      if (Ember.isBlank(hash.bookingItemDetails)) {
        delete hash.bookingItemDetails;
      }

      return this._super.apply(this, arguments);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments); // Get default serialization


      json.flexitank = Ember.isBlank(json.flexitank) ? null : json.flexitank;
      json.shipperOwnedContainer = Ember.isBlank(json.shipperOwnedContainer) ? null : json.shipperOwnedContainer;
      return json;
    }
  });

  _exports.default = _default;
});