define("chimaera/serializers/location", ["exports", "chimaera/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(model, hash) {
      var mode = hash.mode;

      if (mode) {
        hash.id = "".concat(mode, "-").concat(hash.id);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});