define("chimaera/mixins/component-name-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getComponentName: function getComponentName(componentString) {
      var regex = componentString.match(/<[a-z-A-Z]+@[a-zA-Z]+:(?:[a-z]+[\\/])*([-a-zA-Z0-9]+)::[a-zA-Z]+[0-9]+>/);
      return regex[regex.length - 1];
    }
  });

  _exports.default = _default;
});