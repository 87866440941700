define("chimaera/mirage/fixtures/stored-quotes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props */
  // should be JSON formatted
  var _default = [{
    "id": "98d8a67c-62fe-11ea-a842-634332ae83fd",
    "email": "a@a.com",
    "fromLocation": "Felixstowe",
    "toLocation": "Bangkok",
    "reference": "HDL-34534-43243",
    "createdAt": "2020-03-06",
    "expiryDate": "2020-05-06"
  }, {
    "id": "98d8a67c-62fe-11ea-a842-634332ae8asd",
    "email": "b@b.com",
    "fromLocation": "Felixstowe",
    "toLocation": "Bangkok",
    "reference": "HDL-23232-22223",
    "createdAt": "2020-03-06",
    "expiryDate": "2020-05-07"
  }];
  _exports.default = _default;
});