define("chimaera/components/house-bill", ["exports", "@cetus/ember-cli-bill-templates/components/house-bill"], function (_exports, _houseBill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _houseBill.default;
    }
  });
});