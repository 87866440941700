define("chimaera/mixins/booking/order-saving", ["exports", "chimaera/mixins/weight"], function (_exports, _weight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_weight.default, {
    // Services
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    flashMessages: Ember.inject.service(),
    // Methods
    saveOrder: function saveOrder(submit) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.model.save({
          adapterOptions: {
            submit: submit
          }
        }).then(function (order) {
          _this.flashMessages.clearMessages();

          resolve(order);
        }, function (err) {
          if (_this._errorStatusCodes(err).includes('400')) {
            _this.flashMessages.error(_this.intl.t('error.adapter.booking'), {
              sticky: true
            });
          } else {
            _this.flashMessages.error(_this.intl.t('error.savingBooking'), {
              sticky: true
            });
          }

          reject([{
            field: 'system',
            message: err.errors.map(function (errObj) {
              return {
                detail: errObj.detail
              };
            })
          }]);
        });
      });
    },
    approveSI: function approveSI() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.model.save().then(function (order) {
          resolve(order);
        }, function () {
          reject([{
            field: 'system',
            message: _this2.intl.t('error.savingOrder')
          }]);
        });
      });
    },
    _checkVat: function _checkVat(addressId) {
      var address = this.store.peekRecord('address', addressId);

      if (Ember.isBlank(address)) {
        address = this.store.findRecord('address', addressId);
      }

      return address.get('haveVat');
    },
    _errorStatusCodes: function _errorStatusCodes(adapterErrors) {
      return Object.keys(adapterErrors).includes('errors') ? adapterErrors.errors.map(function (err) {
        return err.status;
      }) : [];
    }
  });

  _exports.default = _default;
});