define("chimaera/mixins/mp-pageview", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),
    pageViewMp: function () {
      var mpConfig = _environment.default.mixpanel;

      if (mpConfig == null) {
        mpConfig = {
          pageViewEventName: 'visit',
          pageViewPropertyName: 'url',
          trackAsPage: true
        };
        Ember.warn('No mixpanel info set in environment.js using defaults');
      }

      var eventValue = this.get('router.currentURL');

      if (!mpConfig.trackAsPage) {
        eventValue = this.get('router.currentRouteName');
      }

      if (typeof mixpanel.track === "function") {
        var mpEventProperty = {};
        mpEventProperty[mpConfig.pageViewPropertyName] = eventValue;
        mixpanel.track(mpConfig.pageViewEventName, mpEventProperty);
      }
    }.on('didTransition')
  });

  _exports.default = _default;
});