define("chimaera/pods/components/login/oidc/component", ["exports", "chimaera/mixins/component-name-help", "chimaera/mixins/login-help", "@cetus/ember-cli-model-validation/mixins/string-util", "jquery"], function (_exports, _componentNameHelp, _loginHelp, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentNameHelp.default, _loginHelp.default, _stringUtil.default, {
    classNameBindings: ['show::display-none'],
    ajax: Ember.inject.service('ajax'),
    welcomePack: Ember.inject.service('welcome-pack'),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    isLoading: false,
    isError: false,
    authConfig: Ember.computed.alias('welcomePack.auth'),
    loginBackground: Ember.computed('welcomePack.backgroundUrl', {
      get: function get() {
        var style = Ember.isBlank(this.welcomePack.backgroundUrl) ? 'background-image: url(../assets/images/access.jpg)' : "background-image: url(".concat(this.welcomePack.backgroundUrl, ")");
        return style;
      }
    }),
    customLogoClass: Ember.computed('welcomePack.backgroundUrl', {
      get: function get() {
        return Ember.isBlank(this.welcomePack.backgroundUrl) ? '' : 'custom-login';
      }
    }),
    oidcQueryParam: Ember.computed('welcomePack.wp.config["kontainers.auth.oauth2.flow"]', 'authConfig', {
      get: function get() {
        var redirectUrl = this.welcomePack.wp.config['kontainers.auth.oauth2.callback.url'];
        var clientId = this.welcomePack.wp.config['kontainers.auth.oauth2.clientid'];
        var flow = this.welcomePack.wp.config['kontainers.auth.oauth2.flow'];
        var scope = this.welcomePack.wp.config['kontainers.auth.oauth2.additionalClaims'];
        var state = Math.random().toString().substring(2);
        localStorage.setItem('oidc_state', state);
        var nonce = Math.random().toString().substring(2);
        localStorage.setItem('oidc_nonce', nonce);
        var queryParams = {
          redirect_uri: redirectUrl,
          state: state,
          client_id: clientId,
          response_type: flow,
          scope: scope
        };
        var oidcClaimsTemplate = this.authConfig.oauth2.oidcClaimsTemplate;

        if (!Ember.isBlank(oidcClaimsTemplate)) {
          queryParams.claims = oidcClaimsTemplate;
        }

        if (flow === 'id_token') {
          queryParams.nonce = nonce;
        }

        return queryParams;
      }
    }),
    haveOidcCallbackQueryParams: Ember.computed('code', 'state', 'idToken', {
      get: function get() {
        return !Ember.isBlank(this.code) && !Ember.isBlank(this.state) || !Ember.isBlank(this.idToken) && !Ember.isBlank(this.state);
      }
    }),
    areCodeCallbackQueryParamsValid: Ember.computed('code', 'state', {
      get: function get() {
        return !Ember.isBlank(this.code) && this.state === localStorage.getItem('oidc_state');
      }
    }),
    areIdTokenCallbackQueryParamsValid: Ember.computed('idToken', 'state', {
      get: function get() {
        return !Ember.isBlank(this.idToken) && this.state === localStorage.getItem('oidc_state');
      }
    }),
    loginPayload: Ember.computed('welcomePack.wp.config["kontainers.auth.oauth2.flow"]', 'idToken', 'code', {
      get: function get() {
        var flow = this.welcomePack.wp.config['kontainers.auth.oauth2.flow'];
        return flow === 'id_token' ? {
          token: this.idToken,
          nonce: localStorage.getItem('oidc_nonce')
        } : {
          token: this.code
        };
      }
    }),
    didInsertElement: function didInsertElement() {
      this.set('isError', false);

      if (!Ember.isBlank(this.error)) {
        this.set('isError', true);
      } else {
        if (this.haveOidcCallbackQueryParams) {
          if (this.areCodeCallbackQueryParamsValid || this.areIdTokenCallbackQueryParamsValid) {
            this.set('isLoading', true);
            this.send('login');
          } else {
            this.set('isError', true);
          }
        }
      }
    },
    actions: {
      login: function login() {
        var _this = this;

        this.session.authenticate('authenticator:oidc', this.loginPayload).then(function () {
          var mpUserHash = {
            $email: _this.safeLowercase(_this.get('userService.loggedinUser.email')),
            $first_name: _this.get('userService.loggedinUser.firstName'),
            $last_name: _this.get('userService.loggedinUser.lastName'),
            'Company Name': _this.get('userService.loggedinUser.company.name')
          };
          mixpanel.people.set(mpUserHash);
          mixpanel.identify(_this.safeLowercase(_this.get('userService.loggedinUser.email')));
          mixpanel.track('loggedin', {
            $email: _this.safeLowercase(_this.get('userService.loggedinUser.email'))
          });
          Ember.run.later(_this, function () {
            if (_this.isDestroyed) {
              return;
            }

            _this.doSignInSuccess();
          }, 300);
        }, function () {
          _this.set('isError', true);

          _this.set('isLoading', false);
        });
      },
      followRedirect: function followRedirect() {
        var authorizationCodeUrl = this.welcomePack.wp.config['kontainers.auth.oauth2.authorization_endpoint.url'];
        var requestAuthCodeUrl = "".concat(authorizationCodeUrl, "?").concat(_jquery.default.param(this.oidcQueryParam));
        window.location = requestAuthCodeUrl;
      }
    }
  });

  _exports.default = _default;
});