define("chimaera/pods/components/custom-asset/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    // State
    tagName: '',
    fonts: Object.freeze(['eot', 'woff2', 'woff', 'ttf']),
    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      var url = this.url,
          id = this.id;

      if (url) {
        this.loadCustomAsset(url, id);
      }
    },
    // Methods
    loadCustomAsset: function loadCustomAsset(url, id) {
      var fonts = this.fonts;

      if (url) {
        var ext = this.getExtension(url);

        if (ext === 'js') {
          document.body.append(this.createScript(url, id));
          return;
        }

        var isFont = fonts.includes(ext);

        if (ext === 'css' || isFont) {
          var head = document.getElementsByTagName('head')[0];

          if (isFont) {
            head.appendChild(this.createPreloadLink(url, {
              as: 'font',
              type: "font/".concat(ext),
              crossorigin: 'anonymous'
            }));
            return;
          }

          head.appendChild(this.createPreloadLink(url));
          head.appendChild(this.createStylesheetLink(url));
          return;
        }
      }
    },
    createPreloadLink: function createPreloadLink(url, additionalAttrs) {
      var attrs = {
        href: url,
        rel: 'preload',
        as: 'style'
      };

      if (Object.keys(attrs).length) {
        attrs = _objectSpread(_objectSpread({}, attrs), additionalAttrs);
      }

      return this.createElement(attrs, 'link');
    },
    createStylesheetLink: function createStylesheetLink(url) {
      return this.createElement({
        href: url,
        rel: 'stylesheet'
      }, 'link');
    },
    createScript: function createScript(url, id) {
      return this.createElement({
        src: url,
        id: id || "".concat(Ember.guidFor, "-custom-asset"),
        type: 'text/javascript'
      });
    },
    createElement: function createElement(attrs) {
      var element = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'script';
      var innerHtml = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var tag = document.createElement(element);

      if (Object.keys(attrs).length) {
        for (var attr in attrs) {
          tag.setAttribute(attr, attrs[attr] ? attrs[attr] : null);
        }
      }

      if (innerHtml) {
        tag.innerHTML = innerHtml;
      }

      return tag;
    },
    getExtension: function getExtension(url) {
      return (url.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1] || null;
    }
  });

  _exports.default = _default;
});