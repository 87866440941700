define("chimaera/pods/components/confirmation/cost-summary/cost-summary-item/component", ["exports", "ui-shared/mixins/hide-charge"], function (_exports, _hideCharge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hideCharge.default, {
    // Services
    currencyService: Ember.inject.service('currency'),
    userService: Ember.inject.service('user'),
    welcomePack: Ember.inject.service(),
    // Computed Properties
    bookingCosts: Ember.computed.alias('model.bookingCosts'),
    bookingTandcURL: Ember.computed.alias('welcomePack.bookingTandcURL')
  });

  _exports.default = _default;
});