define("chimaera/validations/change-password", ["exports", "ember-changeset-validations/validators", "chimaera/validators/password-contents", "chimaera/validators/uniqueness"], function (_exports, _validators, _passwordContents, _uniqueness) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    currentPassword: (0, _validators.validatePresence)({
      presence: true,
      message: 'Current password cannot be blank'
    }),
    newPassword: [(0, _uniqueness.default)({
      vs: 'currentPassword',
      localeKey: 'error.changeset.passwordsCannotMatch'
    }), (0, _passwordContents.default)({
      min: 8
    })]
  };
  _exports.default = _default;
});