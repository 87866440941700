define("chimaera/mixins/dashboard/booking-filter", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service('ajax'),
    bookingStatusMappingPromise: function bookingStatusMappingPromise() {
      return this.ajax.request("".concat(_environment.default.APP.BOOKING_URL, "/status?includeMappings=true"));
    }
  });

  _exports.default = _default;
});