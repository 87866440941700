define("chimaera/mirage/serializers/equipment", ["exports", "chimaera/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize() {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _application.default.prototype.serialize.apply(this, arguments);

      return {
        equipment: json.equipment
      };
    }
  });

  _exports.default = _default;
});