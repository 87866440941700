define("chimaera/models/booking-note", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    note: _emberData.default.attr('string'),
    displayName: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date-dd-mmmm-yyyy'),
    profilePic: _emberData.default.attr('string'),
    bookingId: _emberData.default.attr('string'),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  });

  _exports.default = _default;
});