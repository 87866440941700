define("chimaera/pods/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (this.get('session.isAuthenticated')) {
        this.transitionTo('dashboard');
      } else {
        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});