define("chimaera/mixins/validation/address-validation", ["exports", "@cetus/ember-cli-model-validation/mixins/address/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_validation.default, {
    initAndValidateCompanyAddress: function initAndValidateCompanyAddress(addressModel, userModel, forceUpdate) {
      var updatedModel = this._initEmptyValuesFromUserModel(addressModel, userModel, forceUpdate);

      return this.validateCompanyAddress(updatedModel);
    },
    initAndValidateBillingAddress: function initAndValidateBillingAddress(addressModel, userModel) {
      var updatedModel = this._initEmptyValuesFromUserModel(addressModel, userModel, false);

      return this.validateBillingAddress(updatedModel);
    },
    _initEmptyValuesFromUserModel: function _initEmptyValuesFromUserModel(addressModel, userModel, forceUpdate) {
      // set up model for use shared address validations
      if (forceUpdate || Ember.isBlank(addressModel.get('email'))) {
        addressModel.set('email', userModel.get('email'));
      }

      if (forceUpdate || Ember.isBlank(addressModel.get('firstName'))) {
        addressModel.set('firstName', userModel.get('firstName'));
      }

      if (forceUpdate || Ember.isBlank(addressModel.get('lastName'))) {
        addressModel.set('lastName', userModel.get('lastName'));
      }

      return addressModel;
    }
  });

  _exports.default = _default;
});