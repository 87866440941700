define("chimaera/pods/components/booking/container-detail/component", ["exports", "chimaera/mixins/booking/container-index", "chimaera/mixins/booking/si-check", "jquery", "luxon"], function (_exports, _containerIndex, _siCheck, _jquery, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_containerIndex.default, _siCheck.default, {
    // Services
    store: Ember.inject.service('store'),
    bookingService: Ember.inject.service('booking'),
    copyContainerModalService: Ember.inject.service('copy-container-modal'),
    intl: Ember.inject.service(),
    wp: Ember.inject.service('welcome-pack'),
    userService: Ember.inject.service('user'),
    currencyService: Ember.inject.service('currency'),
    // State
    shipperOwnedOptions: null,
    flexitankOptions: null,
    pickupTime: null,
    pickupDate: null,
    expectedCargoReadyDate: null,
    expectedCargoReadyTime: null,
    UI_DATE_TIME_FORMAT: 'd LLLL, yyyy:h:mm a',
    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this.shipperOwnedOptions = [{
        value: null,
        display: this.intl.t('booking.containerDetails.soc.unknown')
      }, {
        value: true,
        display: this.intl.t('booking.containerDetails.soc.true')
      }, {
        value: false,
        display: this.intl.t('booking.containerDetails.soc.false')
      }];
      this.flexitankOptions = [{
        value: null,
        display: this.intl.t('booking.containerDetails.flexitank.unknown')
      }, {
        value: true,
        display: this.intl.t('booking.containerDetails.flexitank.true')
      }, {
        value: false,
        display: this.intl.t('booking.containerDetails.flexitank.false')
      }];
    },
    didInsertElement: function didInsertElement() {
      this._initPickDateTime('.pickatime', '.pickadate', 'pickupDate', 'pickupTime', this.item.pickupTime, this.lastAvailablePickupDate);

      this._initPickDateTime('.timeReady', '.dateReady', 'expectedCargoReadyDate', 'expectedCargoReadyTime', this.item.expectedCargoReadyTime, this.lastAvailablePickupDate);
    },
    // Computed Properties
    weightUnitOptions: Ember.computed.reads('wp.measurementUnits.weightUnits'),
    temperatureUnitOptions: Ember.computed.reads('wp.measurementUnits.temperatureUnits'),
    airflowUnitOptions: Ember.computed.reads('wp.measurementUnits.airflowUnits'),
    updatePickupDateTime: Ember.observer('pickupTime', 'pickupDate', function () {
      this._updateFromPickDateTime(this.pickupDate, this.pickupTime, 'pickupTime');
    }),
    updateExpectedCargoReadyTime: Ember.observer('expectedCargoReadyDate', 'expectedCargoReadyTime', function () {
      this._updateFromPickDateTime(this.expectedCargoReadyDate, this.expectedCargoReadyTime, 'expectedCargoReadyTime');
    }),
    containerDetailIndex: Ember.computed('itemIndex', {
      get: function get() {
        return this.containerDetailIndexMaker(this.itemIndex);
      }
    }),
    containerWeightIndex: Ember.computed('itemIndex', {
      get: function get() {
        return this.containerWeightIndexMaker(this.itemIndex);
      }
    }),
    flexitankIndex: Ember.computed('itemIndex', {
      get: function get() {
        return this.flexitankIndexMaker(this.itemIndex);
      }
    }),
    shipperOwnedContainerIndex: Ember.computed('itemIndex', {
      get: function get() {
        return this.shipperOwnedContainerIndexMaker(this.itemIndex);
      }
    }),
    containerDisplayName: Ember.computed('item.containerType', {
      get: function get() {
        if (Ember.isBlank(this.item.containerDisplayName)) {
          return this.bookingService.lookupBySelectedEqByName(this.item.containerType);
        } else {
          return this.item.containerDisplayName;
        }
      }
    }),
    detailOpen: true,
    isReefer: Ember.computed('item.containerType', {
      get: function get() {
        return this.bookingService.isReeferByName(this.item.containerType);
      }
    }),
    pickupAddy: Ember.computed('item.pickupAddress.singleLine', {
      get: function get() {
        return this.item.pickupAddress.get('singleLine');
      }
    }),
    deliveryAddy: Ember.computed('item.deliveryAddress.singleLine', 'intl.locale', {
      get: function get() {
        var singleline = this.item.deliveryAddress.get('singleLine');
        return Ember.isBlank(singleline) ? this.intl.t('booking.containerDetails.deliveryAddyPlaceHolder') : this.item.deliveryAddress.get('singleLine');
      }
    }),
    isPortToDoor: Ember.computed('hasPickup', 'hasCollect', {
      get: function get() {
        return !this.hasPickup && this.hasCollect;
      }
    }),
    hideExpectedCargoReadyField: Ember.computed('wp.bookingFieldsToHide', {
      get: function get() {
        return this.wp.bookingFieldsToHide.includes('expectedCargoReady');
      }
    }),
    hideExpectedCargoReady: Ember.computed('isPortToDoor', 'isPortToPort', 'isCfsToCfs', 'hideExpectedCargoReadyField', {
      get: function get() {
        return this.hideExpectedCargoReadyField || !(this.isPortToDoor || this.isPortToPort || this.isCfsToCfs);
      }
    }),
    hideFlexiTankField: Ember.computed('wp.bookingFieldsToHide', {
      get: function get() {
        return this.wp.bookingFieldsToHide.indexOf('flexitank') > -1;
      }
    }),
    hideSocField: Ember.computed('wp.bookingFieldsToHide', {
      get: function get() {
        return this.wp.bookingFieldsToHide.indexOf('soc') > -1;
      }
    }),
    // Methods
    _initPickDateTime: function _initPickDateTime(dateClass, timeClass, dateAttr, timeAttr, modelAttr, maxDate) {
      (0, _jquery.default)(dateClass).pickatime();
      (0, _jquery.default)(timeClass).pickadate({
        min: _luxon.DateTime.utc().toJSDate(),
        max: maxDate
      });

      if (!Ember.isBlank(modelAttr)) {
        this.set(dateAttr, _luxon.DateTime.fromISO(modelAttr).toFormat('d LLLL, yyyy'));
        this.set(timeAttr, _luxon.DateTime.fromISO(modelAttr).toFormat('h:mm a'));
      }
    },
    _updateFromPickDateTime: function _updateFromPickDateTime(dateAttr, timeAttr, modelAttr) {
      if (!Ember.isBlank(dateAttr) && !Ember.isBlank(timeAttr)) {
        var formatted = _luxon.DateTime.fromFormat("".concat(dateAttr, ":").concat(timeAttr), this.UI_DATE_TIME_FORMAT).toISO();

        if (this.item.get(modelAttr) !== formatted) {
          this.item.set(modelAttr, formatted);
        }
      }
    },
    // Actions
    actions: {
      setShipperOwendContainer: function setShipperOwendContainer(_ref) {
        var value = _ref.value;
        Ember.set(this.item, 'shipperOwnedContainer', value);
      },
      setFlexitank: function setFlexitank(_ref2) {
        var value = _ref2.value;
        Ember.set(this.item, 'flexitank', value);
      },
      toggleContact: function toggleContact(context) {
        this.doToggleContactsWithContext("".concat(context, ":").concat(this.itemIndex));
      },
      toggleDetail: function toggleDetail() {
        this.toggleProperty('detailOpen');
      },
      addCommodity: function addCommodity() {
        var item = this.item,
            allBookingItems = this.allBookingItems;
        var _this$userService$def = this.userService.defaultMeasurementSystem,
            weightUnit = _this$userService$def.weightUnit,
            volumeUnit = _this$userService$def.volumeUnit,
            distanceUnit = _this$userService$def.distanceUnit; // gets a sample commodity across containers

        var sampleCommodity = allBookingItems.mapBy('bookingItemDetails.firstObject').filter(function (item) {
          return item;
        }).firstObject; // align units with existing commodities

        if (sampleCommodity) {
          weightUnit = sampleCommodity.weightUnit;
          volumeUnit = sampleCommodity.volumeUnit;
          distanceUnit = sampleCommodity.distanceUnit;
        }

        item.errors.remove('bookingItemDetails'); // set defaults based on company location

        var newItemDetail = this.store.createRecord('booking-item-detail', {
          weightUnit: weightUnit,
          volumeUnit: volumeUnit,
          distanceUnit: distanceUnit,
          valueCurrency: this.currencyService.selectedName
        });
        item.bookingItemDetails.pushObject(newItemDetail);
      },
      removeCommodity: function removeCommodity(commodity) {
        commodity.deleteRecord(); // Need to manually remove bookingItem[x].bookingItemDetails
        // adapter error on delete bookingItemDetail

        this.item.errors.remove('bookingItemDetails');
      },
      containerNumbertoUpper: function containerNumbertoUpper() {
        if (!Ember.isBlank(this.item.containerNumber)) {
          this.set('item.containerNumber', this.item.containerNumber.toUpperCase());
        }
      },
      incrementNonNegativeNumber: function incrementNonNegativeNumber(property) {
        // item.airFlow
        if (Ember.isBlank(this.get(property))) {
          this.set(property, 0);
        } else {
          if (property === 'item.humidity' && parseInt(this.get(property)) >= 100) {
            this.set(property, 100);
          } else {
            this.set(property, parseInt(this.get(property)) + 1);
          }
        }
      },
      decrementNonNegativeNumber: function decrementNonNegativeNumber(property) {
        if (Ember.isBlank(this.get(property))) {
          this.set(property, 0);
        } else {
          if (parseInt(this.get(property)) < 1) {
            this.set(property, 0);
          } else {
            this.set(property, parseInt(this.get(property)) - 1);
          }
        }
      },
      incrementTempreture: function incrementTempreture() {
        if (Ember.isBlank(this.item.temperature)) {
          this.set('item.temperature', 0);
        } else {
          this.set('item.temperature', parseInt(this.item.temperature) + 1);
        }
      },
      decrementTempreture: function decrementTempreture() {
        if (Ember.isBlank(this.item.temperature)) {
          this.set('item.temperature', 0);
        } else {
          this.set('item.temperature', parseInt(this.item.temperature) - 1);
        }
      },
      openCopyContainerModal: function openCopyContainerModal(item, itemIndex) {
        this.copyContainerModalService.openModal(item, itemIndex, this.allBookingItems, this.isReefer);
      },
      negativeCheck: function negativeCheck(property) {
        if (parseInt(this.get(property)) < 0) {
          this.set(property, 0);
        }
      },
      negativeAndMaxCheck: function negativeAndMaxCheck(property, max) {
        if (parseInt(this.get(property)) < 0) {
          this.set(property, 0);
        } else if (parseInt(this.get(property)) >= parseInt(max)) {
          this.set(property, max);
        }
      }
    }
  });

  _exports.default = _default;
});