define("chimaera/models/reference", ["exports", "ember-data", "chimaera/mixins/string-help"], function (_exports, _emberData, _stringHelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_stringHelp.default, {
    referenceType: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    displayName: _emberData.default.attr('string'),
    editableReadOnly: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // UI only
    referenceTypeName: Ember.computed('referenceType', 'displayName', {
      get: function get() {
        return this.displayName || this.decamelizeAndCapitalize(this.referenceType);
      }
    }),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  });

  _exports.default = _default;
});