define("chimaera/utils/file-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    isComplete: Ember.computed('status', function () {
      return this.status === 'success';
    }),
    isError: Ember.computed('status', function () {
      return this.status === 'error';
    })
  });

  _exports.default = _default;
});