define("chimaera/pods/components/custom-link-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/umGy+up",
    "block": "{\"symbols\":[\"url\",\"@urls\"],\"statements\":[[6,[37,2],[[32,2,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"custom-asset\",[],[[\"@url\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/custom-link-tags/template.hbs"
    }
  });

  _exports.default = _default;
});