define("chimaera/adapters/notice", ["exports", "chimaera/adapters/application", "chimaera/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.APP.TENANCY_URL,
    latest: function latest() {
      return this.ajax("".concat(this.host, "/notices/latest"), 'GET');
    },
    dismiss: function dismiss() {
      this.ajax("".concat(this.host, "/notices/dismissal"), 'POST', {
        data: {
          dismissed: true
        }
      });
    }
  });

  _exports.default = _default;
});