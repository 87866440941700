define("chimaera/pods/components/dashboard/file-drop/component", ["exports", "chimaera/config/environment", "chimaera/utils/file-info", "dropzone"], function (_exports, _environment, _fileInfo, _dropzone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service('user'),
    draggedFiles: Ember.A(),
    store: Ember.inject.service('store'),
    booking: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var _scope = this;

      var options = {
        autoDiscover: false,
        headers: {
          Authorization: "Bearer ".concat(this.userService.loggedinUser.token)
        },
        clickable: '#dropable',
        maxFilesize: '5',
        url: function url(files) {
          return "".concat(_environment.default.APP.BOOKING_URL, "/bookings/").concat(_this.booking.id, "/files?length=").concat(files[0].size);
        },
        uploadMultiple: false,
        addRemoveLinks: true,
        maxFiles: 5,
        acceptedFiles: 'image/*,application/pdf',
        previewTemplate: document.querySelector('#dummy').innerHTML,
        accept: function accept(file, done) {
          _scope.acceptState(file, done);
        },
        init: function init() {
          this.on('maxfilesexceeded', function (files) {
            _scope.maxfilesexceededState(files);
          });
          this.on('addedfile', function (file) {
            _scope.addedState(file);
          });
          this.on('uploadprogress', function (file, progress) {
            _scope.uploadProgressState(file, progress);
          });
          this.on('success', function (file) {
            _scope.successState(file);
          });
          this.on('error', function (file) {
            _scope.errorState(file);
          });
          this.on('sending', function (file, xhr, formData) {
            _scope.sendingState(file, xhr, formData);
          });
          this.on('queuecomplete', function () {
            _scope.queuecompleteState();
          });
        }
      };
      this.set('instance', new _dropzone.default(this.element, options));
    },
    successState: function successState(file) {
      var foundFile = this.findFile(file.name);
      this.draggedFiles.removeObject(foundFile);
    },
    addedState: function addedState(file) {
      var newFile = _fileInfo.default.create({
        name: file.name,
        status: file.status,
        size: Math.round(file.size / 1000),
        progress: 0
      });

      this.draggedFiles.addObject(newFile);
    },
    errorState: function errorState(file) {
      var foundFile = this.findFile(file.name);
      foundFile.set('error', true);
    },
    acceptState: function acceptState(file, done) {
      var errorSt = '';
      done(errorSt);
    },
    uploadProgressState: function uploadProgressState(file, progress) {
      var foundFile = this.findFile(file.name);
      foundFile.set('progress', progress);
    },
    sendingState: function sendingState(file, xhr, formData) {
      formData.append('uploaded_by', this.userService.loggedinUser.id);
    },
    maxfilesexceededState: function maxfilesexceededState(file) {
      var foundFile = this.findFile(file.name);
      foundFile.set('info', '-- ERROR max 5 files per upload.');
    },
    queuecompleteState: function queuecompleteState() {
      var bookingId = this.booking.id;
      this.set('bookingFiles', this.store.query('booking-file', {
        bookingId: bookingId
      }));
    },
    findFile: function findFile(fileName) {
      var foundFile = this.draggedFiles.findBy('name', fileName);
      return foundFile;
    }
  });

  _exports.default = _default;
});