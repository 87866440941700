define("chimaera/mirage/factories/logistics-detail", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    eta: function eta() {
      return _faker.default.date.between('2020-03-01', '2020-03-31').toISOString();
    },
    etd: function etd() {
      return _faker.default.date.between('2020-01-01', '2020-01-31').toISOString();
    },
    fromLocation: 'Pipavav (Victor) Port',
    fromCountry: 'india',
    fromUNCode: 'INPAV',
    toLocation: 'New York',
    toCountry: 'united states',
    toUNCode: 'USNYC',
    machineName: 'ZIM NEW YORK',
    machineRegisteredCountry: 'HK',
    voyageNumber: '933E',
    mode: 'sea',
    supplier: 'ZIMU',
    seaLegData: null
  });

  _exports.default = _default;
});