define("chimaera/authenticators/base", ["exports", "ember-simple-auth/authenticators/base", "chimaera/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('ajax'),
    userService: Ember.inject.service('user'),
    restore: function restore(data) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.isBlank(data.id)) {
          reject();
        } else {
          var url = "".concat(_environment.default.APP.TENANCY_URL, "/users/self");

          _this.ajax.request(url, {
            headers: {
              Authorization: "Bearer ".concat(data.token)
            }
          }).then(function (data) {
            _this.userService.setLoggedinUser(data.user);

            resolve(data.user);
          }, function (err) {
            return reject(err);
          });
        }
      });
    },
    invalidate: function invalidate() {
      // Used to clear cookie
      return this.ajax.post("".concat(_environment.default.APP.TENANCY_URL, "/logout"));
    }
  });

  _exports.default = _default;
});