define("chimaera/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    department: _emberData.default.attr('string'),
    vatNo: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    tiers: _emberData.default.attr(),
    companyAddress: _emberData.default.belongsTo('company-address'),
    billingAddress: _emberData.default.belongsTo('company-address'),
    references: _emberData.default.hasMany('company-reference')
  });

  _exports.default = _default;
});