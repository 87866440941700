define("chimaera/pods/components/bol/bol-page/component", ["exports", "chimaera/mixins/addressbar-help"], function (_exports, _addressbarHelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_addressbarHelp.default, {
    // Services
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    supplierService: Ember.inject.service('suppliers'),
    wp: Ember.inject.service('welcome-pack'),
    statusService: Ember.inject.service('status'),
    flashMessages: Ember.inject.service(),
    // Computed Properties
    supplierFullName: Ember.computed('model.bookingDetailMainLeg.supplier', {
      get: function get() {
        var supplier = this.supplierService.find(this.model.bookingDetailMainLeg.supplier);
        return supplier ? supplier.fullName : null;
      }
    }),
    allCompleteStatusses: Ember.computed.alias('statusService.allCompleteStatusses'),
    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      this.billType = [{
        value: 'sea_waybill_shipped',
        display: 'Sea Waybill Shipped'
      }, {
        value: 'negotiable_shipped',
        display: 'Negotiable Shipped'
      }, {
        value: 'sea_waybill_received',
        display: 'Sea Waybill Received'
      }, {
        value: 'negotiable_received',
        display: 'Negotiable Received'
      }];
    },
    actions: {
      toggleContactsForBol: function toggleContactsForBol(context) {
        this.send('toggleContactsWithContext', context);
      },
      saveChanges: function saveChanges(override) {
        this.onSave(override);
      },
      onError: function onError() {
        this.doOnError(this.model.id);
      },
      shareBol: function shareBol() {
        this.onShare();
      },
      successMessageAction: function successMessageAction(message) {
        this.flashMessages.success(message);
      },
      errorMessageAction: function errorMessageAction(message) {
        this.flashMessages.error(message, {
          sticky: true
        });
      }
    }
  });

  _exports.default = _default;
});