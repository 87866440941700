define("chimaera/pods/components/dashboard/copy-booking-modal/section/lcl-shipment/component", ["exports", "chimaera/pods/components/dashboard/copy-booking-modal/section/component", "luxon"], function (_exports, _component, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // Services
    welcomePack: Ember.inject.service(),
    // Computed Properties
    distanceUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.distanceUnits'),
    collectionDate: Ember.computed('bookingItem.pickupTime', {
      get: function get() {
        var pickupTime = this.bookingItem.pickupTime;
        return pickupTime && _luxon.DateTime.fromISO(pickupTime).toFormat('d LLLL, yyyy');
      }
    }),
    collectionTime: Ember.computed('bookingItem.pickupTime', {
      get: function get() {
        var pickupTime = this.bookingItem.pickupTime;
        return pickupTime && _luxon.DateTime.fromISO(pickupTime).toFormat('h:mm a');
      }
    }),
    selectAllAttributes: Ember.computed('bookingItem.{pickupTime,pickupAddress,loadingReference,deliveryAddress}', {
      get: function get() {
        var selectAllAttributes = [];
        var _this$bookingItem = this.bookingItem,
            pickupTime = _this$bookingItem.pickupTime,
            pickupAddress = _this$bookingItem.pickupAddress,
            deliveryAddress = _this$bookingItem.deliveryAddress;

        if (pickupTime || pickupAddress.content) {
          selectAllAttributes.pushObject('collection');
        }

        if (deliveryAddress.content) {
          selectAllAttributes.pushObject('delivery');
        }

        return selectAllAttributes;
      }
    }),
    selectedAll: Ember.computed('bookingItem.{bookingItemDetails.@each.willCopy,copyAttributes.[]}', {
      get: function get() {
        var bookingItem = this.bookingItem,
            selectAllAttributes = this.selectAllAttributes;
        var bookingItemDetails = bookingItem.bookingItemDetails,
            copyAttributes = bookingItem.copyAttributes;

        if (!copyAttributes) {
          return;
        }

        var allBookingItemDetailsSelected = bookingItemDetails.every(function (bid) {
          return bid.willCopy;
        });
        var allCopyAttributesSelected = selectAllAttributes.every(function (attr) {
          return copyAttributes.includes(attr);
        });
        return allBookingItemDetailsSelected && allCopyAttributesSelected;
      }
    }),
    selectedSome: Ember.computed('bookingItem.{bookingItemDetails.@each.willCopy,copyAttributes.[]}', {
      get: function get() {
        var bookingItem = this.bookingItem,
            selectAllAttributes = this.selectAllAttributes;
        var bookingItemDetails = bookingItem.bookingItemDetails,
            copyAttributes = bookingItem.copyAttributes;
        var someBookingItemDetailsSelected = bookingItemDetails.toArray().some(function (bid) {
          return bid.willCopy;
        });
        var someCopyAttributesSelected = copyAttributes && selectAllAttributes.toArray().some(function (attr) {
          return copyAttributes.includes(attr);
        });
        return someBookingItemDetailsSelected && someCopyAttributesSelected;
      }
    }),
    // Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.locationsSelected) {
        this.send('toggleSelectAll', false);
      }
    },
    // Actions
    actions: {
      toggleSelectAll: function toggleSelectAll(override) {
        if (!this.locationsSelected && Ember.isBlank(override)) {
          return;
        }

        var selectAllAttributes = this.selectAllAttributes,
            selectedAll = this.selectedAll,
            bookingItem = this.bookingItem;

        if (!bookingItem.copyAttributes) {
          Ember.set(bookingItem, 'copyAttributes', []);
        }

        var copyAttributes = bookingItem.copyAttributes,
            bookingItemDetails = bookingItem.bookingItemDetails;
        var shouldSelect = !Ember.isBlank(override) ? override : !selectedAll;

        if (shouldSelect) {
          selectAllAttributes.forEach(function (attr) {
            if (!copyAttributes.includes(attr)) {
              copyAttributes.pushObject(attr);
            }
          });
          bookingItemDetails.forEach(function (bid) {
            return Ember.set(bid, 'willCopy', true);
          });
        } else {
          selectAllAttributes.forEach(function (attr) {
            if (copyAttributes.includes(attr)) {
              copyAttributes.removeObject(attr);
            }
          });
          bookingItemDetails.forEach(function (bid) {
            return Ember.set(bid, 'willCopy', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});