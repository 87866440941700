define("chimaera/mirage/factories/booking-item", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    containerDetail: function containerDetail() {
      return "".concat(_faker.default.random.number({
        min: 30,
        max: 100
      }), " pallets of ").concat(_faker.default.commerce.productName().toLowerCase());
    },
    weight: function weight() {
      return _faker.default.random.number({
        min: 50,
        max: 500
      });
    },
    weightUnit: function weightUnit() {
      return 'KG';
    },
    containerType: function containerType() {
      return _faker.default.random.arrayElement(['20DRY', '40HDRY', '40REEFER', '40DRY']);
    },
    temperatureUnit: function temperatureUnit() {
      return 'celsius';
    },
    airFlowUnit: function airFlowUnit() {
      return 'CubicMeters';
    },
    temperature: function temperature() {
      return 0;
    },
    containerNumber: function containerNumber() {
      return "CSQU".concat(_faker.default.random.number({
        min: 1000000,
        max: 2000000
      }));
    },
    sealNumber: function sealNumber() {
      return _faker.default.random.number({
        min: 100,
        max: 700
      });
    },
    pickupAddress: (0, _emberCliMirage.association)(),
    deliveryAddress: (0, _emberCliMirage.association)(),
    withBookingItemDetails: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(bookingItem, server) {
        var bookingItemDetails = server.createList('booking-item-detail', 3);
        bookingItem.update({
          bookingItemDetails: bookingItemDetails
        });
        bookingItem.save();
      }
    })
  });

  _exports.default = _default;
});