define("chimaera/validators/uniqueness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateUniqueness;

  function validateUniqueness(_ref) {
    var vs = _ref.vs,
        localeKey = _ref.localeKey;
    return function (key, newValue, oldValue, changes
    /* content */
    ) {
      if (vs && newValue === changes[vs]) {
        return {
          localeKey: localeKey
        };
      }

      return true;
    };
  }
});