define("chimaera/pods/components/change-password/component", ["exports", "chimaera/config/environment", "ember-changeset", "ember-changeset-validations", "chimaera/validations/change-password", "ui-shared/mixins/alertbox-trigger"], function (_exports, _environment, _emberChangeset, _emberChangesetValidations, _changePassword, _alertboxTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_alertboxTrigger.default, {
    // Services
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    loggedinUser: Ember.computed.alias('userService.loggedinUser'),
    // State
    showCurrentPwd: false,
    showNewPwd: false,
    // Computed Properties
    currentPwdFieldType: Ember.computed('showCurrentPwd', {
      get: function get() {
        return this.showCurrentPwd === true ? 'text' : 'password';
      }
    }),
    currentPwdButtonText: Ember.computed('showCurrentPwd', 'intl.locale', {
      get: function get() {
        return this.showCurrentPwd === true ? this.intl.t('settings.changePwd.hideButton') : this.intl.t('settings.changePwd.showButton');
      }
    }),
    newPwdFieldType: Ember.computed('showNewPwd', {
      get: function get() {
        return this.showNewPwd === true ? 'text' : 'password';
      }
    }),
    newPwdButtonText: Ember.computed('showNewPwd', 'intl.locale', {
      get: function get() {
        return this.showNewPwd === true ? this.intl.t('settings.changePwd.hideButton') : this.intl.t('settings.changePwd.showButton');
      }
    }),
    canSave: Ember.computed('changeset.{isDirty,isValid}', {
      get: function get() {
        if (this.changeset) {
          var _this$changeset = this.changeset,
              isDirty = _this$changeset.isDirty,
              isValid = _this$changeset.isValid;
          var canSave = [isDirty, isValid];
          return !canSave.includes(false);
        }

        return false;
      }
    }),
    // Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setupChangeset();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.changeset.rollback();
    },
    setupChangeset: function setupChangeset() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        reset: false
      };

      if (!this.pwdObj || opts.reset) {
        Ember.set(this, 'pwdObj', Ember.Object.create({
          currentPassword: null,
          newPassword: null,
          firstName: null,
          lastName: null,
          email: null
        }));

        if (this.loggedinUser) {
          Ember.set(this, 'pwdObj', {
            firstName: this.loggedinUser.firstName,
            lastName: this.loggedinUser.lastName,
            email: this.loggedinUser.email
          });
        }
      }

      var validation = this.validation || _changePassword.default;
      var changeset = new _emberChangeset.default(this.pwdObj, (0, _emberChangesetValidations.default)(validation), validation);
      Ember.set(this, 'changeset', changeset);
    },
    doSave: function doSave() {
      var _this = this;

      this.ajax.post("".concat(_environment.default.APP.TENANCY_URL, "/users/updatePassword"), {
        data: {
          newPassword: this.pwdObj.newPassword,
          oldPassword: this.pwdObj.currentPassword
        }
      }).then(function () {
        _this.flashMessages.success(_this.intl.t('settings.changePwd.pwdChanged'), {
          sticky: true
        });

        _this.setupChangeset({
          reset: true
        });

        _this.router.transitionTo('settings.index');
      }).catch(function (_ref) {
        var payload = _ref.payload;
        var res = {
          key: 'serverErrors',
          validation: [payload.reason]
        };

        _this.parseChangeSetErrors([res], 'serverErrors');
      });
    },
    actions: {
      toggleCurrentPwdType: function toggleCurrentPwdType() {
        this.toggleProperty('showCurrentPwd');
      },
      toggleNewPwdType: function toggleNewPwdType() {
        this.toggleProperty('showNewPwd');
      },
      validateField: function validateField(field) {
        var _this2 = this;

        this.changeset.validate(field).then(function (res) {
          if (typeof res === 'string') {
            return;
          }

          res['key'] = field;

          _this2.parseChangeSetErrors([res], 'parsedErrors');
        });
      },
      save: function save() {
        if (!this._validate()) {
          return;
        }

        if (this.canSave) {
          this.flashMessages.clearMessages();
          this.changeset.execute();
          this.doSave();
        }
      }
    },
    _validate: function _validate() {
      var changeset = this.changeset;
      changeset.validate();

      if (!this.changeset.isValid) {
        this.flashMessages.error('Fix validation errors', {
          sticky: true
        });
        return false;
      }

      return true;
    }
  });

  _exports.default = _default;
});