define("chimaera/components/core/modal/confirm", ["exports", "@cetus/kontainers-ui-core/components/core/modal/confirm"], function (_exports, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _confirm.default;
    }
  });
});