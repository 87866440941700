define("chimaera/mirage/fixtures/charge-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props */
  // should be JSON formatted
  var _default = [{
    "id": "98dc4134-a6ff-11ea-a7eb-9fc3b4c481c5",
    "name": "10",
    "description": "10",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98dceb48-a6ff-11ea-a7ed-232704be5f17",
    "name": "103",
    "description": "103",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "773e51e0-a978-11ea-99ab-3b227046210b",
    "name": "104",
    "description": "104",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "773e8eee-a978-11ea-99ac-e767cd5ddaba",
    "name": "109",
    "description": "109",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98dd2194-a6ff-11ea-a7ee-bf01b3d9147f",
    "name": "110",
    "description": "110",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98dd5088-a6ff-11ea-a7ef-a7cf982d639a",
    "name": "114",
    "description": "114",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98dda902-a6ff-11ea-a7f0-e766a0c97991",
    "name": "115",
    "description": "115",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98dcb812-a6ff-11ea-a7ec-038a3261d867",
    "name": "12",
    "description": "12",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "FCA": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98dde1b0-a6ff-11ea-a7f1-5b4ed9bacf40",
    "name": "130",
    "description": "130",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98de3a20-a6ff-11ea-a7f2-535c3f406491",
    "name": "137",
    "description": "137",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98de64aa-a6ff-11ea-a7f3-1f3753e484be",
    "name": "139",
    "description": "139",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98df6cd8-a6ff-11ea-a7f4-3fc2d91b28f4",
    "name": "140",
    "description": "140",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "98df9be0-a6ff-11ea-a7f5-6bbe43f3d274",
    "name": "148",
    "description": "148",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "98e029c0-a6ff-11ea-a7f6-ef4c213c2376",
    "name": "201",
    "description": "201",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e06c8c-a6ff-11ea-a7f7-cf8a6f610b8c",
    "name": "203",
    "description": "203",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e0a1ca-a6ff-11ea-a7f8-dfa55c591ac9",
    "name": "206",
    "description": "206",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Import"
      },
      "CIF": {
        "importExport": "Import"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e0d316-a6ff-11ea-a7f9-83af7d22b890",
    "name": "217",
    "description": "217",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "FCA": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "6c042b6e-a5cd-11ea-b9a8-afcf77cb4b62",
    "name": "301",
    "description": "301",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "FCA": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Export"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "6c0468e0-a5cd-11ea-b9a9-cfa11aa80028",
    "name": "302",
    "description": "302",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "FCA": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Import"
      },
      "CIF": {
        "importExport": "Import"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e1822a-a6ff-11ea-a7fa-e311c6b66443",
    "name": "305",
    "description": "305",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "DAP": {
        "importExport": "Export"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "6c03cbec-a5cd-11ea-b9a7-ab717fa6f58f",
    "name": "4",
    "description": "4",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "ccc8a1a0-62fc-11ea-8e73-47f8c389029a",
    "name": "401",
    "description": "Customs clearance up to 5HS code",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "CEVA",
    "hideChildren": true,
    "isIncoTerm": false,
    "incotermSettings": {}
  }, {
    "id": "ccc8e674-62fc-11ea-8e74-eb9257a5f2a6",
    "name": "437-Import Security Filing (437)",
    "description": "Import Security Filing",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "CEVA",
    "hideChildren": true,
    "isIncoTerm": false,
    "incotermSettings": {}
  }, {
    "id": "98e274b4-a6ff-11ea-a7fb-6b69b0f0f9ca",
    "name": "449",
    "description": "449",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Export"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "98e5509e-a6ff-11ea-a7fc-ab65cf9af6af",
    "name": "501",
    "description": "501",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Export"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Export"
      }
    }
  }, {
    "id": "98e5d5be-a6ff-11ea-a7fd-cfb1341dd55a",
    "name": "502",
    "description": "502",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e84cae-a6ff-11ea-a7fe-ab03c179c6cd",
    "name": "516",
    "description": "516",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "ccc92ecc-62fc-11ea-8e75-232f987310b2",
    "name": "519-Doc Turnover/Release Fee (519)",
    "description": "External Brokerage",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "BROK",
    "uiDescriptionCode": "quote.additionalService.title",
    "hideChildren": true,
    "isIncoTerm": false,
    "incotermSettings": {}
  }, {
    "id": "6c04ae4a-a5cd-11ea-b9aa-df576e8261a6",
    "name": "9",
    "description": "9",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Export"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Export"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Export"
      },
      "CIF": {
        "importExport": "Export"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e8a5c8-a6ff-11ea-a7ff-ff92658af46a",
    "name": "908",
    "description": "908",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Import"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Import"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Import"
      },
      "CFR": {
        "importExport": "Import"
      },
      "CIF": {
        "importExport": "Import"
      },
      "DPU": {
        "importExport": "Import"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "98e8e29a-a6ff-11ea-a800-7feb30fcae4a",
    "name": "911",
    "description": "911",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Import"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Import"
      },
      "CIF": {
        "importExport": "Import"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "ccc975ee-62fc-11ea-8e76-1b7ca624d380",
    "name": "BROK",
    "description": "Brokerage",
    "additionalService": "ExcludedByDefault",
    "uiDescriptionCode": "quote.additionalService.title",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {}
  }, {
    "id": "ccc9bd1a-62fc-11ea-8e77-ef6e2f8f70a7",
    "name": "CEVA",
    "description": "CEVA Brokerage, Up to 5 HSCodes",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "BROK",
    "uiDescriptionCode": "quote.additionalService.title",
    "hideChildren": true,
    "isIncoTerm": false,
    "incotermSettings": {}
  }, {
    "id": "2682376e-84b9-11ea-971e-cbc61fdada9e",
    "name": "CFR",
    "description": "CFR",
    "additionalService": "IncludedByDefault",
    "chargeCodeParent": "INCO",
    "uiDescriptionCode": "incoterm.cfr.desc",
    "hideChildren": true,
    "isIncoTerm": true,
    "toType": "Port",
    "incotermSettings": {}
  }, {
    "id": "773dbb22-a978-11ea-99aa-4f78549f24f1",
    "name": "CHASSIS SPLIT FEE DESTINATION",
    "description": "CHASSIS SPLIT FEE DESTINATION",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "EXW,FCA,FAS,FOB,CFR,CIF,CPT,CIP,DPU,DAP,DDP",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {
      "CPT": {
        "importExport": "Import"
      },
      "FCA": {
        "importExport": "Import"
      },
      "CIP": {
        "importExport": "Import"
      },
      "DAP": {
        "importExport": "Export"
      },
      "FAS": {
        "importExport": "Import"
      },
      "DDP": {
        "importExport": "Export"
      },
      "CFR": {
        "importExport": "Import"
      },
      "CIF": {
        "importExport": "Import"
      },
      "DPU": {
        "importExport": "Export"
      },
      "EXW": {
        "importExport": "Import"
      },
      "FOB": {
        "importExport": "Import"
      }
    }
  }, {
    "id": "147c225e-8a4b-11ea-b86c-7bda89e4a5d0",
    "name": "CIF",
    "description": "CIF",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "INCO",
    "uiDescriptionCode": "incoterm.cif.desc",
    "hideChildren": true,
    "isIncoTerm": true,
    "toType": "Port",
    "incotermSettings": {}
  }, {
    "id": "2682b95a-84b9-11ea-9720-f3d057017d37",
    "name": "DPU",
    "description": "DPU",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "INCO",
    "uiDescriptionCode": "incoterm.dpu.desc",
    "hideChildren": true,
    "isIncoTerm": true,
    "toType": "Port",
    "incotermSettings": {}
  }, {
    "id": "2681f272-84b9-11ea-971d-af9496b1e404",
    "name": "FCA",
    "description": "FCA",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "INCO",
    "uiDescriptionCode": "incoterm.fca.desc",
    "hideChildren": true,
    "isIncoTerm": true,
    "fromType": "Port",
    "incotermSettings": {}
  }, {
    "id": "2682f5aa-84b9-11ea-9721-e7f8d15b1ed6",
    "name": "FOB",
    "description": "FOB",
    "additionalService": "ExcludedByDefault",
    "chargeCodeParent": "INCO",
    "uiDescriptionCode": "incoterm.fob.desc",
    "hideChildren": true,
    "isIncoTerm": true,
    "fromType": "Port",
    "incotermSettings": {}
  }, {
    "id": "26811a32-84b9-11ea-971c-aba60c8aa0ce",
    "name": "INCO",
    "description": "Incoterms",
    "additionalService": "ExcludedByDefault",
    "hideChildren": false,
    "isIncoTerm": false,
    "incotermSettings": {}
  }];
  _exports.default = _default;
});