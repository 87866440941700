define("chimaera/pods/components/confirm-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Htyld4q1",
    "block": "{\"symbols\":[],\"statements\":[[11,\"span\"],[24,\"role\",\"button\"],[24,0,\"btn_close close-bclever-modal\"],[4,[38,1],[[32,0],\"doCancel\"],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"vis-hidden\"],[12],[1,[30,[36,0],[\"application.modal.closeDialogue\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"question\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h2\"],[12],[1,[30,[36,0],[[32,0,[\"question\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,\"role\",\"button\"],[24,0,\"bc_button close-bclever-modal\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"doCancel\"],null],[12],[1,[32,0,[\"cancelText\"]]],[13],[2,\"\\n  \"],[11,\"button\"],[24,\"role\",\"button\"],[24,0,\"bc_button btn_secondary close-bclever-modal\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"doConfirm\"],null],[12],[1,[32,0,[\"confirmText\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/confirm-modal/template.hbs"
    }
  });

  _exports.default = _default;
});