define("chimaera/mixins/application/note-modal-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    bookingNoteService: Ember.inject.service('booking-note'),
    noteModalOpen: Ember.computed('bookingNoteService.newNoteModal', function () {
      return this.bookingNoteService.newNoteModal;
    })
  });

  _exports.default = _default;
});