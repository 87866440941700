define("chimaera/pods/components/confirmation/container-detail-view/component", ["exports", "ui-shared/utils/load-calculations", "luxon"], function (_exports, _loadCalculations, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Service
    bookingService: Ember.inject.service('booking'),
    welcomePack: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Computed Properties
    distanceUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.distanceUnits'),
    weightUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.weightUnits'),
    volumeUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.volumeUnits'),
    containerDisplayName: Ember.computed('item.containerType', {
      get: function get() {
        return Ember.isBlank(this.item.containerDisplayName) ? this.bookingService.lookupBySelectedEqByName(this.item.containerType) : this.item.containerDisplayName;
      }
    }),
    collectionDate: Ember.computed('items.pickupTime', {
      get: function get() {
        if (!this.item.pickupTime) return this.intl.t('abbreviation.notApplicable');
        return _luxon.DateTime.fromISO(this.item.pickupTime).toFormat('d LLLL, yyyy');
      }
    }),
    collectionTime: Ember.computed('items.pickupTime', {
      get: function get() {
        if (!this.item.pickupTime) return this.intl.t('abbreviation.notApplicable');
        return _luxon.DateTime.fromISO(this.item.pickupTime).toFormat('h:mm a');
      }
    }),
    // Lifecycle Hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.loadType === 'lcl' || this.loadType === 'air') {
        Ember.set(this, 'grandTotals', (0, _loadCalculations.getGrandTotals)(this.item.bookingItemDetails));
      }
    }
  });

  _exports.default = _default;
});