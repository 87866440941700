define("chimaera/mixins/string-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    decamelizeAndCapitalize: function decamelizeAndCapitalize(input) {
      return Ember.isBlank(input) ? '' : input.decamelize().replace('_', ' ').capitalize();
    }
  });

  _exports.default = _default;
});