define("chimaera/ui-shared/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/shared/alert-box.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/shared/alert-box.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/shared/powered-by.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/shared/powered-by.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/shared/typeahead-search.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/shared/typeahead-search.hbs should pass TemplateLint.\n\n');
  });
});