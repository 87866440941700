define("chimaera/mirage/factories/booking", ["exports", "ember-cli-mirage", "faker", "luxon", "chimaera/mirage/utils/status"], function (_exports, _emberCliMirage, _faker, _luxon, _status) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    currency: function currency() {
      _faker.default.random.arrayElement(['USD', 'GBP']);
    },
    loadType: 'fcl',
    status: null,
    createdAt: function createdAt() {
      return _luxon.DateTime.fromISO(_faker.default.date.between('2020-01-01', '2020-03-07')).toFormat('yyyy-LL-dd');
    },
    createdAtTime: '21:35:19.510619',
    mailTrackingNumber: '',
    customerReference: function customerReference(i) {
      return "REF #".concat(++i);
    },
    bookingRef: function bookingRef() {
      return _faker.default.random.number();
    },
    etd: function etd() {
      return _luxon.DateTime.fromISO(_faker.default.date.between('2020-03-15', '2020-03-20')).toFormat('yyyy-LL-dd');
    },
    eta: function eta() {
      return _luxon.DateTime.fromISO(_faker.default.date.between('2020-03-22', '2020-03-31')).toFormat('yyyy-LL-dd');
    },
    hasInvoice: function hasInvoice() {
      _faker.default.random.arrayElement(true, false);
    },
    hasBolVerifiedCopy: function hasBolVerifiedCopy() {
      _faker.default.random.arrayElement(true, false);
    },
    // BelongsTo
    consignorBookingAddress: (0, _emberCliMirage.association)(),
    consigneeBookingAddress: (0, _emberCliMirage.association)(),
    notifyBookingAddress: (0, _emberCliMirage.association)(),
    bookedByAddress: (0, _emberCliMirage.association)(),
    brokerageAddress: (0, _emberCliMirage.association)(),
    confirmedWithAddress: (0, _emberCliMirage.association)(),
    // HasMany as traits
    withBookingItems: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking, server) {
        var bookingItems = server.createList('booking-item', 2, 'withBookingItemDetails');
        booking.update({
          bookingItems: bookingItems
        });
        booking.save();
      }
    }),
    withBookingCosts: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking, server) {
        var bookingCosts = server.createList('booking-cost', 2);
        booking.update({
          bookingCosts: bookingCosts
        });
        booking.save();
      }
    }),
    withPayingParties: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking, server) {
        var payingParties = server.createList('paying-party', 1);
        booking.update({
          payingParties: payingParties
        });
        booking.save();
      }
    }),
    withLogisticsDetails: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking, server) {
        var logisticsDetails = server.createList('logistics-detail', 2);
        booking.update({
          logisticsDetails: logisticsDetails
        });
        booking.save();
      }
    }),
    withReferences: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking, server) {
        var references = server.createList('reference', 1);
        booking.update({
          references: references
        });
        booking.save();
      }
    }),
    withStatusBookingReceived: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking) {
        booking.update({
          status: 'Booking received',
          statusTimestamps: (0, _status.statusTimestamps)(booking.createdAt, ['Booking received'])
        });
        booking.save();
      }
    }),
    withStatusContainerInGate: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking) {
        booking.update({
          status: 'Container in Gate',
          statusTimestamps: (0, _status.statusTimestamps)(booking.createdAt, ['Booking received', 'Container in Gate'])
        });
        booking.save();
      }
    }),
    withStatusAtd: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking) {
        booking.update({
          status: 'ATD',
          statusTimestamps: (0, _status.statusTimestamps)(booking.createdAt, ['Booking received', 'Container in Gate', 'ATD'])
        });
        booking.save();
      }
    }),
    withStatusOutForDelivery: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(booking) {
        booking.update({
          status: 'Out for Delivery',
          statusTimestamps: (0, _status.statusTimestamps)(booking.createdAt, ['Booking received', 'Container in Gate', 'ATD', 'Out for Delivery'])
        });
        booking.save();
      }
    })
  });

  _exports.default = _default;
});