define("chimaera/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/booking.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/booking.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/logger.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/logger.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/tenancy-welcome-pack.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/tenancy-welcome-pack.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/tenancy.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/tenancy.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/translation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/translation.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/endpoints/trip.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/endpoints/trip.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/booking-address.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/booking-address.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/booking-cost.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/booking-cost.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/booking-item-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/booking-item-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/booking-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/booking-item.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/booking.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/booking.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/child.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/child.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/company.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/equipment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/equipment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/leg.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/leg.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/location.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/location.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/logistics-detail.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/logistics-detail.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/notice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/notice.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/paying-party.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/paying-party.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/rate.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/rate.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/reference.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/reference.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/schedule.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/schedule.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/booking-reference-types.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/booking-reference-types.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/charge-codes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/charge-codes.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/countries.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/countries.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/status-mappings.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/status-mappings.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/stored-quotes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/stored-quotes.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/suppliers.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/suppliers.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/translations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/translations.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/fixtures/welcome-packs.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/welcome-packs.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/booking-reference-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/booking-reference-type.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/child.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/child.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/country.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/country.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/status-mapping.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/status-mapping.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/stored-quote.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/stored-quote.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/supplier.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/supplier.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/translation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/translation.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/shared.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/shared.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/booking-reference-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/booking-reference-type.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/booking.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/booking.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/charge-code.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/charge-code.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/country.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/country.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/equipment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/equipment.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/notice.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/notice.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/schedule.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/schedule.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/status-mapping.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/status-mapping.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/stored-quote.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/stored-quote.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/supplier.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/supplier.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/translation.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/translation.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/welcome-pack.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/welcome-pack.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/dates.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/dates.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/locations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/locations.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/status.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/status.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/utils/vessels.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils/vessels.js should pass ESLint\n\n');
  });
});