define("chimaera/mirage/endpoints/translation", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    // Get translations
    server.get("".concat(_environment.default.APP.CMS_URL, "/translations/:locale"), function (schema, request) {
      var locale = request.params.locale;
      return schema.translations.findBy({
        locale: locale
      });
    }); // Get translations version

    server.get("".concat(_environment.default.APP.CMS_URL, "/version"), function () {
      return {
        version: '202003061607'
      };
    });
  }
});