define("chimaera/serializers/incoterm", ["exports", "chimaera/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = _application.default.extend({
    normalize: function normalize(model, hash) {
      hash.displayName = hash.description ? hash.description : hash.name;
      hash.description = hash.uiDescriptionCode;
      hash.isDefault = hash.additionalService === 'IncludedByDefault';
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});