define("chimaera/pods/components/contact-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    countryService: Ember.inject.service('country'),
    // State
    addyObjInst: null,
    isDisabled: false,
    // Computed Properties
    countries: Ember.computed.alias('countryService.countries')
  });

  _exports.default = _default;
});