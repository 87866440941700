define("chimaera/pods/bol/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    supplierService: Ember.inject.service('suppliers'),
    model: function model(params) {
      var _this = this;

      return new Promise(function (resolve) {
        _this.supplierService.supplierPromise().then(function (suppliers) {
          // can refactor this to fetchSuppliers if we make model async
          _this.supplierService.setSuppliers(suppliers);

          _this.store.find('booking', params.booking_id).then(function (booking) {
            _this.store.query('booking-note', {
              bookingId: booking.id
            }).then(function (bookingNotes) {
              resolve({
                booking: booking,
                bookingNotes: bookingNotes
              });
            });
          });
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.booking);
      controller.set('bookingNotes', model.bookingNotes);
    }
  });

  _exports.default = _default;
});