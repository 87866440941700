define("chimaera/components/textfield", ["exports", "@cetus/kontainers-ui-core/components/textfield"], function (_exports, _textfield) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _textfield.default;
    }
  });
});