define("chimaera/mirage/utils/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocation = _exports.locations = void 0;
  var locations = [{
    place: 'Pipavav (Victor) Port',
    country: 'india',
    unCode: 'INPAV'
  }, {
    place: 'Ahmedabad',
    country: 'india',
    unCode: 'INAMD'
  }, {
    place: 'Delhi',
    country: 'india',
    unCode: 'INDEL'
  }, {
    place: 'Vancouver',
    country: 'canada',
    unCode: 'CAVAN'
  }, {
    place: 'New York',
    country: 'united states',
    unCode: 'USNYC'
  }, {
    place: 'Syracuse',
    country: 'united states',
    unCode: 'USSYR'
  }, {
    place: 'New Albany',
    country: 'united states',
    unCode: 'USNAM'
  }, {
    place: 'Buffalo',
    country: 'united states',
    unCode: 'USBUF'
  }];
  _exports.locations = locations;

  var getLocation = function getLocation(place, direction) {
    var location = {};
    var result = locations.find(function (location) {
      return location.place === place;
    });
    location[direction] = result.place;
    location["".concat(direction, "Country")] = result.country;
    location["".concat(direction, "UNCode")] = result.unCode;
    return location;
  };

  _exports.getLocation = getLocation;
});