define("chimaera/mirage/fixtures/status-mappings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props, object-curly-spacing */
  // should be JSON formatted
  var _default = [{
    "statusMapping": [{
      "ourStatus": "created",
      "theirStatus": "created",
      "statusCategory": "BI"
    }, {
      "ourStatus": "booked",
      "theirStatus": "Booking received",
      "statusCategory": "BI"
    }, {
      "ourStatus": "confirmed",
      "theirStatus": "Container in Gate",
      "statusCategory": "SI"
    }, {
      "ourStatus": "picked up",
      "theirStatus": "picked up",
      "statusCategory": "SI"
    }, {
      "ourStatus": "shipped on board",
      "theirStatus": "ATD",
      "statusCategory": "SI"
    }, {
      "ourStatus": "customs cleared",
      "theirStatus": "ATA",
      "statusCategory": "SI"
    }, {
      "ourStatus": "collected",
      "theirStatus": "Customs",
      "statusCategory": "SI"
    }, {
      "ourStatus": "arrived",
      "theirStatus": "Out for Delivery",
      "statusCategory": "SI"
    }, {
      "ourStatus": "proof of delivery",
      "theirStatus": "Delivered",
      "statusCategory": "SI"
    }, {
      "ourStatus": "cancelled",
      "theirStatus": "cancelled",
      "statusCategory": "Other"
    }]
  }];
  _exports.default = _default;
});