define("chimaera/ui-shared/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/components/shared/alert-box.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/shared/alert-box.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/shared/powered-by.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/shared/powered-by.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/shared/typeahead-search.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/shared/typeahead-search.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/shared/includes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/shared/includes.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/shared/translate-with-fallback.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/shared/translate-with-fallback.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/alertbox-trigger.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/alertbox-trigger.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/amount-help.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/amount-help.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/hide-charge.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/hide-charge.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/number-formatter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/number-formatter.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/typeahead-help.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/typeahead-help.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/load-calculations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/load-calculations.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/modes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/modes.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/query-params.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/query-params.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/serializer-quote-query-params.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/serializer-quote-query-params.js should pass ESLint\n\n');
  });
});