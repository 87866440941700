define("chimaera/mirage/factories/company", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Kontainers',
    role: 'beneficialCargoOwner',
    kyc: 'approved',
    kycAt: '2020-05-15T13:58:00.478838'
  });

  _exports.default = _default;
});