define("chimaera/pods/invite/route", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_environment.default, {
    ajax: Ember.inject.service('ajax'),
    userService: Ember.inject.service('user'),
    model: function model(params) {
      var _this = this;

      var inviteDetails = "".concat(_environment.default.APP.TENANCY_URL, "/teams/").concat(params.invite_token);
      this.ajax.request(inviteDetails).then(function (data) {
        var invitedUser = data.team;
        invitedUser.inviteToken = params.invite_token;

        _this.userService.setInvitedUser(invitedUser);

        _this.replaceWith('/settings/registration');
      }).catch(function () {
        _this.replaceWith('/invalid-invite');
      });
    }
  });

  _exports.default = _default;
});