define("chimaera/pods/components/booking/lcl-load/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UzqaeF34",
    "block": "{\"symbols\":[\"commodity\",\"commodityIndex\",\"bookingItemDetail\",\"bookingItemDetailIndex\",\"@alertErrors\"],\"statements\":[[10,\"div\"],[14,0,\"row lcl-loads\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"bookingItemDetailsByCommodities\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"lcl-dim\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"locale\"]],\"heading.load\"],null]],null]],[2,\" \"],[1,[30,[36,2],[[32,2]],null]],[13],[2,\"\\n      \"],[10,\"strong\"],[12],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"locale\"]],\"heading.dimensions\"],null]],null]],[2,\":\"],[13],[2,\"\\n\\n      \"],[8,\"core/dimensions-label\",[],[[\"@width\",\"@height\",\"@length\",\"@distanceUnit\"],[[32,1,[\"bookingItemDetails\",\"firstObject\",\"widthPerUnit\"]],[32,1,[\"bookingItemDetails\",\"firstObject\",\"heightPerUnit\"]],[32,1,[\"bookingItemDetails\",\"firstObject\",\"lengthPerUnit\"]],[30,[36,3],[[32,1,[\"bookingItemDetails\",\"firstObject\",\"distanceUnit\"]],[32,0,[\"distanceUnitOptions\"]]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1,[\"bookingItemDetails\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"booking/lcl-load/lcl-load-commodity\",[],[[\"@bookingItemDetail\",\"@alertErrors\",\"@index\"],[[32,3],[32,5],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[3,4]}]]]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"inc\",\"option-label\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/booking/lcl-load/template.hbs"
    }
  });

  _exports.default = _default;
});