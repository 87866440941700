define("chimaera/pods/booking/file/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return Promise.all([this.store.find('booking', params.order_id), this.store.query('booking-file', {
        bookingId: params.order_id
      })]).then(function (results) {
        return {
          booking: results[0],
          bookingFiles: results[1]
        };
      });
    },
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});