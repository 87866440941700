define("chimaera/serializers/leg", ["exports", "ember-data", "chimaera/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      children: {
        embedded: 'always'
      },
      rates: {
        embedded: 'always'
      },
      issues: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});