define("chimaera/pods/components/confirmation/cost-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YAEjTj4B",
    "block": "{\"symbols\":[\"@model\",\"@showNotes\"],\"statements\":[[10,\"div\"],[14,0,\"charge-toggles\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"tab-buttons\"],[14,\"role\",\"tablist\"],[12],[2,\"\\n    \"],[10,\"li\"],[15,0,[31,[\"tab-button-holder tab-1 \",[30,[36,1],[[30,[36,0],[[32,0,[\"currencyType\"]],\"checkout\"],null],\"selected\"],null]]]],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"tab-button\"],[14,\"role\",\"tab\"],[15,\"onclick\",[30,[36,2],[[32,0],\"setCurrencyType\",\"checkout\"],null]],[14,\"data-test-action\",\"checkout-currency\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"confirmation.costSummary.label.checkoutCurrency\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"li\"],[15,0,[31,[\"tab-button-holder tab-2 \",[30,[36,1],[[30,[36,0],[[32,0,[\"currencyType\"]],\"local\"],null],\"selected\"],null]]]],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"tab-button\"],[14,\"role\",\"tab\"],[15,\"onclick\",[30,[36,2],[[32,0],\"setCurrencyType\",\"local\"],null]],[14,\"data-test-action\",\"local-currency\"],[14,4,\"button\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"confirmation.costSummary.label.localCurrency\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"confirmation/cost-summary/cost-summary-item\",[],[[\"@booking\",\"@currencyType\",\"@showTotal\",\"@showNotes\"],[[32,1],[32,0,[\"currencyType\"]],[30,[36,0],[[32,0,[\"currencyType\"]],\"checkout\"],null],[32,2]]],null]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/confirmation/cost-summary/template.hbs"
    }
  });

  _exports.default = _default;
});