define("chimaera/mirage/fixtures/suppliers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props, object-curly-spacing */
  // should be JSON formatted
  var _default = [{
    "suppliers": [{
      "id": "83e40470-fc64-11e8-9155-438eb9ef1efb",
      "fullName": "American President Lines Ltd.",
      "scac": "APLS",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e42efa-fc64-11e8-9156-6f424a15fe1e",
      "fullName": "CMA CGM",
      "scac": "CMDU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e459de-fc64-11e8-9157-9b80de805560",
      "fullName": "COSCO Container Lines",
      "scac": "COSU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e48472-fc64-11e8-9158-c7ab300e6c1c",
      "fullName": "Evergreen",
      "scac": "EGLV",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e4aede-fc64-11e8-9159-8bc765b6d40e",
      "fullName": "Hapag Lloyd",
      "scac": "HLUS",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e4d88c-fc64-11e8-915a-535573290003",
      "fullName": "Maersk Line",
      "scac": "MAEU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e502da-fc64-11e8-915b-1305757fdc65",
      "fullName": "Matson navigation co.",
      "scac": "MATS",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e529cc-fc64-11e8-915c-87e1eb4fdc77",
      "fullName": "Mediterranean Shipping Company S.A.",
      "scac": "MSCU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e55546-fc64-11e8-915d-7f82f11d5a99",
      "fullName": "Ocean Network Express",
      "scac": "ONEY",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e586ce-fc64-11e8-915e-7be84c36f44d",
      "fullName": "Orient Overseas Container Line",
      "scac": "OOLU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e5ae60-fc64-11e8-915f-1bdaa422c5be",
      "fullName": "Hamburg Süd",
      "scac": "SUDU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "83e5d46c-fc64-11e8-9160-5bbde5320a47",
      "fullName": "Yang Ming Line",
      "scac": "YMLU",
      "threePl": false,
      "validator": "default",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }, {
      "id": "3b390548-f87e-11e8-a26c-c73bbab0013a",
      "fullName": "Zim",
      "scac": "ZIMU",
      "threePl": false,
      "validator": "supplier",
      "packagingCodes": "supplier",
      "commodityCodes": "supplier",
      "delete": false
    }]
  }];
  _exports.default = _default;
});