define("chimaera/pods/components/payment-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BoW313NY",
    "block": "{\"symbols\":[\"@doTransfer\"],\"statements\":[[10,\"button\"],[14,\"data-test-action\",\"confirm-and-book\"],[15,0,[31,[\"k-button btn-assign-now cta \",[30,[36,0],[[32,0,[\"disabledPayButton\"]],\"disabled k-loading\"],null]]]],[15,\"disabled\",[32,0,[\"disabledPayButton\"]]],[15,\"onclick\",[30,[36,1],[[32,0],[32,1]],null]],[14,4,\"button\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"booking.payment.assignAndBook\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/payment-button/template.hbs"
    }
  });

  _exports.default = _default;
});