define("chimaera/mixins/booking/si-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    statusService: Ember.inject.service('status'),
    hideSiFields: Ember.computed('bookingStatus', function () {
      return this.statusService.getCategoryByTheirStatus(this.bookingStatus) === 'BI';
    }),
    isSi: Ember.computed('bookingStatus', function () {
      return this.statusService.getCategoryByTheirStatus(this.bookingStatus) === 'SI';
    })
  });

  _exports.default = _default;
});