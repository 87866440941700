define("chimaera/mirage/factories/equipment", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: null,
    displayName: null,
    isoCode: null,
    isReefer: false,
    deleted: false
  });

  _exports.default = _default;
});