define("chimaera/pods/user/validation", ["exports", "ember-changeset-validations/validators", "chimaera/validators/password-contents"], function (_exports, _validators, _passwordContents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UserUpdateValidations = void 0;
  var UserUpdateValidations = {
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    email: (0, _validators.validateFormat)({
      type: 'email'
    }),
    password: (0, _passwordContents.default)({
      min: 8
    })
  };
  _exports.UserUpdateValidations = UserUpdateValidations;
  var _default = UserUpdateValidations;
  _exports.default = _default;
});