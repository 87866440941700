define("chimaera/pods/components/dashboard/copy-booking-modal/section/files-and-documents/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zRzY+yUP",
    "block": "{\"symbols\":[\"file\",\"@locale\"],\"statements\":[[10,\"div\"],[14,0,\"darkerPanel\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"chk-copy-section\"],[4,[38,1],[[32,0],\"toggleSelectAll\"],null],[12],[2,\"\\n    \"],[8,\"input\",[[16,1,[30,[36,2],[[32,0],\"copyFilesAndDocuments\"],null]]],[[\"@type\",\"@checked\"],[\"checkbox\",[32,0,[\"selectedAll\"]]]],null],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[30,[36,2],[[32,0],\"copyFilesAndDocuments\"],null]],[12],[10,\"span\"],[12],[13],[1,[30,[36,4],[[30,[36,3],[[32,2],\"label.copyAll\"],null]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,4],[[30,[36,3],[[32,2],\"heading.filesAndDocuments\"],null]],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[16,0,[31,[\"copy-area \",[30,[36,0],[[32,1,[\"willCopy\"]],\"copied\"],null]]]],[4,[38,1],[[32,0],\"toggleCopy\",[32,1]],null],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"chk-copy-section\"],[12],[2,\"\\n            \"],[8,\"input\",[[16,1,[30,[36,2],[[32,0],\"copyFile1\"],null]]],[[\"@type\",\"@checked\"],[\"checkbox\",[32,1,[\"willCopy\"]]]],null],[2,\"\\n            \"],[10,\"label\"],[15,\"for\",[30,[36,2],[[32,0],\"copyFile1\"],null]],[12],[10,\"span\"],[12],[13],[1,[30,[36,4],[[30,[36,3],[[32,2],\"label.copy\"],null]],null]],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"p\"],[14,0,\"copy-file\"],[12],[10,\"strong\"],[12],[1,[32,1,[\"displayFileName\"]]],[13],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"guid-for\",\"concat\",\"t\",\"files\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/dashboard/copy-booking-modal/section/files-and-documents/template.hbs"
    }
  });

  _exports.default = _default;
});