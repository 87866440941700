define("chimaera/models/equipment", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    displayName: (0, _attr.default)('string'),
    isoCode: (0, _attr.default)('string'),
    isReefer: (0, _attr.default)('boolean'),
    teu: (0, _attr.default)('string'),
    deleted: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});