define("chimaera/pods/settings/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "chimaera/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service('ajax'),
    userService: Ember.inject.service('user'),
    welcomePackService: Ember.inject.service('welcome-pack'),
    isPrimaryUser: null,
    model: function model() {
      var _this = this;

      return this.store.find('user', 'self').then(function (user) {
        _this.isPrimaryUser = user.isPrimary;

        if (_this.isPrimaryUser) {
          return Promise.all([_this.store.find('user', 'self'), _this.store.findAll('invite'), _this.ajax.request("".concat(_environment.default.APP.TENANCY_URL, "/users")).then(function (data) {
            return data.user;
          })]).then(function (response) {
            return {
              user: response.objectAt(0),
              invites: response.objectAt(1),
              team: response.objectAt(2)
            };
          });
        } else {
          return Promise.all([_this.store.find('user', 'self')]).then(function (response) {
            return {
              user: response.objectAt(0)
            };
          });
        }
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('user', models.user);
      controller.set('team', models.team);
      controller.set('invites', models.invites);
      controller.set('showTeam', this.welcomePackService.teamEnabled);
    }
  });

  _exports.default = _default;
});