define("chimaera/mirage/endpoints/tenancy", ["exports", "chimaera/config/environment", "ember-cli-mirage/response"], function (_exports, _environment, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    // Get welcome pack
    server.get("".concat(_environment.default.APP.TENANCY_URL, "/welcomePacks"), function (schema) {
      var tenantName = server.tenantName;
      return schema.welcomePacks.findBy({
        tenantName: tenantName
      });
    }); // Get countries

    server.get("".concat(_environment.default.APP.TENANCY_URL, "/countries"), function (schema) {
      return schema.countries.findBy({
        type: 'default'
      });
    }); // Get countries, postcode required

    server.get("".concat(_environment.default.APP.TENANCY_URL, "/countriesWithPostcodeRequired"), function (schema) {
      return schema.countries.findBy({
        type: 'postcode'
      });
    }); // Get countries, vat required

    server.get("".concat(_environment.default.APP.TENANCY_URL, "/countriesWithVatRequired"), function (schema) {
      return schema.countries.findBy({
        type: 'vat'
      });
    }); // Get notices

    server.get("".concat(_environment.default.APP.TENANCY_URL, "/notices/latest"), function (schema) {
      return schema.notices.find(1);
    }); // Login

    server.post("".concat(_environment.default.APP.TENANCY_URL, "/login"), function (schema) {
      return schema.users.find(1);
    }); // Get self

    server.get("".concat(_environment.default.APP.TENANCY_URL, "/users/self"), function (schema) {
      return schema.users.find(1);
    }); // Report issue

    server.post("".concat(_environment.default.APP.TENANCY_URL, "/reportIssue"), function (schema, request) {
      var _JSON$parse = JSON.parse(request.requestBody),
          issueText = _JSON$parse.issueText;

      if (issueText) {
        return new _response.default(200, {
          'Content-Type': 'application/json'
        }, {
          reason: 'Issue reported'
        });
      }

      return new _response.default(422, {
        'Content-Type': 'application/json'
      }, {
        errors: [{
          status: 422,
          title: 'issueText is missing',
          description: 'issueText is missing'
        }]
      });
    });
  }
});