define("chimaera/mirage/factories/reference", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    referenceType: 'order',
    reference: 'EKITOXKYJFDOHQCJQ',
    createdAt: function createdAt() {
      return '2019-12-13T12:57:10.881357';
    },
    updatedAt: function updatedAt() {
      return '2019-12-13T12:57:10.881361';
    }
  });

  _exports.default = _default;
});