define("chimaera/pods/components/bol/bol-page/item-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('item.bookingItemDetails').length === 0) {
        this.item.addNullBookingItemDetail();
      }
    },
    didUpdate: function didUpdate() {
      if (this.get('item.bookingItemDetails').length === 0) {
        this.item.addNullBookingItemDetail();
      } else if (this.get('item.bookingItemDetails').length > 1) {
        this.get('item.bookingItemDetails').forEach(function (bid) {
          if (Ember.isEmpty(bid.get('id'))) {
            bid.deleteRecord();
          }
        });
      }
    }
  });

  _exports.default = _default;
});