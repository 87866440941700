define("chimaera/pods/components/booking/copy-container-modal/component", ["exports", "ui-shared/mixins/alertbox-trigger"], function (_exports, _alertboxTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_alertboxTrigger.default, {
    copyContainerModalService: Ember.inject.service('copy-container-modal'),
    classNames: ['bclever-modal', 'copy-details-modal'],
    classNameBindings: ['copyContainerModalService.showModal:bclever-modal-open'],
    intl: Ember.inject.service(),
    cargoDetailsChecked: false,
    commoditiesChecked: false,
    temperatureChecked: false,
    alertErrors: null,
    isAnyDetailsChecked: Ember.computed('cargoDetailsChecked', 'commoditiesChecked', 'temperatureChecked', {
      get: function get() {
        return this.cargoDetailsChecked || this.commoditiesChecked || this.temperatureChecked;
      }
    }),
    isAnyContainerChecked: Ember.computed('copyContainerModalService.copyToContainerArray.@each.checked', {
      get: function get() {
        return !Ember.isBlank(this.copyContainerModalService.copyToContainerArray.filter(function (container) {
          return container.checked;
        }));
      }
    }),
    invalidForReefer: Ember.computed('cargoDetailsChecked', 'commoditiesChecked', 'temperatureChecked', 'copyContainerModalService.copyToContainerArray.@each.checked', {
      get: function get() {
        var temperaturedCheckedOnly = this.temperatureChecked && !this.cargoDetailsChecked && !this.commoditiesChecked;
        var selectedReeferContainers = this.copyContainerModalService.copyToContainerArray.filter(function (container) {
          return !container.isReefer && container.checked;
        });
        return temperaturedCheckedOnly && !Ember.isBlank(selectedReeferContainers);
      }
    }),
    toContainerHasNonReefer: Ember.computed('copyContainerModalService.copyToContainerArray.[]', {
      get: function get() {
        return !Ember.isBlank(this.copyContainerModalService.copyToContainerArray.filter(function (container) {
          return !container.isReefer;
        }));
      }
    }),
    sourceContainerAllChecked: Ember.computed('cargoDetailsChecked', 'commoditiesChecked', 'temperatureChecked', {
      get: function get() {
        return this.cargoDetailsChecked && this.commoditiesChecked && this.temperatureChecked;
      },
      set: function set(key, value) {
        this.set('cargoDetailsChecked', value);
        this.set('commoditiesChecked', value);

        if (this.copyContainerModalService.sourceContainerisReefer) {
          this.set('temperatureChecked', value);
        }

        return value;
      }
    }),
    toAllContainerChecked: Ember.computed('copyContainerModalService.copyToContainerArray.@each.checked', {
      get: function get() {
        return this.copyContainerModalService.copyToContainerArray.every(function (container) {
          return container.checked;
        });
      },
      set: function set(key, value) {
        this.copyContainerModalService.copyToContainerArray.forEach(function (container) {
          return container.set('checked', value);
        });
        return value;
      }
    }),
    _validateInput: function _validateInput() {
      this.set('alertErrors', []);
      var validationErrors = [];

      if (!this.isAnyDetailsChecked) {
        validationErrors.push({
          field: 'selectDetails',
          message: this.intl.t('booking.containerDetails.copyContainerModal.selectDetailError')
        });
      }

      if (!this.isAnyContainerChecked) {
        validationErrors.push({
          field: 'selectContainer',
          message: this.intl.t('booking.containerDetails.copyContainerModal.selectContainerError')
        });
      }

      if (this.invalidForReefer) {
        validationErrors.push({
          field: 'selectContainer',
          message: this.intl.t('booking.containerDetails.copyContainerModal.nonReeferError')
        });
      }

      this.set('alertErrors', validationErrors);
    },
    actions: {
      closeModal: function closeModal() {
        this.copyContainerModalService.closeModal();
        this.set('sourceContainerAllChecked', false);
        this.set('cargoDetailsChecked', false);
        this.set('commoditiesChecked', false);
        this.set('temperatureChecked', false);
        this.set('toAllContainerChecked', false);
      },
      paste: function paste() {
        this._validateInput();

        if (Ember.isBlank(this.get('alertErrors'))) {
          this.copyContainerModalService.copyContainer(this.cargoDetailsChecked, this.commoditiesChecked, this.temperatureChecked);
          this.flashMessages.success("\n          ".concat(this.intl.t('booking.containerDetails.copyContainerModal.notificationText1'), "\n          ").concat(parseInt(this.copyContainerModalService.sourceContainerIndex) + 1, "\n          ").concat(this.intl.t('booking.containerDetails.copyContainerModal.notificationText2'), "\n          ").concat(this.copyContainerModalService.copyToContainerArray.filter(function (container) {
            return container.checked;
          }).map(function (container) {
            return parseInt(container.index) + 1;
          }).join(','), "\n        "));
          this.send('closeModal');
        }
      }
    }
  });

  _exports.default = _default;
});