define("chimaera/mixins/booking/container-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    containerDetailIndexMaker: function containerDetailIndexMaker(index) {
      return "containerDetail_".concat(index);
    },
    containerWeightIndexMaker: function containerWeightIndexMaker(index) {
      return "containerWeight_".concat(index);
    },
    containerNumberIndexMaker: function containerNumberIndexMaker(index) {
      return "containerNumber_".concat(index);
    },
    flexitankIndexMaker: function flexitankIndexMaker(index) {
      return "flexitank_".concat(index);
    },
    shipperOwnedContainerIndexMaker: function shipperOwnedContainerIndexMaker(index) {
      return "shipperOwnedContainer_".concat(index);
    },
    sealNumberIndexMaker: function sealNumberIndexMaker(index) {
      return "sealNumber_".concat(index);
    },
    vgmIndexMaker: function vgmIndexMaker(index) {
      return "vgm_".concat(index);
    },
    volumeIndexMaker: function volumeIndexMaker(index) {
      return "volume_".concat(index);
    },
    shippingMarkIndexMaker: function shippingMarkIndexMaker(index) {
      return "shippingMark_".concat(index);
    }
  });

  _exports.default = _default;
});