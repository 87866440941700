define("chimaera/pods/booking/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "chimaera/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    referenceService: Ember.inject.service('reference'),
    commodityLookupService: Ember.inject.service('commodity-lookup'),
    bookingService: Ember.inject.service('booking'),
    eqService: Ember.inject.service('equipment-type'),
    wp: Ember.inject.service('welcome-pack'),
    ajax: Ember.inject.service('ajax'),
    statusService: Ember.inject.service('status'),
    flashMessages: Ember.inject.service(),
    errorService: Ember.inject.service('error'),
    model: function model(params) {
      var _this = this;

      return Promise.all([this.store.find('booking', params.order_id), this.eqService.getEquipment(), this.ajax.request("".concat(_environment.default.APP.BOOKING_URL, "/status?includeMappings=true"))]).then(function (res) {
        _this.bookingService.setSelectedEquipment(Ember.A(res.objectAt(1)));

        _this.statusService.setMappings(res.objectAt(2).statusMapping);

        return {
          booking: res.objectAt(0)
        };
      });
    },
    afterModel: function afterModel(model) {
      var supplier = model.booking.bookingDetailMainLeg.supplier;

      if (Ember.isBlank(supplier)) {
        this.errorService.error({
          issue: 'debug supplier',
          route: 'booking.edit',
          booking: model.booking.id,
          tenant: this.wp.tenantName,
          supplier: supplier
        });
      }

      this.set('commodityLookupService.supplierCode', model.booking.bookingDetailMainLeg.supplier);
      model.booking.bookingItems.forEach(function (bi) {
        bi.bookingItemDetails.forEach(function (bid) {
          if (Ember.isBlank(bid.id)) {
            bi.bookingItemDetails.removeObject(bid);
          }
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.booking);
    },
    deactivate: function deactivate() {
      this.flashMessages.clearMessages();
    },
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});