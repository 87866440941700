define("chimaera/utils/booking-query-params", ["exports", "@cetus/kontainers-ui-core/utils/uri"], function (_exports, _uri) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bookingQueryParams = void 0;
  var bookingQueryParams = {
    schedulesAfterDate: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    from: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    fromCountry: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    fromMode: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    fromType: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    fromIata: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    fromUNCode: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    loadType: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    quantity: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    to: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    toCountry: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    toMode: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    toType: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    toIata: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    toUNCode: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    commodities: {
      defaultValue: null,
      refresh: true,
      replace: true,
      serialize: function serialize(value) {
        if (value) {
          return Ember.typeOf(value) === 'string' ? value : JSON.stringify(value);
        }

        return value;
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (value) {
          value = (0, _uri.decodeUriComponentJson)(value);
          return Ember.isArray(value) ? value : JSON.parse(value);
        }
      }
    },
    importExport: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    asIncoterm: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    asProducts: {
      defaultValue: null,
      refresh: true,
      replace: true,
      serialize: function serialize(value) {
        if (value) {
          return Ember.typeOf(value) === 'string' ? value : JSON.stringify(value);
        }

        return value;
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (value) {
          value = (0, _uri.decodeUriComponentJson)(value);
          return Ember.isArray(value) ? value : JSON.parse(value);
        }
      }
    },
    storedQuoteId: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    storedQuoteRef: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    supplier: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    copyFrom: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    copyAttributes: {
      defaultValue: '',
      refresh: true,
      serialize: function serialize(value) {
        return Ember.typeOf(value) === 'string' ? value : JSON.stringify(value);
      },
      deserialize: function deserialize() {
        var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

        if (value) {
          value = (0, _uri.decodeUriComponentJson)(value);
          return value;
        }
      }
    },
    scheduleId: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    quoteId: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    reload: {
      defaultValue: null,
      replace: false,
      refresh: true
    },
    offlineQuoteRef: {
      defaultValue: null,
      refresh: true,
      replace: true
    },
    bookingId: {
      defaultValue: null,
      refresh: false,
      replace: true
    },
    airPickup: {
      defaultValue: false,
      replace: true
    },
    airDelivery: {
      defaultValue: false,
      replace: true
    },
    airSupplier: {
      defaultValue: '',
      replace: true
    },
    airTransit: {
      defaultValue: '',
      replace: true
    }
  };
  _exports.bookingQueryParams = bookingQueryParams;
});