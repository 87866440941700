define("chimaera/services/commodity-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    showModal: false,
    supplierCode: '',
    selectedCommodityDetails: null,
    commodityCode: '',
    commodityDescription: '',
    showTypeAhead: false,
    fromCountry: '',
    toCountry: '',
    showCommodityLookup: function showCommodityLookup(commodityDetail) {
      this.set('showModal', true);
      this.set('selectedCommodityDetails', commodityDetail);
      this.set('commodityCode', commodityDetail.commodityCode);
      this.set('commodityDescription', commodityDetail.description);
      this.set('showTypeAhead', false);
      this.set('fromCountry', commodityDetail.mainLegCountries.fromCountry || '');
      this.set('toCountry', commodityDetail.mainLegCountries.toCountry || '');
    },
    closeCommodityLookup: function closeCommodityLookup() {
      this.set('showModal', false);
    }
  });

  _exports.default = _default;
});