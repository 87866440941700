define("chimaera/serializers/booking-item-detail", ["exports", "ember-data", "chimaera/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    userService: Ember.inject.service('user'),
    currencyService: Ember.inject.service('currency'),
    normalize: function normalize(model, hash) {
      var _this$userService$def = this.userService.defaultMeasurementSystem,
          weightUnit = _this$userService$def.weightUnit,
          volumeUnit = _this$userService$def.volumeUnit;
      var valueCurrency = this.currencyService.selectedName;
      var attrs = [{
        key: 'weightUnit',
        val: weightUnit
      }, {
        key: 'volumeUnit',
        val: volumeUnit
      }, {
        key: 'valueCurrency',
        val: valueCurrency
      }];

      for (var _i = 0, _attrs = attrs; _i < _attrs.length; _i++) {
        var attr = _attrs[_i];

        if (!Ember.isBlank(attr.key) && !Object.keys(hash).includes(attr.key)) {
          hash[attr.key] = attr.val;
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});