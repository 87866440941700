define("chimaera/components/drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'select',
    content: null,
    value: '',
    optionValuePath: '',
    optionLabelPath: '',
    disableOption: false,
    disableOptionText: "Please Select",
    checkValueChange: Ember.observer('value', function () {
      if (Ember.isBlank(this.value)) {
        this.$("option[value='']").prop('selected', true);
      } else {
        this.$("option[value='".concat(this.value, "']")).prop('selected', true);
      }
    }),
    generateDisableOption: Ember.computed('disableOption', 'value', function () {
      if (this.disableOption === true && Ember.isBlank(this.value)) {
        return Ember.String.htmlSafe("<option selected=\"true\" disabled=\"disabled\">".concat(this.disableOptionText, "</option>"));
      } else if (this.disableOption === true && !Ember.isBlank(this.value)) {
        return Ember.String.htmlSafe("<option disabled=\"disabled\">".concat(this.disableOptionText, "</option>"));
      } else {
        return "";
      }
    }),
    initSelect: Ember.on('didInsertElement', function () {
      var _this = this;

      this.$().find("option[value='".concat(this.value, "']")).prop('selected', true);
      this.$().on('change', function () {
        var optionValue = false;

        if ($("option:selected", _this.$()).val() === 'true') {
          optionValue = $("option:selected", _this.$()).val() === 'true';
        } else if ($("option:selected", _this.$()).val() === 'false') {
          optionValue = $("option:selected", _this.$()).val() === 'true';
        } else {
          optionValue = $("option:selected", _this.$()).val();
        }

        _this.set('value', optionValue);
      });
    })
  });

  _exports.default = _default;
});