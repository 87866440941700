define("chimaera/models/booking", ["exports", "ember-data", "chimaera/mixins/dependent-relationships", "ember-data-copyable", "chimaera/services/welcome-pack", "luxon"], function (_exports, _emberData, _dependentRelationships, _emberDataCopyable, _welcomePack, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_dependentRelationships.default, _emberDataCopyable.default, {
    storedQuoteId: _emberData.default.attr('string'),
    copiedFromBookingId: _emberData.default.attr('string'),
    currency: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date-dd-mmmm-yyyy'),
    voyageNumber: _emberData.default.attr('string'),
    mailTrackingNumber: _emberData.default.attr('string'),
    customerReference: _emberData.default.attr('string'),
    bookingRef: _emberData.default.attr('string'),
    companyId: _emberData.default.attr('string'),
    userId: _emberData.default.attr('string'),
    bookingEnabled: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    totalCost: _emberData.default.attr('currency'),
    originalForms: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    numberOfForms: _emberData.default.attr('number'),
    incoTerms: _emberData.default.attr('string'),
    loadType: _emberData.default.attr('string'),
    importExport: _emberData.default.attr('string'),
    displayEtd: _emberData.default.attr('string'),
    displayEta: _emberData.default.attr('string'),
    freightCollectionType: _emberData.default.attr('string', {
      defaultValue: null
    }),
    tAndC: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // UI only
    totalTEU: _emberData.default.attr('string'),
    totalVolumeUnit: _emberData.default.attr('string'),
    totalVolume: _emberData.default.attr('string'),
    bookingItems: _emberData.default.hasMany('booking-item'),
    bookingCosts: _emberData.default.hasMany('booking-cost'),
    payingParties: _emberData.default.hasMany('paying-party'),
    logisticsDetails: _emberData.default.hasMany('logistics-detail'),
    references: _emberData.default.hasMany('reference'),
    consignorBookingAddress: _emberData.default.belongsTo('booking-address'),
    consigneeBookingAddress: _emberData.default.belongsTo('booking-address'),
    notifyBookingAddress: _emberData.default.belongsTo('booking-address'),
    bookedByAddress: _emberData.default.belongsTo('booking-address'),
    brokerageAddress: _emberData.default.belongsTo('booking-address'),
    confirmedWithAddress: _emberData.default.belongsTo('booking-address'),
    bookingDetailMainLeg: Ember.computed('logisticsDetails.@each.mode', function () {
      return this.logisticsDetails.find(function (ld) {
        return (0, _welcomePack.isMainMode)(ld.mode);
      });
    }),
    bookingDetailSea: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.find(function (ld) {
        return !!ld.get('seaLegData.id');
      });
    }),
    consignorAddressSingleLine: Ember.computed('consignorBookingAddress.addressText', function () {
      return this._toSingleLine(this.consignorBookingAddress.get('addressText'));
    }),
    consigneeAddressSingleLine: Ember.computed('consigneeBookingAddress.addressText', function () {
      return this._toSingleLine(this.consigneeBookingAddress.get('addressText'));
    }),
    notifyAddressSingleLine: Ember.computed('notifyBookingAddress.addressText', function () {
      return this._toSingleLine(this.notifyBookingAddress.get('addressText'));
    }),
    brokerageAddressSingleLine: Ember.computed('brokerageAddress.addressText', function () {
      return this._toSingleLine(this.brokerageAddress.get('addressText'));
    }),
    _toSingleLine: function _toSingleLine(addressText) {
      return addressText && addressText.split(',').join(', ');
    },
    originBookingCosts: Ember.computed('bookingCosts.[]', function () {
      return this.bookingCosts.filter(function (cost) {
        return cost.chargeEnd.toLowerCase() == 'origin';
      });
    }),
    destinationBookingCosts: Ember.computed('bookingCosts.[]', function () {
      return this.bookingCosts.filter(function (cost) {
        return cost.chargeEnd.toLowerCase() == 'destination';
      });
    }),
    freightBookingCosts: Ember.computed('bookingCosts.[]', function () {
      return this.bookingCosts.filter(function (cost) {
        return cost.chargeEnd.toLowerCase() == 'freight';
      });
    }),
    fromType: Ember.computed('logisticsDetails.[]', function () {
      return (0, _welcomePack.isMainMode)(this.logisticsDetails.firstObject.mode) ? 'Port' : 'City';
    }),
    fromLocationName: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.firstObject.fromLocation;
    }),
    fromCountryName: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.firstObject.fromCountry;
    }),
    fromLocationDisplayName: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.firstObject.fromLocationDisplayName;
    }),
    toType: Ember.computed('logisticsDetails.[]', function () {
      return (0, _welcomePack.isMainMode)(this.logisticsDetails.lastObject.mode) ? 'Port' : 'City';
    }),
    toLocationName: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.lastObject.toLocation;
    }),
    toCountryName: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.lastObject.toCountry;
    }),
    toLocationDisplayName: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.lastObject.toLocationDisplayName;
    }),
    isPortToPort: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.firstObject.mode === 'sea' && this.logisticsDetails.lastObject.mode === 'sea';
    }),
    isCfsToCfs: Ember.computed('logisticsDetails.[]', function () {
      return this.logisticsDetails.firstObject.mode === 'cfs' && this.logisticsDetails.lastObject.mode === 'cfs';
    }),
    totalContainers: Ember.computed('bookingItems.[]', function () {
      return this.get('bookingItems.length');
    }),
    isOffline: Ember.computed('references.[]', function () {
      return this.references && this.references.mapBy('referenceType').includes('offlineQuote');
    }),

    /*
    If there is a cut off date for that schedule. We will block one day before.
    If there is no cut off date. 3 days before ETD.
    */
    lastAvailablePickupDate: Ember.computed('bookingDetailMainLeg', function () {
      if (this.get('bookingDetailMainLeg.seaLegData.closingDate')) {
        return _luxon.DateTime.fromFormat(this.get('bookingDetailMainLeg.seaLegData.closingDate'), 'dd LLLL yyyy').minus({
          days: 1
        }).toJSDate();
      } else {
        return _luxon.DateTime.fromFormat(this.get('bookingDetailMainLeg.etd'), 'dd LLLL yyyy').minus({
          days: 3
        }).toJSDate();
      }
    }),
    isAir: Ember.computed.equal('loadType', 'air'),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  });

  _exports.default = _default;
});