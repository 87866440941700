define("chimaera/pods/forgot-password/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['resetInvalid'],
    resetInvalid: null,
    wasResetInvalid: Ember.computed('resetInvalid', {
      get: function get() {
        if (Ember.isBlank(this.resetInvalid)) {
          return false;
        }

        return this.resetInvalid;
      }
    })
  });

  _exports.default = _default;
});