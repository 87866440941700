define("chimaera/pods/application/controller", ["exports", "ui-shared/mixins/alertbox-trigger", "chimaera/mixins/application/note-modal-help", "chimaera/mixins/application/bclever-modal-bg-help"], function (_exports, _alertboxTrigger, _noteModalHelp, _bcleverModalBgHelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertboxTrigger.default, _noteModalHelp.default, _bcleverModalBgHelp.default, {
    queryParams: ['cookie_accepted', 'lang'],
    cookie_accepted: null,
    lang: null,
    showCookies: true,
    userService: Ember.inject.service('user'),
    wp: Ember.inject.service('welcome-pack'),
    session: Ember.inject.service(),
    customLinkTagUrls: Ember.computed.alias('wp.customLinkTagUrls'),
    primaryColor: Ember.computed('wp.wp', {
      get: function get() {
        return this.wp.wp.config['kontainers.website.color.primary'] || '#37b3c0';
      }
    }),
    secondaryColor: Ember.computed('wp.wp', {
      get: function get() {
        return this.wp.wp.config['kontainers.website.color.secondary'] || '#38a0d0';
      }
    })
  });

  _exports.default = _default;
});