define("chimaera/validations/commodity", ["exports", "ember-changeset-validations/validators", "chimaera/validators/number-by-unit"], function (_exports, _validators, _numberByUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    units: (0, _validators.validateNumber)({
      gt: 0,
      message: 'You must set the number of packages'
    }),
    lengthPerUnit: [(0, _validators.validateNumber)({
      gt: 0,
      message: 'Length must be greater than 0'
    }), (0, _numberByUnit.default)({
      lt: 350,
      unit: 'cms',
      unitProperty: 'distanceUnit'
    })],
    widthPerUnit: [(0, _validators.validateNumber)({
      gt: 0,
      message: 'Width must be greater than 0'
    }), (0, _numberByUnit.default)({
      lt: 225,
      unit: 'cms',
      unitProperty: 'distanceUnit'
    })],
    heightPerUnit: [(0, _validators.validateNumber)({
      gt: 0,
      message: 'Height must be greater than 0'
    }), (0, _numberByUnit.default)({
      lt: 240,
      unit: 'cms',
      unitProperty: 'distanceUnit'
    })],
    weightPerUnit: [(0, _validators.validateNumber)({
      gt: 0,
      message: 'Weight must be greater than 0'
    }), (0, _numberByUnit.default)({
      lt: 2500,
      unit: 'KG',
      unitProperty: 'weightUnit'
    })]
  };
  _exports.default = _default;
});