define("chimaera/pods/components/confirm-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['sure-modal', 'bclever-modal', 'bclever-modal-open'],
    classNameBindings: ['hideModal:display-none'],
    hideModal: true,
    question: null,
    cancelText: 'Cancel',
    confirmText: 'Delete',
    checkConfirmationRequests: Ember.observer('question', function () {
      if (!Ember.isBlank(this.question) && this.hideModal === true) {
        this.set('hideModal', false);
      }
    }),
    actions: {
      doCancel: function doCancel() {
        this.cancel();
        this.set('hideModal', true);
        this.set('question', '');
      },
      doConfirm: function doConfirm() {
        this.confirm();
        this.set('hideModal', true);
        this.set('question', '');
      }
    }
  });

  _exports.default = _default;
});