define("chimaera/mixins/login-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    welcomePack: Ember.inject.service(),
    show: Ember.computed('welcomePack.wp', function () {
      if (!Ember.isBlank(this.get('welcomePack.wp'))) {
        var componentName = this.getComponentName(this.toString()).split('-');
        var authType = componentName[componentName.length - 1];
        return authType === this.get('welcomePack.wp.config')['kontainers.auth.customer.implementation'];
      }

      return false;
    })
  });

  _exports.default = _default;
});