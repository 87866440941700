define("chimaera/adapters/location", ["exports", "chimaera/adapters/application", "chimaera/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.APP.TRIP_URL,
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/locations");
    }
  });

  _exports.default = _default;
});