define("chimaera/pods/booking/file/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // Services
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    // State
    draggedFiles: Ember.A(),
    // Lifecycle Hooks
    actions: {
      removeBadupload: function removeBadupload(uploadingFileIndex) {
        this.draggedFiles.removeAt(uploadingFileIndex);
      },
      removeFile: function removeFile(file) {
        var _this = this;

        file.destroyRecord().then(function () {
          _this.send('reload');
        }, function () {
          _this.flashMessages.error(_this.intl.t('error.deletingFile'), {
            sticky: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});