define("chimaera/pods/settings/change-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "niiqzf+C",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"dash_holder \"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content_holder\"],[12],[2,\"\\n\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"tab-content current-tab\"],[14,1,\"tab-1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"contact_info \"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"one columns\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"ten columns\"],[12],[2,\"\\n            \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"settings.changePwd.header\"],null]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"change-password\",[],[[],[]],null],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[2,\"    \"],[8,\"shared/powered-by\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/settings/change-password/template.hbs"
    }
  });

  _exports.default = _default;
});