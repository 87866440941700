define("chimaera/components/bill-template", ["exports", "@cetus/ember-cli-bill-templates/components/bill-template"], function (_exports, _billTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _billTemplate.default;
    }
  });
});