define("chimaera/services/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var roles = [{
    company: 'beneficialCargoOwner',
    shipment: 'import',
    roleAlias: 'consignee'
  }, {
    company: 'freightForwarder',
    shipment: 'export',
    roleAlias: 'consignor'
  }, {
    company: 'bco+Forwarder',
    shipment: null,
    roleAlias: null
  }];

  var _default = Ember.Service.extend({
    // State
    loggedinUser: null,
    invitedUser: null,
    roles: roles,
    // Computed Properties
    defaultMeasurementSystem: Ember.computed('loggedinUser.company.companyAddress.country', function () {
      var country = Ember.get(this, 'loggedinUser.company.companyAddress.country');

      if (country && country === 'United States') {
        return {
          system: 'imperial',
          weightUnit: 'LB',
          distanceUnit: 'inch',
          volumeUnit: 'CubicFeet',
          temperatureUnit: 'fahrenheit'
        };
      }

      return {
        system: 'metric',
        weightUnit: 'KG',
        distanceUnit: 'cms',
        volumeUnit: 'CubicMeters',
        temperatureUnit: 'celsius'
      };
    }),
    // TODO can we move this to user model?
    loggedinUserRole: Ember.computed('loggedinUser.company.role', {
      get: function get() {
        if (this.loggedinUser) {
          var company = this.loggedinUser.company;
          var role = company.role;
          return roles.find(function (r) {
            return r.company === role;
          });
        }
      }
    }),
    // Methods
    setLoggedinUser: function setLoggedinUser(userJson) {
      this.set('loggedinUser', userJson);
    },
    setInvitedUser: function setInvitedUser(userJson) {
      this.set('invitedUser', userJson);
    }
  });

  _exports.default = _default;
});