define("chimaera/models/company-address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    addressType: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    lastName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    address3: _emberData.default.attr('string'),
    address4: _emberData.default.attr('string'),
    cityTown: _emberData.default.attr('string'),
    postCode: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    danNumber: _emberData.default.attr('string'),
    vatNo: _emberData.default.attr('string'),
    danAuthorized: _emberData.default.attr('boolean'),
    sameAsInvoice: _emberData.default.attr('boolean'),
    companyName: Ember.computed('name', function () {
      return this.name;
    }),
    contactName: Ember.computed('firstName', 'lastName', {
      get: function get() {
        return "".concat(this.firstName, " ").concat(this.lastName);
      },
      set: function set(key, value) {
        var splitAr = value.split(/(\s+)/).filter(function (v) {
          return v.trim().length > 0;
        });
        var firstName = splitAr[0];
        var lastName = '';

        if (splitAr.length > 1) {
          splitAr.shift();
          lastName = splitAr.join(' ');
        }

        this.set('firstName', firstName);
        this.set('lastName', lastName);
        return value;
      }
    })
  });

  _exports.default = _default;
});