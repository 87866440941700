define("chimaera/pods/components/dashboard/status-item/component", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['two', 'columns'],
    classNameBindings: ['completeState:completed-state', 'statusCurrent:current-state'],
    lastCompletedStatus: '',
    statusDate: Ember.computed('status.changedAt', {
      get: function get() {
        if (Ember.isBlank(this.status.changedAt)) {
          return '-';
        } else {
          return _luxon.DateTime.fromISO(this.status.changedAt).toFormat('dd LLL yyyy');
        }
      }
    }),
    completeState: Ember.computed('status.changedAt', {
      get: function get() {
        return !Ember.isBlank(this.status.changedAt);
      }
    }),
    statusCurrent: Ember.computed('status.status', {
      get: function get() {
        return this.status.status === this.nextStatus;
      }
    })
  });

  _exports.default = _default;
});