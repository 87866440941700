define("chimaera/services/booking-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bookingNotes', []);
    },
    newNoteModal: false,
    currentBookingId: null,
    bookingNotes: null,
    noteBarState: 'hide',
    openNoteModal: function openNoteModal(bookingId, bookingNotes) {
      this.set('currentBookingId', bookingId);
      this.set('bookingNotes', bookingNotes);
      this.set('newNoteModal', true);
    },
    addNewNoteToAr: function addNewNoteToAr(note) {
      this.bookingNotes.unshiftObject(note);
    },
    closeNoteModal: function closeNoteModal() {
      this.set('newNoteModal', false);
      this.set('currentBookingId', null);
    },
    setNoteBarState: function setNoteBarState(state) {
      this.set('noteBarState', state);
    }
  });

  _exports.default = _default;
});