define("chimaera/mirage/endpoints/logger", ["exports", "chimaera/config/environment", "ember-cli-mirage/response"], function (_exports, _environment, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    // Post logs
    server.post("".concat(_environment.default.APP.LOGGER_URL), function (schema, request) {
      var levels = ['debug', 'info', 'warn', 'error'];

      var _JSON$parse = JSON.parse(request.requestBody),
          level = _JSON$parse.level,
          message = _JSON$parse.message;

      if (!levels.includes(level) || !level || !message) {
        return new _response.default(400, {
          'Content-Type': 'application/json'
        }, {
          reason: 'Request was formatted incorrectly'
        });
      }

      return new _response.default(200, {
        'Content-Type': 'application/json'
      }, {
        reason: 'success'
      });
    });
  }
});