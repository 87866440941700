define("chimaera/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    // serializeId(snapshot, json, primaryKey) {
    //   const id = snapshot.id;
    //   if(!Ember.isBlank(id)){
    //     json[primaryKey] = parseInt(id, 10);
    //   }
    // },
    serialize: function serialize(record) {
      var json = this._super.apply(this, arguments); // Get default serialization


      if (!Ember.isBlank(record.id)) {
        json.id = record.id; // tack on the id
      }

      return json;
    }
  });

  _exports.default = _default;
});