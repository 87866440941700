define("chimaera/mixins/confirm-modal-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    confirmationQuestion: null,
    actionToConfirm: null,
    actionToConfirmTarget: null,
    actions: {
      startActionConfirmation: function startActionConfirmation(action, target, question) {
        this.set('actionToConfirm', action);
        this.set('actionToConfirmTarget', target);

        if (typeof question === 'string') {
          this.set('confirmationQuestion', question);
        } else {
          this.set('confirmationQuestion', 'application.modal.confirm');
        }
      },
      doActionToConfirm: function doActionToConfirm() {
        this.send(this.actionToConfirm, this.actionToConfirmTarget);
        this.send('clearActionToConfirm');
      },
      clearActionToConfirm: function clearActionToConfirm() {
        this.set('actionToConfirm', null);
        this.set('actionToConfirmTarget', null);
      }
    }
  });

  _exports.default = _default;
});