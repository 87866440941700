define("chimaera/templates/components/drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TCrUfF8w",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[1,[34,3]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,2],[[32,1],[35,1],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"optionLabelPath\",\"optionValuePath\",\"dropdown-option\",\"generateDisableOption\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "chimaera/templates/components/drop-down.hbs"
    }
  });

  _exports.default = _default;
});