define("chimaera/models/charge-code", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    additionalService: (0, _attr.default)('string'),
    chargeCodeParent: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    hideChildren: (0, _attr.default)('boolean'),
    name: (0, _attr.default)('string'),
    uiHintCode: (0, _attr.default)('string'),
    uiDescriptionCode: (0, _attr.default)('string'),
    isIncoTerm: (0, _attr.default)('boolean'),
    incotermSettings: (0, _attr.default)(),
    // UI only
    isDefault: Ember.computed.equal('additionalService', 'IncludedByDefault'),
    isParent: Ember.computed.bool('children.length'),
    isChild: Ember.computed.bool('chargeCodeParent'),
    checked: Ember.computed('isDefault', {
      get: function get() {
        return this.isDefault;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    checkedChildName: Ember.computed('children.@each.checked', {
      get: function get() {
        var checkedChild = this.children.findBy('checked');
        return checkedChild ? checkedChild.name : '';
      },
      set: function set(key, value) {
        return value;
      }
    }),
    children: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  });

  _exports.default = _default;
});