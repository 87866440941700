define("chimaera/pods/booking/edit/controller", ["exports", "ui-shared/mixins/alertbox-trigger", "chimaera/mixins/addressbar-help", "chimaera/mixins/booking/order-saving"], function (_exports, _alertboxTrigger, _addressbarHelp, _orderSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertboxTrigger.default, _addressbarHelp.default, _orderSaving.default, {
    // Services
    bookingService: Ember.inject.service('booking'),
    bookingNoteService: Ember.inject.service('booking-note'),
    welcomePack: Ember.inject.service(),
    intl: Ember.inject.service(),
    // State
    bookingNote: null,
    isEditMode: true,
    // Lifecycle Hooks
    actions: {
      onSave: function onSave() {
        var transitionToPayment = this.welcomePack.booking.edit.onSave.transitionToPayment;

        if (transitionToPayment) {
          this.transitionToRoute('payment', {
            queryParams: {
              isEditMode: true
            }
          });
          return;
        }

        this.transitionToRoute('dashboard', {
          queryParams: {
            bookingState: 'edited'
          }
        });
      },
      showNotesBar: function showNotesBar() {
        this.bookingNoteService.setNoteBarState('show');
      }
    },
    // Methods
    setDefaultValueOnNewOrder: function setDefaultValueOnNewOrder() {
      this.model.logisticsDetails.forEach(function (item) {
        item.set('billIssuedLocation', item.toLocation);
      });
      this.model.bookingCosts.forEach(function (item) {
        item.set('paymentTerm', 'collect');
      });
    }
  });

  _exports.default = _default;
});