define("chimaera/mirage/factories/paying-party", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    bookingRef: function bookingRef() {
      return _faker.default.random.number();
    }
  });

  _exports.default = _default;
});