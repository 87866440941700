define("chimaera/models/rate", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    desc: _emberData.default.attr('string'),
    amount: _emberData.default.attr('stringToFloat'),
    amountTotal: _emberData.default.attr('currency'),
    equipment: _emberData.default.attr('string'),
    currency: _emberData.default.attr('string'),
    perTrip: _emberData.default.attr('boolean'),
    unit: _emberData.default.attr('stringToFloat'),
    originAmount: _emberData.default.attr('stringToFloat'),
    conversion: _emberData.default.attr('string'),
    supplierRef: _emberData.default.attr('string'),
    service: _emberData.default.attr('string'),
    tarName: _emberData.default.attr('string'),
    contractTariffName: _emberData.default.attr('string'),
    encryptedData: _emberData.default.attr('string'),
    chargeEnd: _emberData.default.attr('string'),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  });

  _exports.default = _default;
});