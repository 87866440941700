define("chimaera/pods/components/file-uploader/listing/file/queued/component", ["exports", "chimaera/pods/components/file-uploader/listing/file/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // State
    classNameBindings: ['failedUpload'],
    // Computed Properties
    failedUpload: Ember.computed('file.state', {
      get: function get() {
        return ['failed', 'timed_out', 'aborted'].includes(this.file.state);
      }
    }),
    // Actions
    actions: {
      selectedDescriptionChanged: function selectedDescriptionChanged() {
        Ember.set(this.file, 'errors', null);

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});