define("chimaera/pods/settings/index/controller", ["exports", "chimaera/config/environment", "ui-shared/mixins/alertbox-trigger", "chimaera/mixins/addressbar-help", "chimaera/pods/user/validation", "ember-changeset-validations", "ember-changeset", "chimaera/mixins/confirm-modal-trigger", "chimaera/mixins/validation/address-validation", "@cetus/ember-cli-model-validation/mixins/company/validation", "chimaera/pods/invite/validation", "luxon"], function (_exports, _environment, _alertboxTrigger, _addressbarHelp, _validation, _emberChangesetValidations, _emberChangeset, _confirmModalTrigger, _addressValidation, _validation2, _validation3, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertboxTrigger.default, _environment.default, _addressbarHelp.default, _addressValidation.default, _validation2.default, _confirmModalTrigger.default, {
    ajax: Ember.inject.service('ajax'),
    intl: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    wpService: Ember.inject.service('welcome-pack'),
    flashMessages: Ember.inject.service(),
    primaryAddyValidation: null,
    billingAddyValidation: null,
    userValidations: null,
    accountDetailsTab: true,
    isValidConfirmPassword: true,
    inviteEmail: null,
    users: null,
    team: null,
    bcoChecked: Ember.computed('user.company.role', {
      get: function get() {
        return this.user.company.get('role') === 'beneficialCargoOwner' || this.user.company.get('role') === 'bco+Forwarder';
      }
    }),
    freightForwarderChecked: Ember.computed('user.company.role', {
      get: function get() {
        return this.user.company.get('role') === 'freightForwarder' || this.user.company.get('role') === 'bco+Forwarder';
      }
    }),
    isSso: Ember.computed.reads('wpService.isOidc'),
    _refreshUser: function _refreshUser() {
      var _this = this;

      var url = "".concat(_environment.default.APP.TENANCY_URL, "/users/self");
      this.ajax.request(url, {
        headers: {
          Authorization: "Bearer ".concat(this.get('userService.loggedinUser.token'))
        }
      }).then(function (data) {
        _this.userService.setLoggedinUser(data.user);

        _this.flashMessages.success(_this.intl.t('settings.accountUpdated'));

        _this.set('confirmPasswordError', '');

        _this.set('isValidConfirmPassword', true);
      }, function () {
        _this.flashMessages.error(_this.intl.t('error.updateAccountFailed'), {
          sticky: true
        });
      });
    },
    _duplicateEmailInvite: function _duplicateEmailInvite(email) {
      return this.get('invites').find(function (invite) {
        return invite.email === email;
      });
    },
    actions: {
      closeAllTabs: function closeAllTabs() {
        this.set('accountDetailsTab', false);
        this.set('companyDetailsTab', false);
        this.set('billingAddressTab', false);
        this.set('teamSettingsTab', false);
      },
      openAccountDetailsTab: function openAccountDetailsTab() {
        this.send('closeAllTabs');
        this.set('accountDetailsTab', true);
      },
      openCompanyDetailsTab: function openCompanyDetailsTab() {
        this.send('closeAllTabs');
        this.set('companyDetailsTab', true);
      },
      openBillingAddressTab: function openBillingAddressTab() {
        this.send('closeAllTabs');
        this.set('billingAddressTab', true);
      },
      openTeamSettingsTab: function openTeamSettingsTab() {
        this.send('closeAllTabs');
        this.set('teamSettingsTab', true);
      },
      resendInvite: function resendInvite(invite) {
        var _this2 = this;

        this.ajax.request("".concat(_environment.default.APP.TENANCY_URL, "/resent/").concat(invite.id)).then(function () {
          _this2.flashMessages.success(_this2.intl.t('settings.team.inviteSent'));
        }).catch(function () {
          _this2.flashMessages.error(_this2.intl.t('error.savingInvite'), {
            sticky: true
          });
        });
      },
      saveInvite: function saveInvite() {
        var _this3 = this;

        var emailAddress = this.get('inviteEmail');
        var companyId = this.get('user.company.id');
        var invite = {
          email: emailAddress,
          companyId: companyId
        };
        var inviteChangeset = new _emberChangeset.default(invite, (0, _emberChangesetValidations.default)(_validation3.default), _validation3.default);
        Promise.all([inviteChangeset.validate()]).then(function () {
          if (inviteChangeset.get('isValid')) {
            _this3.ajax.post("".concat(_environment.default.APP.TENANCY_URL, "/teams"), {
              data: JSON.stringify({
                team: invite
              })
            }).then(function (data) {
              if (!_this3._duplicateEmailInvite(emailAddress)) {
                _this3.store.createRecord('invite', data['team']);
              }

              _this3.flashMessages.success(_this3.intl.t('settings.team.inviteSent'));

              _this3.set('inviteEmail', '');
            }).catch(function (error) {
              var payload = error.payload;
              var errors = payload.errors;

              if (Ember.isArray(errors)) {
                var adapterError = errors[0];
                var message = Object.keys(adapterError).includes('detail') ? adapterError.detail : _this3.intl.t('error.savingInvite');

                _this3.flashMessages.error(message, {
                  sticky: true
                });
              } else {
                var errorMessage = error.payload.reason || error.message;

                switch (errorMessage) {
                  case 'Cannot issue invite for active user':
                    _this3.flashMessages.error(_this3.intl.t('error.cannotInviteActiveUser'), {
                      sticky: true
                    });

                    break;

                  default:
                    _this3.flashMessages.error(_this3.intl.t('error.savingInvite'), {
                      sticky: true
                    });

                    break;
                }
              }
            });
          } else {
            _this3.parseChangeSetErrors(inviteChangeset.errors, 'inviteValidation');
          }
        });
      },
      deleteInvite: function deleteInvite(invite) {
        invite.destroyRecord();
      },
      togglePrimary: function togglePrimary(member) {
        var _this4 = this;

        Ember.set(member, 'isPrimary', !member.isPrimary);
        this.ajax.post("".concat(_environment.default.APP.TENANCY_URL, "/users/").concat(member.id, "/isPrimary"), {
          data: {
            isPrimary: member.isPrimary
          }
        }).then(function () {
          _this4.flashMessages.success(_this4.intl.t('settings.team.primarySaved'));
        });
      },
      deleteTeamMember: function deleteTeamMember(index) {
        var _this5 = this;

        var memberId = this.get('team')[index]['id'];
        this.ajax.delete("".concat(_environment.default.APP.TENANCY_URL, "/users/").concat(memberId)).then(function () {
          _this5.team.removeAt(index);
        });
      },
      saveUser: function saveUser() {
        var _this6 = this;

        // trim before validation
        Ember.setProperties(this.user, {
          firstName: this.user.firstName.trim(),
          lastName: this.user.lastName.trim(),
          email: this.user.email.trim()
        }); // confirmPassword validation

        if (!Ember.isBlank(this.get('user.password'))) {
          if (Ember.isBlank(this.get('confirmPassword'))) {
            this.set('isValidConfirmPassword', false);
            this.set('confirmPasswordError', this.intl.t('error.confirmPasswordEmpty'));
            return false;
          } else if (this.get('user.password') !== this.get('confirmPassword')) {
            this.set('isValidConfirmPassword', false);
            this.set('confirmPasswordError', this.intl.t('error.confirmPasswordWrong'));
            return false;
          }
        } else {
          this.set('isValidConfirmPassword', true);
        }

        var userChangeset = new _emberChangeset.default(this.user, (0, _emberChangesetValidations.default)(_validation.UserUpdateValidations), _validation.UserUpdateValidations);
        Promise.all([userChangeset.validate()]).then(function () {
          if (userChangeset.get('isValid')) {
            userChangeset.save().then(function () {
              var mpUserHash = {
                $email: _this6.get('user.email').toLowerCase().trim(),
                $first_name: _this6.get('user.firstName').trim(),
                $last_name: _this6.get('user.lastName').trim(),
                $created: _luxon.DateTime.local().toFormat('yyyy-LL-dd'),
                'Company Name': _this6.get('user.company.name')
              };
              mixpanel.people.set(mpUserHash);
              mixpanel.identify(_this6.get('user.email').toLowerCase());

              _this6._refreshUser();
            }, function () {
              _this6.flashMessages.error(_this6.intl.t('error.updateAccountFailed'), {
                sticky: true
              });
            });
          } else {
            _this6.parseChangeSetErrors(userChangeset.errors, 'userValidations');
          }
        });
      },
      savePrimary: function savePrimary() {
        var _this7 = this;

        var companyAddress = this.store.peekRecord('company-address', this.get('user.company.companyAddress.id'));
        var company = this.store.peekRecord('company', this.get('user.company.id'));
        var primaryAddressValidation = this.initAndValidateCompanyAddress(companyAddress, this.user, false);
        var companyValidation = this.validateCompany(company);

        if (Ember.isBlank(primaryAddressValidation) && Ember.isBlank(companyValidation)) {
          Promise.all([company.save(), companyAddress.save()]).then(function () {
            var mpUserHash = {
              email: _this7.get('user.email').toLowerCase().trim(),
              first_name: _this7.get('user.firstName').trim(),
              last_name: _this7.get('user.lastName').trim(),
              created: _luxon.DateTime.local().toFormat('yyyy-LL-dd'),
              'Company Name': _this7.get('user.company.name')
            };
            mixpanel.people.set(mpUserHash);
            mixpanel.identify(_this7.get('user.email').toLowerCase());

            _this7._refreshUser();
          }, function () {
            _this7.flashMessages.error(_this7.intl.t('error.updateAccountFailed'), {
              sticky: true
            });
          });
        } else {
          this.set('primaryAddyValidation', primaryAddressValidation.concat(companyValidation));
        }
      },
      saveBilling: function saveBilling() {
        var _this8 = this;

        var billingAddress = this.store.peekRecord('company-address', this.get('user.company.billingAddress.id'));
        var billingAddressValidation = this.initAndValidateBillingAddress(billingAddress, this.user);

        if (Ember.isBlank(billingAddressValidation)) {
          billingAddress.save().then(function () {
            _this8._refreshUser();
          }, function () {
            _this8.flashMessages.error(_this8.intl.t('error.updateAccountFailed'), {
              sticky: true
            });
          });
        } else {
          this.set('billingAddyValidation', billingAddressValidation);
        }
      }
    }
  });

  _exports.default = _default;
});