define("chimaera/serializers/logistics-detail", ["exports", "ember-data", "chimaera/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      seaLegData: {
        embedded: 'always'
      }
    },
    normalize: function normalize(model, hash) {
      if (Ember.isBlank(hash.seaLegData)) {
        delete hash.seaLegData;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});