define("chimaera/mirage/factories/leg", ["exports", "ember-cli-mirage", "chimaera/mirage/utils/locations", "chimaera/mirage/utils/vessels", "chimaera/mirage/utils/dates", "faker"], function (_exports, _emberCliMirage, _locations, _vessels, _dates, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    rates: null,
    children: null,
    issues: null,
    etd: function etd() {
      return (0, _dates.dateBetween)('2020-03-15', '2020-03-20');
    },
    eta: function eta() {
      return (0, _dates.dateBetween)('2020-03-22', '2020-03-31');
    },
    from: null,
    fromCountry: null,
    fromUNCode: null,
    lloydsNumber: '9597472',
    mode: 'sea',
    supplierName: 'APLS',
    to: null,
    toCountry: null,
    total: null,
    toUNCode: null,
    transitTime: function transitTime() {
      return _faker.default.random.number({
        min: 30,
        max: 60
      });
    },
    vesselName: null,
    vesselRegisteredCountry: null,
    voyage: null,
    // Traits
    fromAhmedabad: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Ahmedabad', 'from')),
    fromDelhi: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Delhi', 'from')),
    fromPipavav: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Pipavav (Victor) Port', 'from')),
    fromSyracuse: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Syracuse', 'from')),
    fromVancouver: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Vancouver', 'from')),
    toAhmedabad: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Ahmedabad', 'to')),
    toBuffalo: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Buffalo', 'to')),
    toNewAlbany: (0, _emberCliMirage.trait)((0, _locations.getLocation)('New Albany', 'to')),
    toNewYork: (0, _emberCliMirage.trait)((0, _locations.getLocation)('New York', 'to')),
    toSyracuse: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Syracuse', 'to')),
    toVancouver: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Vancouver', 'to')),
    vesselAplColumbus: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('APL COLUMBUS')),
    vesselWashington: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('OOCL WASHINGTON')),
    vesselOoclSeoul: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('OOCL SEOUL')),
    vesselAplVancouver: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('APL VANCOUVER')),
    vesselVessel8: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('VESSEL8')),
    vesselOoclHamburg: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('OOCL HAMBURG')),
    withRates: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(leg, server) {
        var rates = [server.create('rate', {
          equipment: '20DRY',
          amount: '5100'
        }, 'typeDestination'), server.create('rate', {
          equipment: '20DRY',
          amount: '5200'
        }, 'typeDestination'), server.create('rate', {
          equipment: '20DRY',
          amount: '5100'
        }, 'typeFreight'), server.create('rate', {
          equipment: '20DRY',
          amount: '5200'
        }, 'typeFreight'), server.create('rate', {
          equipment: '20DRY',
          amount: '5100'
        }, 'typeOrigin'), server.create('rate', {
          equipment: '20DRY',
          amount: '5200'
        }, 'typeOrigin')];
        var total = "".concat(rates.map(function (rate) {
          return parseFloat(rate.amount);
        }).reduce(function (a, b) {
          return a + b;
        }, 0));
        leg.update({
          rates: rates,
          total: total
        });
        leg.save();
      }
    }),
    withChildren: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(leg, server) {
        var children = [server.create('child', {
          transitTime: 32,
          mode: 'cfs'
        }, 'fromPipavav', 'toNewYork'), server.create('child', {
          transitTime: 62,
          mode: 'road'
        }, 'fromAhmedabad', 'toNewYork')];
        leg.update({
          children: children
        });
        leg.save();
      }
    }),
    withIssues: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(leg) {
        leg.update({
          issues: []
        });
        leg.save();
      }
    })
  });

  _exports.default = _default;
});