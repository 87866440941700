define("chimaera/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QLlVCadK",
    "block": "{\"symbols\":[],\"statements\":[[8,\"login/auth-userpass\",[],[[\"@doSignup\",\"@doSignInSuccess\"],[[30,[36,0],[[32,0],\"signup\"],null],[30,[36,0],[[32,0],\"signInSuccess\"],null]]],null],[2,\"\\n\"],[8,\"login/oidc\",[],[[\"@code\",\"@state\",\"@idToken\",\"@error\",\"@doSignInSuccess\"],[[32,0,[\"code\"]],[32,0,[\"state\"]],[32,0,[\"id_token\"]],[32,0,[\"error\"]],[30,[36,0],[[32,0],\"signInSuccess\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "chimaera/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});