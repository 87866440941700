define("chimaera/quote/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/application.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/application.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/additional-services/basket-bar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/additional-services/basket-bar.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/additional-services/product.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/additional-services/product.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/air-rate-list.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/air-rate-list.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/air-rate-list/rate-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/air-rate-list/rate-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/bookmark-bar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/bookmark-bar.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/charge-code-wrapper.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/charge-code-wrapper.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/charge-code-wrapper/expander-info.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/charge-code-wrapper/expander-info.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/container-dropdown-wrapper.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/container-dropdown-wrapper.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/container-dropdown-wrapper/container-dropdown.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/container-dropdown-wrapper/container-dropdown.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/container-dropdown-wrapper/container-dropdown/listbase-dropdown.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/container-dropdown-wrapper/container-dropdown/listbase-dropdown.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/date-picker.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/date-picker.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/focus-input.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/focus-input.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/incoterms.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/incoterms.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/lcl-load-wrapper.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/lcl-load-wrapper.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/lcl-load.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/lcl-load.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/lcl-load/lcl-load-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/lcl-load/lcl-load-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/left-nav-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/left-nav-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/left-nav.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/left-nav.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/load-type.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/load-type.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/location-finder.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/location-finder.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list-for-share.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list-for-share.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list-for-share/rate-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list-for-share/rate-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list-for-share/rate-item/rate-breakdown.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list-for-share/rate-item/rate-breakdown.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list-for-share/supplier-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list-for-share/supplier-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list/rate-item.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list/rate-item.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list/rate-item/child-breakdown.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list/rate-item/child-breakdown.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/multileg-rate-list/rate-item/rate-breakdown.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/multileg-rate-list/rate-item/rate-breakdown.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/rate-header.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/rate-header.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/send-quote.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/send-quote.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/shared-quotes-header.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/shared-quotes-header.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/shipment-type.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/shipment-type.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/index.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/index.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/index/additional-services.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/index/additional-services.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/index/air.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/index/air.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/index/listing.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/index/listing.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/index/new.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/index/new.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/index/share.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/index/share.hbs should pass TemplateLint.\n\n');
  });
});