define("chimaera/pods/reset-password/route", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // Services
    ajax: Ember.inject.service(),
    // Lifecycle Hooks
    model: function model(params) {
      return {
        token: params.token
      };
    },
    afterModel: function afterModel(model) {
      var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
      var proxy = ObjectPromiseProxy.create({
        promise: Ember.RSVP.resolve(this.ajax.request(_environment.default.APP.TENANCY_URL + '/forgetpassword', {
          data: {
            token: model.token
          }
        }))
      });
      var wrapper = this;
      proxy.then(function () {// continue
      }, function () {
        wrapper.transitionTo('forgot-password', {
          queryParams: {
            resetInvalid: true
          }
        });
      });
    }
  });

  _exports.default = _default;
});