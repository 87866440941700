define("chimaera/adapters/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _emberData.default.RESTAdapter.extend({
    // Services
    welcomePack: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    errorService: Ember.inject.service('error'),
    // State
    sessionDate: new Date().getTime(),
    // Computed Properties
    headers: Ember.computed('userService.loggedinUser.token', function () {
      var token = this.get('userService.loggedinUser.token');
      var sessionDate = this.sessionDate;
      return {
        Authorization: "Bearer ".concat(token),
        'X-Tenant-Id': this.get('welcomePack.wp.id'),
        'X-Session-Id': sessionDate,
        'Content-Type': 'application/json; charset=utf-8'
      };
    }),
    currentRequest: null,
    ajax: function ajax(url, type, options) {
      Ember.set(this, 'currentRequest', {
        url: url,
        type: type,
        options: options
      });
      return this._super.apply(this, arguments);
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 401 && this.session.isAuthenticated) {
        this.session.invalidate();
      }

      if (status === 400) {
        /*
          This is to properly log adapter errors
        */
        var _this$currentRequest = this.currentRequest,
            options = _this$currentRequest.options,
            url = _this$currentRequest.url,
            type = _this$currentRequest.type;

        var stack = _objectSpread(_objectSpread({}, payload), {}, {
          url: url,
          type: type
        });

        if (options.data) {
          stack['params'] = options.data;
        } // remove circular reference


        delete stack.errors;
        this.errorService.error(stack);
      }

      return this._super.apply(this, arguments);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload.reason) {
        return [{
          status: "".concat(status),
          title: "".concat(payload.reason)
        }];
      }

      if (payload && _typeof(payload) === 'object' && payload.errors) {
        return payload.errors;
      } else {
        return [{
          status: "".concat(status),
          title: 'The backend responded with an error',
          detail: "".concat(payload)
        }];
      }
    }
  });

  _exports.default = _default;
});