define("chimaera/pods/components/language-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gXARmvL6",
    "block": "{\"symbols\":[\"locale\"],\"statements\":[[10,\"button\"],[14,0,\"k-button simple icon icon_message\"],[14,\"data-test-action\",\"language-selector\"],[14,4,\"button\"],[12],[1,[30,[36,2],[\"navBar.language\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[12],[2,\"\\n  \"],[10,\"li\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"localeList\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"k-button simple\"],[16,\"data-test-action\",[30,[36,0],[\"language-\",[32,1,[\"value\"]]],null]],[24,4,\"button\"],[4,[38,1],[[32,0],\"setLocale\",[32,1,[\"value\"]]],null],[12],[1,[32,1,[\"display\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"action\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/language-selector/template.hbs"
    }
  });

  _exports.default = _default;
});