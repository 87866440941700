define("chimaera/pods/components/payment-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // State
    tagName: '',
    disabledPayButton: false,
    // Lifecycle Hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.set(this, 'disabledPayButton', false);
    }
  });

  _exports.default = _default;
});