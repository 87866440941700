define("chimaera/pods/components/dashboard/booking-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    bookingDashboardService: Ember.inject.service('booking/dashboard'),
    statusService: Ember.inject.service('status'),
    // State
    classNames: ['it-left-nav', 'hidden'],
    statusGroup: null,
    statusDropdown: null,
    // Computed Properties
    statusOptionGroup: Ember.computed.alias('bookingDashboardService.statusOptionGroup'),
    statusFilter: Ember.computed.alias('bookingDashboardService.statusFilter'),
    customerRef: Ember.computed.alias('bookingDashboardService.customerRefFilter'),
    bookingRefFilter: Ember.computed.alias('bookingDashboardService.bookingRefFilter'),
    emptyStatusFilter: Ember.computed.empty('statusFilter'),
    emptyCustomerRef: Ember.computed.empty('customerRef'),
    emptyBookingRefFilter: Ember.computed.empty('bookingRefFilter'),
    statusOptions: Ember.computed('statusOptionGroup.[]', 'statusGroup', {
      get: function get() {
        var statusOptionGroup = this.statusOptionGroup;

        if (!statusOptionGroup) {
          return [];
        }

        var statusOptions = statusOptionGroup.map(function (item) {
          return {
            display: item,
            value: item
          };
        });
        statusOptions.unshift({
          display: 'any status',
          value: ''
        });
        return statusOptions;
      }
    }),
    selectedStatus: Ember.computed('statusOptions.[]', 'statusFilter', {
      get: function get() {
        return this.statusOptions && this.statusOptions.filterBy('value', this.statusFilter).firstObject;
      }
    }),
    // Actions
    actions: {
      onStatusSelect: function onStatusSelect(status) {
        var value = status.value;
        Ember.set(this, 'statusFilter', value);
        this.onFilterChange(true);
      },
      onRefUpdate: function onRefUpdate() {
        this.onFilterChange();
      },
      clearFilter: function clearFilter(filter) {
        Ember.set(this, filter, '');
        this.onFilterChange(true);
      }
    }
  });

  _exports.default = _default;
});