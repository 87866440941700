define("chimaera/models/stored-quote", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    email: _emberData.default.attr('string'),
    // comma delimited list
    reference: _emberData.default.attr('string'),
    loadType: _emberData.default.attr('string'),
    fromLocation: _emberData.default.attr('string'),
    toLocation: _emberData.default.attr('string'),
    expiry: _emberData.default.attr('datetime-utc') // if we want to show the expired state
    // isExpired: computed('expiry', function() {
    //   if (!this.expiry) {
    //     return false;
    //   }
    //   // just initial assumption
    //   return DateTime.utc().startOf('day') > DateTime.fromISO(this.expiry).startOf('day');
    // })

  });

  _exports.default = _default;
});