define("chimaera/models/incoterm", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    // Attributes
    name: (0, _attr.default)('string'),
    displayName: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    isDefault: (0, _attr.default)('boolean')
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});