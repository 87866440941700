define("chimaera/services/status", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('statusMappings', []);
    },
    statusMappings: null,
    byCategory: Ember.computed('statusMappings', {
      get: function get() {
        return this.statusMappings.reduce(function (o, status) {
          var ourStatus = status.ourStatus,
              theirStatus = status.theirStatus,
              statusCategory = status.statusCategory;
          if (!o[statusCategory]) o[statusCategory] = {};
          o[statusCategory][Ember.String.camelize(ourStatus)] = theirStatus;
          return o;
        }, {});
      }
    }),
    allOpenStatusses: Ember.computed('statusMappings.[]', function () {
      return this.statusMappings.filter(function (o) {
        return (o.statusCategory === 'BI' || o.statusCategory === 'SI') && o.ourStatus !== 'created' && o.ourStatus !== 'proof of delivery';
      }).map(function (o) {
        return o.theirStatus;
      });
    }),
    allCancelStatusses: Ember.computed('statusMappings.[]', function () {
      return this.statusMappings.filter(function (o) {
        return o.ourStatus === 'cancelled';
      }).map(function (o) {
        return o.theirStatus;
      });
    }),
    allCompleteStatusses: Ember.computed('statusMappings.[]', function () {
      return this.statusMappings.filter(function (o) {
        return o.ourStatus === 'arrived' || o.ourStatus === 'proof of delivery';
      }).map(function (o) {
        return o.theirStatus;
      });
    }),
    toPortCompleteStatus: Ember.computed('statusMappings.[]', function () {
      return this.statusMappings.filterBy('ourStatus', 'arrived').mapBy('theirStatus').firstObject;
    }),
    toDoorCompleteStatus: Ember.computed('statusMappings.[]', function () {
      return this.statusMappings.filterBy('ourStatus', 'proof of delivery').mapBy('theirStatus').firstObject;
    }),
    getCategory: function getCategory(status) {
      return this.get(status).statusCategory;
    },
    get: function get(status) {
      return this.statusMappings.find(function (o) {
        return o.ourStatus === status;
      }) || '';
    },
    getIndex: function getIndex(status) {
      return this.statusMappings.findIndex(function (o) {
        return o.ourStatus === status;
      });
    },
    getCategoryByTheirStatus: function getCategoryByTheirStatus(status) {
      return this.getTheirStatus(status).statusCategory;
    },
    getTheirStatus: function getTheirStatus(status) {
      return this.statusMappings.find(function (o) {
        return o.theirStatus === status;
      }) || '';
    },
    setMappings: function setMappings(statusMappings) {
      this.set('statusMappings', statusMappings);
    },
    statusByGroup: Ember.computed('statusGroup', {
      get: function get() {
        var statusGroup = this.statusGroup;

        if (statusGroup === 'openBookingPage') {
          return this.allOpenStatusses;
        } else if (statusGroup === 'completedBookingPage') {
          return this.allCompleteStatusses;
        } else if (statusGroup === 'failedBookingPage') {
          return this.allCancelStatusses;
        }
      }
    }),
    getStatusByGroup: function getStatusByGroup(statusGroup) {
      if (statusGroup === 'openBookingPage') {
        return this.allOpenStatusses;
      } else if (statusGroup === 'completedBookingPage') {
        return this.allCompleteStatusses;
      } else if (statusGroup === 'failedBookingPage') {
        return this.allCancelStatusses;
      }
    },
    onOrAfterStatus: function onOrAfterStatus(curentStatus, _onOrAfterStatus) {
      return Ember.isBlank(curentStatus) ? false : this.getIndex(curentStatus) >= this.getIndex(_onOrAfterStatus);
    },
    bookingStatusMappingPromise: function bookingStatusMappingPromise() {
      return this.ajax.request("".concat(_environment.default.APP.BOOKING_URL, "/status?includeMappings=true"));
    }
  });

  _exports.default = _default;
});