define("chimaera/utils/liveperson", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._lp = _lp;
  _exports._lpUserData = _lpUserData;
  _exports._addLpDiv = _addLpDiv;
  _exports._bootLP = _bootLP;

  function _lp(livePersonKey) {
    window.lpTag = window.lpTag || {}, 'undefined' == typeof window.lpTag._tagCount ? (window.lpTag = {
      wl: lpTag.wl || null,
      scp: lpTag.scp || null,
      site: livePersonKey,
      //'91717203',
      section: lpTag.section || '',
      tagletSection: lpTag.tagletSection || null,
      autoStart: lpTag.autoStart !== !1,
      ovr: lpTag.ovr || {},
      _v: '1.10.0',
      _tagCount: 1,
      protocol: 'https:',
      events: {
        bind: function bind(t, e, i) {
          lpTag.defer(function () {
            lpTag.events.bind(t, e, i);
          }, 0);
        },
        trigger: function trigger(t, e, i) {
          lpTag.defer(function () {
            lpTag.events.trigger(t, e, i);
          }, 1);
        }
      },
      defer: function defer(t, e) {
        0 === e ? (this._defB = this._defB || [], this._defB.push(t)) : 1 === e ? (this._defT = this._defT || [], this._defT.push(t)) : (this._defL = this._defL || [], this._defL.push(t));
      },
      load: function load(t, e, i) {
        var n = this;
        setTimeout(function () {
          n._load(t, e, i);
        }, 0);
      },
      _load: function _load(t, e, i) {
        var n = t;
        t || (n = this.protocol + '//' + (this.ovr && this.ovr.domain ? this.ovr.domain : 'lptag.liveperson.net') + '/tag/tag.js?site=' + this.site);
        var o = document.createElement('script');
        o.setAttribute('charset', e ? e : 'UTF-8'), i && o.setAttribute('id', i), o.setAttribute('src', n), document.getElementsByTagName('head').item(0).appendChild(o);
      },
      init: function init() {
        this._timing = this._timing || {}, this._timing.start = new Date().getTime();
        var t = this;
        window.attachEvent ? window.attachEvent('onload', function () {
          t._domReady('domReady');
        }) : (window.addEventListener('DOMContentLoaded', function () {
          t._domReady('contReady');
        }, !1), window.addEventListener('load', function () {
          t._domReady('domReady');
        }, !1)), 'undefined' === typeof window._lptStop && this.load();
      },
      start: function start() {
        this.autoStart = !0;
      },
      _domReady: function _domReady(t) {
        this.isDom || (this.isDom = !0, this.events.trigger('LPT', 'DOM_READY', {
          t: t
        })), this._timing[t] = new Date().getTime();
      },
      vars: lpTag.vars || [],
      dbs: lpTag.dbs || [],
      ctn: lpTag.ctn || [],
      sdes: lpTag.sdes || [],
      hooks: lpTag.hooks || [],
      identities: lpTag.identities || [],
      ev: lpTag.ev || []
    }, lpTag.init()) : window.lpTag._tagCount += 1;
  }

  function _lpUserData(email, firstName, lastName) {
    lpTag.section = ['sampleSection'];
    lpTag.sdes.push({
      type: 'ctmrinfo',
      //MANDATORY
      info: {
        userName: email
      }
    });
    lpTag.sdes.push({
      type: 'personal',
      //MANDATORY
      personal: {
        firstname: firstName,
        lastname: lastName
      }
    });
  }

  function _addLpDiv(divId) {
    var lpDiv = document.createElement('div');
    lpDiv.id = divId;
    lpDiv.className = 'LPMcontainer LPMoverlay';
    lpDiv.setAttribute('role', 'button');
    lpDiv.setAttribute('tabindex', 0);
    document.getElementsByTagName('body')[0].appendChild(lpDiv);
  }

  function _bootLP(key, divId, authenticated, email, firstName, lastName) {
    if (authenticated === true && key !== '' && divId !== '') {
      _addLpDiv(divId);

      _lp(key);

      _lpUserData(email, firstName, lastName);
    }
  }
});