define("chimaera/utils/file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mbToBytes = mbToBytes;

  function mbToBytes(mb) {
    return mb * 1000000;
  }
});