define("chimaera/pods/components/dashboard/dash-item/component", ["exports", "chimaera/mixins/booking/address-format", "chimaera/mixins/booking/si-check", "luxon"], function (_exports, _addressFormat, _siCheck, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_addressFormat.default, _siCheck.default, {
    store: Ember.inject.service('store'),
    router: Ember.inject.service('router'),
    statusService: Ember.inject.service('status'),
    intl: Ember.inject.service(),
    currencyService: Ember.inject.service('currency'),
    wp: Ember.inject.service('welcome-pack'),
    expanded: false,
    buttonText: 'dashboard.tabs.openOrderTable.more',
    item: null,
    operator: null,
    ajax: Ember.inject.service('ajax'),
    suppliersService: Ember.inject.service('suppliers'),
    welcomePack: Ember.inject.service('welcome-pack'),
    dateFormat: 'yyyy-LL-dd',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.expanded && this.item.doExpand) {
        this.send('showHideDetail');
      }
    },
    disableEdit: Ember.computed('item', 'wp', {
      get: function get() {
        var etd = this.item.etd;
        var etdPlus = this.wp.booking.edit.disable.etdPlus;

        if (etdPlus) {
          if (this.isPastEdtPlus(etd, etdPlus)) {
            return true;
          }
        }

        var currentOurStatus = this.statusService.getTheirStatus(this.item.status).ourStatus;
        return Ember.isBlank(this.welcomePack.disableBookingStatus) ? false : this.statusService.onOrAfterStatus(currentOurStatus, this.welcomePack.disableBookingStatus);
      }
    }),
    filteredStatuses: Ember.computed.filter('item.statusTimestamps.[]', function (status) {
      return this.statusService.get(status.status).ourStatus !== 'created';
    }),
    bookingStatus: Ember.computed('item.status', {
      get: function get() {
        return this.item.status;
      }
    }),
    allCompleteStatusses: Ember.computed.alias('statusService.allCompleteStatusses'),
    showOperator: Ember.computed('operator', {
      get: function get() {
        return !Ember.isBlank(this.operator);
      }
    }),
    completed: Ember.computed('item.statusTimestamps.@each.changedAt', 'cancelled', {
      get: function get() {
        return !this.cancelled && !Ember.isBlank(this.item.statusTimestamps.lastObject.changedAt);
      }
    }),
    cancelled: Ember.computed('item.status', {
      get: function get() {
        return this.statusService.get(this.item.status).ourStatus === 'cancelled';
      }
    }),
    completedOrCancelled: Ember.computed('completed', 'cancelled', {
      get: function get() {
        return this.completed || this.cancelled;
      }
    }),
    ratesEnabled: Ember.computed('wp', {
      get: function get() {
        return this.wp.ratesEnabled;
      }
    }),
    editButtonText: Ember.computed('item.status', 'intl.locale', {
      get: function get() {
        return this.isSi ? this.intl.t('dashboard.tabs.openOrderTable.editSI', 'htmlSafe=true') : this.intl.t('dashboard.tabs.openOrderTable.edit', 'htmlSafe=true');
      }
    }),
    statusClassName: Ember.computed('item.statusTimestamps.[]', {
      get: function get() {
        if (!Ember.isBlank(this.item.statusTimestamps)) {
          return "legs-".concat(this.item.statusTimestamps.length - 1);
        }
      }
    }),
    nextStatus: Ember.computed('item.statusTimestamps.[]', {
      get: function get() {
        var next = this.item.statusTimestamps.find(function (item) {
          return Ember.isBlank(item.changedAt);
        });

        if (!Ember.isBlank(next)) {
          return next.status;
        }
      }
    }),
    supplierNames: Ember.computed('item.{loadType,logisticsDetails.@each.supplier}', {
      get: function get() {
        var _this = this;

        var supplierArr = this.item.logisticsDetails.mapBy('supplier').uniq();

        if (this.item.loadType === 'air') {
          return supplierArr.join();
        }

        var names = supplierArr.map(function (supplierCode) {
          return _this.suppliersService.find(supplierCode).fullName;
        });
        return names.join(', ');
      }
    }),
    bookingLoadTypeLabel: Ember.computed('item.loadType', 'intl.locale', {
      get: function get() {
        if (Ember.isBlank(this.item.loadType)) return;
        var text = this.intl.t("dashboard.dashItem.label.".concat(this.item.loadType));
        return Ember.String.htmlSafe("<strong>(".concat(text, ")</strong>"));
      }
    }),
    showPaymentValues: Ember.computed('welcomePack.{showPayment,ratesEnabled}', 'item.{storedQuoteId,isOffline,loadType}', {
      get: function get() {
        var _this$welcomePack = this.welcomePack,
            showPayment = _this$welcomePack.showPayment,
            ratesEnabled = _this$welcomePack.ratesEnabled;
        var _this$item = this.item,
            storedQuoteId = _this$item.storedQuoteId,
            isOffline = _this$item.isOffline,
            loadType = _this$item.loadType;
        /*
          Note, the full booking model is retrieved when item is expanded
        */

        return showPayment && ratesEnabled && !isOffline && !storedQuoteId && loadType !== 'air';
      }
    }),
    actions: {
      showHideDetail: function showHideDetail() {
        var _this2 = this;

        this.toggleProperty('expanded');

        if (this.expanded) {
          this.set('buttonText', 'dashboard.tabs.openOrderTable.less');
          this.store.findRecord('booking', this.get('item.id')).then(function (booking) {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }

            Ember.set(booking, 'statusTimestamps', _this2.item.statusTimestamps);
            Ember.set(_this2, 'item', booking);
            Ember.set(_this2, 'operator', _this2.confirmedWithAddressDisplay(booking));
          });
        } else {
          this.set('buttonText', 'dashboard.tabs.openOrderTable.more');
        }
      },
      showBOL: function showBOL(bookingId) {
        this.router.transitionTo('bol', bookingId);
      },
      showSI: function showSI(bookingId) {
        this.router.transitionTo('booking.edit', bookingId);
      },
      orderFiles: function orderFiles(bookingId) {
        this.router.transitionTo('booking.file', bookingId);
      }
    },
    isPastEdtPlus: function isPastEdtPlus(etd, etdPlus) {
      var today = _luxon.DateTime.local().startOf('day');

      var etdPlusDate = _luxon.DateTime.fromISO(etd).plus({
        days: etdPlus
      }).startOf('day');

      return today > etdPlusDate;
    }
  });

  _exports.default = _default;
});