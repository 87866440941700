define("chimaera/pods/components/address-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countryService: Ember.inject.service('country'),
    countries: Ember.computed.alias('countryService.countries'),
    addyObjInst: null,
    selectedLocationItem: null,
    isDisabled: '',
    obsSelectedLocation: Ember.observer('selectedLocationItem', function () {
      if (!Ember.isBlank(this.selectedLocationItem)) {
        this.set('addyObjInst.city', this.get('selectedLocationItem.searchName'));
      }
    })
  });

  _exports.default = _default;
});