define("chimaera/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    lastName: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    isPrimary: _emberData.default.attr('boolean'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    session: _emberData.default.attr('string'),
    referrer: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('number'),
    intercomHash: _emberData.default.attr('string'),
    kyc: _emberData.default.attr('boolean'),
    userCode: _emberData.default.attr('string'),
    company: _emberData.default.belongsTo('company'),
    inviteToken: _emberData.default.attr('string'),
    mixpanelIdentifyNeeded: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});