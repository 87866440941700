define("chimaera/mirage/factories/rate", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    desc: null,
    amount: null,
    unit: '1',
    amountTotal: function amountTotal() {
      return this.amount;
    },
    equipment: null,
    currency: 'USD',
    chargeEnd: null,
    perTrip: false,
    originAmount: function originAmount() {
      return this.amount;
    },
    conversion: '1',
    supplierRef: 'APLU-1235',
    tarName: null,
    service: 'CY/CY',
    encryptedData: 'xxxx',
    typeDestination: (0, _emberCliMirage.trait)({
      desc: 'destination fee',
      chargeEnd: 'Destination',
      tarName: 'DESTINATION'
    }),
    typeOrigin: (0, _emberCliMirage.trait)({
      desc: 'origin fee',
      chargeEnd: 'Origin',
      tarName: 'ORIGIN'
    }),
    typeFreight: (0, _emberCliMirage.trait)({
      desc: 'freight fee',
      chargeEnd: 'Freight',
      tarName: 'FREIGHT'
    })
  });

  _exports.default = _default;
});