define("chimaera/mirage/scenarios/shared", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.loadFixtures('countries');
    server.loadFixtures('welcome-packs');
    server.loadFixtures('translations');
    server.loadFixtures('suppliers');
    server.loadFixtures('statusMappings');
    server.loadFixtures('bookingReferenceTypes');
    server.create('user');
    server.create('notice');
    server.create('equipment', {
      name: '20DRY',
      displayName: '20\' Standard',
      isoCode: '22G0'
    });
    server.create('equipment', {
      name: '40HDRY',
      displayName: '40\' High Cube',
      isoCode: '45G0'
    });
    server.create('equipment', {
      name: '40REEFER',
      displayName: '41\' Reefer',
      isoCode: '42R1',
      isReefer: true
    });
    server.create('equipment', {
      name: '40DRY',
      displayName: '40\' Standard',
      isoCode: '42G0'
    });
  }
});