define("chimaera/mixins/application/bclever-modal-bg-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    bookingNoteService: Ember.inject.service('booking-note'),
    commodityLookupService: Ember.inject.service('commodity-lookup'),
    copyContainerModalService: Ember.inject.service('copy-container-modal'),
    isBcleverModalOpen: Ember.computed('bookingNoteService.newNoteModal', 'commodityLookupService.showModal', 'copyContainerModalService.showModal', function () {
      return this.bookingNoteService.newNoteModal || this.commodityLookupService.showModal || this.copyContainerModalService.showModal;
    })
  });

  _exports.default = _default;
});