define("chimaera/mirage/factories/notice", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    noticeText: '',
    displayFrom: '2020-03-04T09:08:13.097830',
    displayTo: '2020-03-04T09:08:13.097830',
    noticeType: 'info',
    audience: 'both',
    paused: true,
    tenantId: 'cd4813f2-f874-11e8-a2f8-878dc27f8782'
  });

  _exports.default = _default;
});