define("chimaera/pods/components/language-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['hideCountrySelector:display-none'],
    intl: Ember.inject.service('intl'),
    welcomePack: Ember.inject.service('welcome-pack'),
    localeList: Ember.computed('welcomePack.localeList', {
      get: function get() {
        return this.welcomePack.localeList;
      }
    }),
    hideCountrySelector: Ember.computed('welcomePack.localeList', {
      get: function get() {
        return Ember.isBlank(this.welcomePack.localeList) || this.welcomePack.localeList.length < 2;
      }
    }),
    actions: {
      setLocale: function setLocale(locale) {
        this.welcomePack.setupTranslations(locale);
      }
    }
  });

  _exports.default = _default;
});