define("chimaera/pods/components/dashboard/file-drop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RjO7BLPx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"dummy\"],[12],[10,\"div\"],[12],[13],[13],[2,\"\\n\"],[10,\"div\"],[14,1,\"dropable\"],[14,0,\"dropzone upload-drop-zone dz-default dz-message\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"dashboard.fileUpload.dragDrop\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/dashboard/file-drop/template.hbs"
    }
  });

  _exports.default = _default;
});