define("chimaera/pods/login/controller", ["exports", "ui-shared/mixins/alertbox-trigger", "chimaera/config/environment", "chimaera/utils/liveperson"], function (_exports, _alertboxTrigger, _environment, _liveperson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_alertboxTrigger.default, _environment.default, {
    // Services
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    noticeService: Ember.inject.service('notice'),
    welcomePack: Ember.inject.service(),
    // State
    queryParams: ['code', 'state', 'error', 'reg', 'id_token'],
    code: null,
    state: null,
    error: null,
    reg: false,
    id_token: null,
    blockSending: false,
    // Computed Properties
    bookByUserRole: Ember.computed.alias('welcomePack.bookByUserRole'),
    loggedinUserRole: Ember.computed.alias('userService.loggedinUserRole'),
    signInSuccessRoute: Ember.computed('queryParamsAtLogin', 'bookedByUserRole', 'loggedinUserRole', {
      get: function get() {
        var bookByUserRole = this.bookByUserRole,
            loggedinUserRole = this.loggedinUserRole,
            queryParamsAtLogin = this.queryParamsAtLogin;
        var shipment = loggedinUserRole.shipment; // If sharedQuoteReference, ignore role behaviour and show shared quotes in the dashboard

        if (Object.keys(queryParamsAtLogin).length) {
          if (Object.keys(queryParamsAtLogin).includes('sharedQuoteReference')) {
            return 'dashboard';
          }
        }

        if (bookByUserRole && shipment === 'import') {
          return 'quotes.index';
        }

        return 'dashboard';
      }
    }),
    // Lifecycle Hooks
    actions: {
      signup: function signup() {
        this.transitionToRoute('settings.registration', {
          queryParams: {
            email: this.loginEmail
          }
        });
      },
      signInSuccess: function signInSuccess() {
        var attemptedLoginTransition = this.session.attemptedLoginTransition;

        this._bootLivePerson(); // We manage this over ESA, due to order of logic


        if (attemptedLoginTransition) {
          attemptedLoginTransition.retry();
          Ember.set(this.session, 'attemptedLoginTransition', null);
          return;
        }

        this.transitionToRoute(this.signInSuccessRoute);
      }
    },
    // Methods
    _bootLivePerson: function _bootLivePerson() {
      var key = this.welcomePack.livepersonKey;
      var divId = this.welcomePack.livepersonDivId;
      var email = this.get('userService.loggedinUser.email');
      var firstName = this.get('userService.loggedinUser.firstName');
      var lastName = this.get('userService.loggedinUser.lastName');
      (0, _liveperson._bootLP)(key, divId, this.get('session.isAuthenticated'), email, firstName, lastName);
    }
  });

  _exports.default = _default;
});