define("chimaera/ui-shared/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/shared/alert-box.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/shared/alert-box.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/shared/powered-by.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/shared/powered-by.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/shared/typeahead-search.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/shared/typeahead-search.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/shared/includes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/shared/includes.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/shared/translate-with-fallback.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/shared/translate-with-fallback.js should pass ESLint\n\n');
  });
  QUnit.test('app/mixins/alertbox-trigger.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/mixins/alertbox-trigger.js should pass ESLint\n\n');
  });
  QUnit.test('app/mixins/amount-help.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/mixins/amount-help.js should pass ESLint\n\n');
  });
  QUnit.test('app/mixins/hide-charge.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/mixins/hide-charge.js should pass ESLint\n\n');
  });
  QUnit.test('app/mixins/number-formatter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/mixins/number-formatter.js should pass ESLint\n\n');
  });
  QUnit.test('app/mixins/typeahead-help.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/mixins/typeahead-help.js should pass ESLint\n\n');
  });
  QUnit.test('app/utils/load-calculations.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/utils/load-calculations.js should pass ESLint\n\n');
  });
  QUnit.test('app/utils/modes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/utils/modes.js should pass ESLint\n\n');
  });
  QUnit.test('app/utils/query-params.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/utils/query-params.js should pass ESLint\n\n');
  });
  QUnit.test('app/utils/serializer-quote-query-params.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/utils/serializer-quote-query-params.js should pass ESLint\n\n');
  });
});