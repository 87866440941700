define("chimaera/adapters/invite", ["exports", "chimaera/adapters/application", "chimaera/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.APP.TENANCY_URL,
    //query /teams instead of /invites
    pathForType: function pathForType() {
      return 'teams';
    }
  });

  _exports.default = _default;
});