define("chimaera/pods/components/dashboard/status-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4m+K93ZO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[32,0,[\"status\",\"status\"]]],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"status-indicator \",[30,[36,0],[[32,0,[\"completeState\"]],\"status-completed\"],null],\" \",[30,[36,0],[[32,0,[\"statusCurrent\"]],\"status-current\"],null]]]],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"status-date\"],[12],[1,[32,0,[\"statusDate\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/dashboard/status-item/template.hbs"
    }
  });

  _exports.default = _default;
});