define("chimaera/serializers/stored-quote", ["exports", "chimaera/serializers/application", "ui-shared/utils/serializer-quote-query-params"], function (_exports, _application, _serializerQuoteQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(model, hash) {
      var lclQuoteRequest = hash.lclQuoteRequest,
          fclQuoteRequest = hash.fclQuoteRequest,
          loadType = hash.loadType;
      var quoteRequest = loadType === 'lcl' ? lclQuoteRequest : fclQuoteRequest;
      var to = quoteRequest.to,
          from = quoteRequest.from;
      hash['fromLocation'] = from;
      hash['toLocation'] = to;

      if (hash.sharedWith && hash.sharedWith.length) {
        var email = hash.sharedWith.mapBy('email').join(', ');
        hash['email'] = email;
        delete hash.sharedWith;
      }

      hash.rateType = (0, _serializerQuoteQueryParams.getRateType)(hash.multiLegScheduleWithRate.legs);
      return this._super.apply(this, arguments);
    },
    // fake paginated results until server supported
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var queryParams = payload.queryParams;
      delete payload.queryParams;
      var page = queryParams.page,
          pageSize = queryParams.pageSize;
      var fromIdx = page * pageSize;
      var toIdx = (page + 1) * pageSize;
      payload.storedQuote = payload.storedQuote.sortBy('expiry').reverse();
      payload.storedQuote = payload.storedQuote.slice(fromIdx, toIdx);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});