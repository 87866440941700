define("chimaera/mixins/commodity-lookup-modal-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    commodityLookupService: Ember.inject.service('commodity-lookup'),
    actions: {
      showCommodityLookup: function showCommodityLookup(commodityDetail) {
        this.commodityLookupService.showCommodityLookup(commodityDetail);
      }
    }
  });

  _exports.default = _default;
});