define("chimaera/adapters/equipment", ["exports", "chimaera/adapters/application", "chimaera/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.APP.TRIP_URL,
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.host, "/equipmentTypes");
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (payload && status === 400) {
        payload.errors = payload;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});