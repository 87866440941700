define("chimaera/services/copy-container-modal", ["exports", "chimaera/models/booking-item-detail"], function (_exports, _bookingItemDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BookingItemChecker = void 0;
  var BookingItemChecker = Ember.Object.extend({
    index: null,
    checked: false,
    bookingItem: null,
    isReefer: false
  });
  _exports.BookingItemChecker = BookingItemChecker;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    bookingService: Ember.inject.service('booking'),
    _showModal: false,
    sourceContainerisReefer: false,
    sourceContainer: null,
    sourceContainerIndex: null,
    copyToContainerArray: null,
    cargoDetailsPropArray: null,
    commoditiesPropArray: null,
    temperaturePropArray: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('copyToContainerArray', []);
      this.set('cargoDetailsPropArray', ['containerDetail', 'weight', 'weightUnit', 'shipperOwnedContainer', 'flexitank']);
      this.set('commoditiesPropArray', ['bookingItemDetails']);
      this.set('temperaturePropArray', ['temperature', 'temperatureUnit', 'humidity', 'airFlow', 'airFlowUnit']);
    },
    showModal: Ember.computed('_showModal', function () {
      return this._showModal;
    }),
    openModal: function openModal(sourceContainer, sourceContainerIndex, bookingItems, sourceContainerisReefer) {
      var _this = this;

      this.set('_showModal', true);
      this.set('sourceContainer', sourceContainer);
      this.set('sourceContainerisReefer', sourceContainerisReefer);
      var copyToContainerIndexArray = Array(parseInt(bookingItems.length)).fill().map(function (x, i) {
        return i;
      });
      copyToContainerIndexArray.splice(copyToContainerIndexArray.indexOf(sourceContainerIndex), 1);
      this.set('sourceContainerIndex', sourceContainerIndex);
      this.set('copyToContainerArray', copyToContainerIndexArray.map(function (i) {
        return BookingItemChecker.create({
          index: i,
          container: bookingItems.objectAt(i),
          isReefer: _this.bookingService.isReeferByName(bookingItems.objectAt(i).containerType)
        });
      }));
    },
    closeModal: function closeModal() {
      this.set('_showModal', false);
      this.set('sourceContainerisReefer', false);
      this.set('sourceContainer', null);
      this.set('sourceContainerIndex', null);
      this.set('copyToContainerArray', []);
    },
    copyContainer: function copyContainer(cargoDetailsChecked, commoditiesChecked, temperatureChecked) {
      var _this2 = this;

      if (cargoDetailsChecked) {
        this.copyToContainerArray.forEach(function (element) {
          if (element.checked) {
            _this2._copyPropArray(element, _this2.cargoDetailsPropArray);
          }
        });
      }

      if (commoditiesChecked) {
        this.copyToContainerArray.forEach(function (element) {
          if (element.checked) {
            element.container.set('bookingItemDetails', []);
          }
        });

        if (!Ember.isBlank(this.sourceContainer.get('bookingItemDetails'))) {
          var bookingItemPropArray = Array.from(Ember.get(_bookingItemDetail.default, 'attributes').keys());
          this.copyToContainerArray.forEach(function (element) {
            if (element.checked) {
              _this2.sourceContainer.get('bookingItemDetails').forEach(function (sbid) {
                var newBid = element.container.get('bookingItemDetails').pushObject(_this2.store.createRecord('booking-item-detail'));
                bookingItemPropArray.forEach(function (prop) {
                  newBid.set(prop, sbid.get(prop));
                });
              });
            }
          });
        }
      }

      if (temperatureChecked) {
        this.copyToContainerArray.forEach(function (element) {
          if (element.checked && element.isReefer) {
            _this2._copyPropArray(element, _this2.temperaturePropArray);
          }
        });
      }
    },
    _copyPropArray: function _copyPropArray(element, propArray) {
      var _this3 = this;

      propArray.forEach(function (prop) {
        element.container.set(prop, _this3.sourceContainer.get(prop));
      });
    }
  });

  _exports.default = _default;
});