define("chimaera/utils/convert-units", ["exports", "@cetus/kontainers-ui-core/utils/convert-units"], function (_exports, _convertUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ft2in", {
    enumerable: true,
    get: function get() {
      return _convertUnits.ft2in;
    }
  });
  Object.defineProperty(_exports, "in2ft", {
    enumerable: true,
    get: function get() {
      return _convertUnits.in2ft;
    }
  });
  Object.defineProperty(_exports, "m2ft", {
    enumerable: true,
    get: function get() {
      return _convertUnits.m2ft;
    }
  });
  Object.defineProperty(_exports, "ft2m", {
    enumerable: true,
    get: function get() {
      return _convertUnits.ft2m;
    }
  });
  Object.defineProperty(_exports, "kg2lb", {
    enumerable: true,
    get: function get() {
      return _convertUnits.kg2lb;
    }
  });
  Object.defineProperty(_exports, "lb2kg", {
    enumerable: true,
    get: function get() {
      return _convertUnits.lb2kg;
    }
  });
  Object.defineProperty(_exports, "m2cm", {
    enumerable: true,
    get: function get() {
      return _convertUnits.m2cm;
    }
  });
  Object.defineProperty(_exports, "cm2m", {
    enumerable: true,
    get: function get() {
      return _convertUnits.cm2m;
    }
  });
  Object.defineProperty(_exports, "in2cm", {
    enumerable: true,
    get: function get() {
      return _convertUnits.in2cm;
    }
  });
  Object.defineProperty(_exports, "cm2in", {
    enumerable: true,
    get: function get() {
      return _convertUnits.cm2in;
    }
  });
  Object.defineProperty(_exports, "cm2ft", {
    enumerable: true,
    get: function get() {
      return _convertUnits.cm2ft;
    }
  });
  Object.defineProperty(_exports, "convertAs", {
    enumerable: true,
    get: function get() {
      return _convertUnits.convertAs;
    }
  });
  Object.defineProperty(_exports, "convertAsMapping", {
    enumerable: true,
    get: function get() {
      return _convertUnits.convertAsMapping;
    }
  });
});