define("chimaera/pods/components/cookie-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yw0fNgcU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cookie-message-holder\"],[12],[2,\"\\n  \"],[8,\"shared/powered-by\",[],[[],[]],null],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"locale\"]],\"cookie.text\"],null]],[[\"url\",\"htmlSafe\"],[[32,0,[\"wp\",\"cookieURL\"]],true]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"k-button simple\"],[24,\"data-test-action\",\"accept-cookie\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"acceptCookie\"],null],[12],[1,[30,[36,1],[\"cookie.accept\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/cookie-info/template.hbs"
    }
  });

  _exports.default = _default;
});