define("chimaera/mixins/application/number-formatter", ["exports", "accounting/format-number", "accounting/unformat"], function (_exports, _formatNumber, _unformat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    convertToNumber: function convertToNumber(input) {
      return Ember.isBlank(input) ? input : (0, _unformat.default)(input);
    },
    formatNumberWith2DecimalPlaces: function formatNumberWith2DecimalPlaces(input) {
      return isNaN(input) || Ember.isBlank(input) ? input : (0, _formatNumber.default)(input, {
        precision: 2,
        thousand: ''
      });
    }
  });

  _exports.default = _default;
});