define("chimaera/mixins/weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    validateWeight: function validateWeight(value, unit, target, validationErrors) {
      if (value == null) {
        mixpanel.track('order validation error', {
          property: 'weight'
        });
        validationErrors.push({
          field: target,
          message: this.intl.t('error.containerWeight')
        });
      } else {
        var regEx = new RegExp(/^\d+(\.\d{1,2})?$/);

        if (!regEx.test(value)) {
          mixpanel.track('order validation error', {
            property: 'weight',
            reason: 'not numbers'
          });
          validationErrors.push({
            field: target,
            message: this.intl.t('error.numberOnly')
          });
        } else if (Math.sign(parseInt(value)) !== 1) {
          validationErrors.push({
            field: target,
            message: this.intl.t('error.invalidNegativeNumber')
          });
        }

        if (parseInt(value) > 27000 && unit === 'Kg') {
          validationErrors.push({
            field: target,
            message: this.intl.t('error.less27kg')
          });
        }

        if (parseInt(value) > 59524 && unit === 'Lb') {
          validationErrors.push({
            field: target,
            message: this.intl.t('error.less59lb')
          });
        }
      }
    }
  });

  _exports.default = _default;
});