define("chimaera/pods/confirmation/controller", ["exports", "chimaera/config/environment", "luxon"], function (_exports, _environment, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, {
    queryParams: ['bookingType', 'bookingId', 'storedQuoteId', 'storedQuoteRef', 'offlineQuoteRef'],
    // Services
    intl: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    currencyService: Ember.inject.service('currency'),
    wp: Ember.inject.service('welcome-pack'),
    bookingService: Ember.inject.service('booking'),
    flashMessages: Ember.inject.service(),
    webStorage: Ember.inject.service(),
    // State
    bookingType: null,
    bookingId: null,
    disableGoToNext: false,
    // Computed Properties
    transitionToPayment: Ember.computed.alias('wp.booking.new.onSave.transitionToPayment'),
    dashboardNext: Ember.computed('offlineQuoteRef', 'storedQuoteId', 'bookingType', 'transitionToPayment', 'model.isAir', {
      get: function get() {
        return this.model.isAir || this.offlineQuoteRef || this.storedQuoteId || Ember.isEqual(this.bookingType, 'edit') || !this.transitionToPayment;
      }
    }),
    buttonName: Ember.computed('transitionToPayment', 'dashboardNext', 'intl.locale', 'model.isAir', {
      get: function get() {
        if (this.dashboardNext) {
          if (this.model.isAir) {
            return this.intl.t('booking.summary.confirmAndBook');
          }

          return this.transitionToPayment ? this.intl.t('booking.summary.goToDashboard') : this.intl.t('booking.summary.confirmBooking');
        }

        return this.intl.t('booking.summary.goToPayment');
      }
    }),
    showPaymentValues: Ember.computed('wp.showPayment', 'storedQuoteId', 'offlineQuoteRef', 'model.isAir', {
      get: function get() {
        return this.wp.showPayment && !this.storedQuoteId && !this.offlineQuoteRef && !this.model.isAir;
      }
    }),
    // Methods
    _notifyError: function _notifyError() {
      this.flashMessages.error('There is a problem saving your booking.', {
        sticky: true
      });
    },
    // Actions
    actions: {
      goToNext: function goToNext() {
        var _this = this;

        this.set('disableGoToNext', true);

        if (this.dashboardNext) {
          var data = {};

          if (this.storedQuoteId) {
            data['storedQuoteId'] = this.storedQuoteId;
          }

          return this.ajax.put("".concat(_environment.default.APP.BOOKING_URL, "/booking/tick/").concat(this.get('model.id')), {
            data: data
          }).then(function () {
            _this.bookingService.clearBasket();

            _this.bookingService.setNewBooking(_this.model.id);

            var _this$model = _this.model,
                loadType = _this$model.loadType,
                logisticsDetails = _this$model.logisticsDetails,
                customerReference = _this$model.customerReference,
                incoTerms = _this$model.incoTerms,
                bookingItems = _this$model.bookingItems,
                teuTotal = _this$model.teuTotal,
                totalVolumeUnit = _this$model.totalVolumeUnit,
                totalVolume = _this$model.totalVolume;
            var orgin = logisticsDetails.firstObject;
            var destination = logisticsDetails.lastObject;
            var mixpanelParams = {
              from: orgin.fromLocation,
              fromCountry: orgin.fromCountry,
              to: destination.toLocation,
              toCountry: destination.toCountry,
              bookingDate: _luxon.DateTime.utc().toISO(),
              cust_ref: customerReference,
              loadType: loadType,
              incoTerms: incoTerms
            };

            if (loadType === 'fcl') {
              mixpanelParams['quantity'] = bookingItems.length;
              mixpanelParams['teu'] = teuTotal;
            }

            if (loadType === 'lcl') {
              mixpanelParams['totalVolUnit'] = totalVolumeUnit;
              mixpanelParams['totalVol'] = totalVolume;
            }

            mixpanel.track('confirmation', mixpanelParams); // clear drafts

            _this.webStorage.removeLocal('draft-booking');

            _this.set('disableGoToNext', false);

            _this.transitionToRoute('dashboard', {
              queryParams: {
                bookingState: 'saved'
              }
            });
          }).catch(function () {
            _this._notifyError();

            _this.set('disableGoToNext', false);
          });
        }

        this.set('disableGoToNext', false);
        this.transitionToRoute('payment');
      },
      backToOrder: function backToOrder() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});