define("chimaera/@cetus/ember-cli-bill-templates/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/autosize-textarea.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/autosize-textarea.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/bill-template.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/bill-template.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/house-bill.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/house-bill.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/toll-bill.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/toll-bill.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/zim-bill.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/zim-bill.hbs should pass TemplateLint.\n\n');
  });
});