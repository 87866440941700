define("chimaera/pods/bol/controller", ["exports", "chimaera/mixins/bol/bol-actions"], function (_exports, _bolActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bolActions.default, {
    welcomePack: Ember.inject.service(),
    bookingNotes: null
  });

  _exports.default = _default;
});