define("chimaera/quote/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/components/additional-services/basket-bar.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/additional-services/basket-bar.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/additional-services/product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/additional-services/product.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/air-rate-list.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/air-rate-list.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/air-rate-list/rate-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/air-rate-list/rate-item.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/bookmark-bar.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/bookmark-bar.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/charge-code-wrapper.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/charge-code-wrapper.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/charge-code-wrapper/expander-info.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/charge-code-wrapper/expander-info.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/container-dropdown-wrapper.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/container-dropdown-wrapper.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/container-dropdown-wrapper/container-dropdown.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/container-dropdown-wrapper/container-dropdown.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/container-dropdown-wrapper/container-dropdown/listbase-dropdown.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/container-dropdown-wrapper/container-dropdown/listbase-dropdown.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/date-picker.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/date-picker.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/focus-input.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/focus-input.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/global-js-handlers.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/global-js-handlers.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/incoterms.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/incoterms.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/lcl-load-wrapper.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/lcl-load-wrapper.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/lcl-load.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/lcl-load.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/lcl-load/lcl-load-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/lcl-load/lcl-load-item.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/left-nav-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/left-nav-item.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/left-nav.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/left-nav.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/load-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/load-type.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/location-finder.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/location-finder.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list-for-share.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list-for-share.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list-for-share/rate-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list-for-share/rate-item.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list-for-share/rate-item/rate-breakdown.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list-for-share/rate-item/rate-breakdown.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list-for-share/supplier-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list-for-share/supplier-item.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list/rate-item.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list/rate-item.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list/rate-item/child-breakdown.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list/rate-item/child-breakdown.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/multileg-rate-list/rate-item/rate-breakdown.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/multileg-rate-list/rate-item/rate-breakdown.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/rate-header.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/rate-header.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/send-quote.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/send-quote.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/shared-quotes-header.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/shared-quotes-header.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/shipment-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/shipment-type.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/base.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/base.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/index.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/index/additional-services.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/index/additional-services.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/index/air.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/index/air.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/index/listing.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/index/listing.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/index/new.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/index/new.js should pass ESLint\n\n');
  });
  QUnit.test('addon/controllers/index/share.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/controllers/index/share.js should pass ESLint\n\n');
  });
  QUnit.test('addon/engine.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/engine.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/amount-splitter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/amount-splitter.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/date-formatter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/date-formatter.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/datesuffix-formatter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/datesuffix-formatter.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/equipment-name.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/equipment-name.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/location-icon.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/location-icon.js should pass ESLint\n\n');
  });
  QUnit.test('addon/helpers/month-formatter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/helpers/month-formatter.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/location-help.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/location-help.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/rates-help.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/rates-help.js should pass ESLint\n\n');
  });
  QUnit.test('addon/mixins/stage-common.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/mixins/stage-common.js should pass ESLint\n\n');
  });
  QUnit.test('addon/resolver.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/resolver.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/application.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/base.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/base.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/copy.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/copy.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index-redirect.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index-redirect.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index/additional-services.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index/additional-services.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index/air.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index/air.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index/listing.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index/listing.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index/new.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index/new.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/index/share.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/index/share.js should pass ESLint\n\n');
  });
  QUnit.test('addon/routes/login.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes/login.js should pass ESLint\n\n');
  });
  QUnit.test('addon/services/charge-code.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/services/charge-code.js should pass ESLint\n\n');
  });
  QUnit.test('addon/services/quotes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/services/quotes.js should pass ESLint\n\n');
  });
  QUnit.test('addon/utils/quote-query-params.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/utils/quote-query-params.js should pass ESLint\n\n');
  });
  QUnit.test('addon/validations/commodity.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/validations/commodity.js should pass ESLint\n\n');
  });
  QUnit.test('addon/validations/send-quote.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/validations/send-quote.js should pass ESLint\n\n');
  });
  QUnit.test('addon/validators/number-by-unit.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/validators/number-by-unit.js should pass ESLint\n\n');
  });
});