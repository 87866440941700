define("chimaera/mirage/utils/vessels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getVessel = _exports.vessels = void 0;
  var vessels = [{
    voyage: '11E',
    vesselName: 'APL COLUMBUS',
    vesselRegisteredCountry: 'SG'
  }, {
    voyage: '37E',
    vesselName: 'OOCL WASHINGTON',
    vesselRegisteredCountry: 'HK'
  }, {
    voyage: '46E',
    vesselName: 'OOCL SEOUL',
    vesselRegisteredCountry: 'HK'
  }, {
    voyage: '55E',
    vesselName: 'APL VANCOUVER',
    vesselRegisteredCountry: 'SG'
  }, {
    voyage: '35E',
    vesselName: 'VESSEL8',
    vesselRegisteredCountry: 'LR'
  }, {
    voyage: '117E',
    vesselName: 'OOCL HAMBURG',
    vesselRegisteredCountry: 'HK'
  }];
  _exports.vessels = vessels;

  var getVessel = function getVessel(vesselName) {
    return vessels.find(function (vessel) {
      return vessel.vesselName === vesselName;
    });
  };

  _exports.getVessel = getVessel;
});