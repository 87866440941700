define("chimaera/helpers/dropdown-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default Ember.Handlebars.makeBoundHelper(function(data, valueProp, displayProp) {
  var _default = Ember.Helper.helper(function (params, hash) {
    var data = params[0];
    var valueProp = params[1];
    var displayProp = params[2];
    var value = '';
    var display = '';

    if (typeof data.get === 'function') {
      value = data.get(valueProp);
      display = data.get(displayProp);
    } else {
      value = data[valueProp];
      display = data[displayProp];
    }

    if (Em.isBlank(valueProp)) {
      value = data;
      display = data;
    }

    var wrappedValue = value === undefined || value === null || value === true || value === false ? "".concat(value) : "\"".concat(value, "\"");
    return Ember.String.htmlSafe("<option value=".concat(wrappedValue, ">").concat(display, "</option>"));
  });

  _exports.default = _default;
});