define("chimaera/mirage/factories/booking-cost", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    description: function description() {
      return _faker.default.lorem.sentence();
    },
    amount: '100.00',
    amountTotal: '100.00',
    oriAmount: '100.00',
    conversion: '1',
    equipment: function equipment() {
      return _faker.default.random.arrayElement(['20DRY', '40HDRY', '40REEFER', '40DRY']);
    },
    currency: function currency() {
      return 'USD';
    },
    perTrip: function perTrip() {
      return _faker.default.random.arrayElement(['true', 'false']);
    },
    unit: function unit() {
      return _faker.default.random.number({
        min: 1,
        max: 5
      });
    },
    chargeEnd: function chargeEnd() {
      return _faker.default.random.arrayElement(['Destination', 'Origin', 'Freight']);
    },
    tarName: function tarName() {
      return this.chargeEnd;
    },
    paymentTerm: 'collect'
  });

  _exports.default = _default;
});