define("chimaera/pods/payment/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      isEditMode: {
        refreshModel: true
      }
    },
    // Services
    session: Ember.inject.service(),
    bookingService: Ember.inject.service('booking'),
    userService: Ember.inject.service('user'),
    // Lifecycle Hooks
    beforeModel: function beforeModel(transition) {
      if (Ember.isBlank(this.get('bookingService.basket'))) {
        transition.abort();
        this.transitionTo('index');
      }

      this.bookingService.fetchIncoTermTypes.perform();

      this._super(transition);
    },
    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        resolve(_this.get('bookingService.basket'));
      });
    },
    // Methods
    _singleLineBillingAddress: function _singleLineBillingAddress() {
      var _this2 = this;

      var singleLineArr = [];
      var keys = ['firstName', 'lastName', 'companyName', 'address1', 'address2', 'address3', 'address4', 'cityTown', 'region', 'country', 'postCode', 'vatNo'];
      keys.forEach(function (k) {
        if (!Ember.isBlank(_this2.get("userService.loggedinUser.company.billingAddress.".concat(k)))) {
          singleLineArr.push("".concat(_this2.get("userService.loggedinUser.company.billingAddress.".concat(k))));
        } else if (k === 'companyName') {
          singleLineArr.push("".concat(_this2.get('userService.loggedinUser.company.name')));
        }
      });
      return singleLineArr.join(', ');
    }
  });

  _exports.default = _default;
});