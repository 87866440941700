define("chimaera/pods/components/dashboard/bookmark-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['quote-module'],
    showSaveButton: false,
    textChanged: false,
    changeDetected: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$('.q-name').focusin(function () {
        _this.set('changeDetected', true);

        _this.set('showSaveButton', true);
      });
      this.$('.q-name').focusout(function () {
        _this.set('changeDetected', false);

        _this.set('showSaveButton', false);
      });
    },
    willClearRender: function willClearRender() {
      this.$('.q-name').off('focusin');
      this.$('.q-name').off('focusout');
    },
    actions: {
      saveNameChange: function saveNameChange() {
        this.doChangeName(this.item);
        this.set('showSaveButton', false);
      },
      delBookmark: function delBookmark() {
        this.doPromotDel(this.item);
        this.set('showSaveButton', false);
      }
    }
  });

  _exports.default = _default;
});