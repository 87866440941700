define("chimaera/pods/components/dashboard/signpost/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    welcomePack: Ember.inject.service(),
    // State
    showRetrieveQuotes: false,
    // Lifecycle Hooks
    actions: {
      retrieveQuote: function retrieveQuote(storedQuote) {
        this.doRetrieveQuote(storedQuote);
      }
    }
  });

  _exports.default = _default;
});