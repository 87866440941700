define("chimaera/pods/components/modal/note-lookup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bookingNoteService: Ember.inject.service('booking-note'),
    userService: Ember.inject.service('user'),
    store: Ember.inject.service('store'),
    classNames: ['bclever-modal', 'note-modal'],
    classNameBindings: ['bookingNoteService.newNoteModal:bclever-modal-open'],
    noteValue: '',
    actions: {
      closeModal: function closeModal() {
        this.set('noteValue', '');
        this.bookingNoteService.closeNoteModal();
      },
      saveNote: function saveNote() {
        var _this = this;

        this.store.createRecord('booking-note', {
          displayName: "".concat(this.userService.loggedinUser.firstName, " ").concat(this.userService.loggedinUser.lastName),
          note: this.noteValue,
          bookingId: this.bookingNoteService.currentBookingId
        }).save().then(function (note) {
          _this.set('noteValue', '');

          _this.bookingNoteService.addNewNoteToAr(note);

          _this.bookingNoteService.closeNoteModal();
        });
      }
    }
  });

  _exports.default = _default;
});