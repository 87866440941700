define("chimaera/utils/uri", ["exports", "@cetus/kontainers-ui-core/utils/uri"], function (_exports, _uri) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "decodeUriComponentJson", {
    enumerable: true,
    get: function get() {
      return _uri.decodeUriComponentJson;
    }
  });
  Object.defineProperty(_exports, "encodeUriComponentJson", {
    enumerable: true,
    get: function get() {
      return _uri.encodeUriComponentJson;
    }
  });
});