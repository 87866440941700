define("chimaera/pods/components/file-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j0K8RhWW",
    "block": "{\"symbols\":[\"@loadType\",\"@locale\",\"@queueName\",\"@accept\",\"@multiple\",\"@maxFileSizeMb\",\"@model\",\"@doUploadFile\"],\"statements\":[[10,\"h3\"],[15,0,[31,[\"docsHeading icon icon_\",[30,[36,0],[[32,1]],null]]]],[14,\"data-test-data\",\"filesAndDocuments\"],[12],[1,[30,[36,2],[[30,[36,1],[[32,2],\"heading.filesAndDocuments\"],null]],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\\n\"],[8,\"file-uploader/dropzone\",[],[[\"@locale\",\"@queueName\",\"@accept\",\"@multiple\",\"@maxFileSizeMb\",\"@modelFiles\"],[[30,[36,1],[[32,2],\"dropzone.\"],null],[32,3],[32,4],[32,5],[32,6],[32,0,[\"modelFiles\"]]]],null],[2,\"\\n\\n\"],[8,\"file-uploader/listing\",[],[[\"@locale\",\"@queueName\",\"@model\",\"@modelFiles\",\"@uploadQueue\",\"@maxFileSizeMb\",\"@doRemoveFile\",\"@doUploadFile\"],[[30,[36,1],[[32,2],\"listing.\"],null],[32,3],[32,7],[32,0,[\"modelFiles\"]],[32,0,[\"uploadQueue\"]],[32,6],[30,[36,3],[[32,0],\"doRemoveFile\"],null],[30,[36,3],[[32,0],[32,8]],null]]],null]],\"hasEval\":false,\"upvars\":[\"uppercase\",\"concat\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/file-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});