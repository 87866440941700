define("chimaera/pods/components/booking/booking-references/component", ["exports", "ui-shared/mixins/alertbox-trigger", "chimaera/mixins/string-help", "@cetus/kontainers-ui-core/utils/model-error"], function (_exports, _alertboxTrigger, _stringHelp, _modelError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_alertboxTrigger.default, _stringHelp.default, {
    // State
    classNames: ['other-refs', 'booking-refs'],
    // Services
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    referenceService: Ember.inject.service('reference'),
    // Computed Properties
    bookingReferences: Ember.computed.alias('booking.references'),
    isDisabledAddMore: Ember.computed('bookingReferences.@each.referenceType', 'writableReferenceTypeArray.[]', {
      get: function get() {
        var _this = this;

        var enteredWritableRefTypeArray = [];
        this.writableReferenceTypeArray.forEach(function (ref) {
          var foundRef = _this.bookingReferences.findBy('referenceType', ref.value);

          if (!Ember.isBlank(foundRef)) {
            enteredWritableRefTypeArray.pushObject(ref);
          }
        });
        return enteredWritableRefTypeArray.length === this.writableReferenceTypeArray.length;
      }
    }),
    allReferenceTypes: Ember.computed('bookingReferences.[]', 'referenceService.referenceTypes.[]', {
      get: function get() {
        if (Ember.isBlank(this.referenceService.referenceTypes)) {
          return [];
        }

        return this.referenceService.referenceTypes.map(function (ref) {
          return Ember.Object.create({
            value: ref.name,
            display: ref.displayName,
            readOnly: ref.readOnly
          });
        });
      }
    }),
    writableReferenceTypeArray: Ember.computed('allReferenceTypes.[]', {
      get: function get() {
        return this.allReferenceTypes.filter(function (ref) {
          return !ref.readOnly;
        });
      }
    }),
    selectableReferenceTypes: Ember.computed('writableReferenceTypeArray.[]', 'bookingReferences.[]', {
      get: function get() {
        if (Ember.isBlank(this.writableReferenceTypeArray)) {
          return [];
        }

        var refArray = this.writableReferenceTypeArray.toArray();
        refArray.insertAt(0, Ember.Object.create({
          value: null,
          display: '',
          readOnly: false
        }));
        this.bookingReferences.forEach(function (ref) {
          if (!Ember.isBlank(ref.referenceType)) {
            var foundReferenceTypeIndex = refArray.findIndex(function (i) {
              return i.value === ref.referenceType;
            });
            if (foundReferenceTypeIndex >= 0) refArray.removeAt(foundReferenceTypeIndex);
          }
        });
        return refArray;
      }
    }),
    // Methods
    validateReference: function validateReference() {
      this.bookingReferences.forEach(function (item) {
        var referenceType = item.referenceType,
            reference = item.reference;

        if (Ember.isBlank(referenceType) || Ember.isBlank(reference)) {
          (0, _modelError.addErrorToModel)(item, 'reference', 'error.fieldBlank');
        }
      });
    },
    // Actions
    actions: {
      addReference: function addReference() {
        this.booking.errors.remove('references');

        if (this.bookingReferences.filter(function (ref) {
          return Ember.isBlank(ref.reference) || Ember.isBlank(ref.referenceType);
        }).length > 0) {
          this.validateReference();
        } else {
          this.bookingReferences.pushObject(this.store.createRecord('reference'));
        }
      }
    }
  });

  _exports.default = _default;
});