define("chimaera/mirage/factories/child", ["exports", "ember-cli-mirage", "chimaera/mirage/utils/locations", "chimaera/mirage/utils/vessels", "chimaera/mirage/utils/dates"], function (_exports, _emberCliMirage, _locations, _vessels, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    eta: function eta() {
      return (0, _dates.dateBetween)('2020-03-22', '2020-03-31');
    },
    etd: function etd() {
      return (0, _dates.dateBetween)('2020-03-15', '2020-03-20');
    },
    from: null,
    fromCountry: null,
    fromUNCode: null,
    lloydsNumber: '9597472',
    supplierName: 'GoldStar',
    to: null,
    toCountry: null,
    toUNCode: null,
    transitTime: null,
    vesselName: null,
    vesselRegisteredCountry: null,
    voyage: null,
    mode: 'sea',
    // Traits
    fromAhmedabad: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Ahmedabad', 'from')),
    fromDelhi: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Delhi', 'from')),
    fromPipavav: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Pipavav (Victor) Port', 'from')),
    fromSyracuse: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Syracuse', 'from')),
    fromVancouver: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Vancouver', 'from')),
    toAhmedabad: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Ahmedabad', 'to')),
    toBuffalo: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Buffalo', 'to')),
    toNewAlbany: (0, _emberCliMirage.trait)((0, _locations.getLocation)('New Albany', 'to')),
    toNewYork: (0, _emberCliMirage.trait)((0, _locations.getLocation)('New York', 'to')),
    toSyracuse: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Syracuse', 'to')),
    toVancouver: (0, _emberCliMirage.trait)((0, _locations.getLocation)('Vancouver', 'to')),
    vesselAplColumbus: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('APL COLUMBUS')),
    vesselWashington: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('OOCL WASHINGTON')),
    vesselOoclSeoul: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('OOCL SEOUL')),
    vesselAplVancouver: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('APL VANCOUVER')),
    vesselVessel8: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('VESSEL8')),
    vesselOoclHamburg: (0, _emberCliMirage.trait)((0, _vessels.getVessel)('OOCL HAMBURG'))
  });

  _exports.default = _default;
});