define("chimaera/validators/password-contents", ["exports", "@cetus/kontainers-ui-core/utils/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePasswordContents;

  function validatePasswordContents(_ref) {
    var min = _ref.min;
    return function (key, newValue, oldValue, changes, content) {
      /*
        TODO
        Potential improvements:
        Incrementally build non-matching criteria message - need to consider locale
      */
      // if updating user with a blank password attribute
      if (!content.isNew && !newValue) {
        return true;
      }

      if (!newValue || newValue.length < min) {
        return {
          localeKey: 'error.changeset.passwordTooShort'
        };
      }
      /*
        We need to check against new record attrs (changes), but also existing record attrs (content)
      */


      var lowerCaseNewValue = (0, _string.safeLowercase)(newValue);

      if (changes.firstName && lowerCaseNewValue.includes((0, _string.safeLowercase)((0, _string.safeTrim)(changes.firstName))) || content.firstName && lowerCaseNewValue.includes((0, _string.safeLowercase)((0, _string.safeTrim)(content.firstName)))) {
        return {
          localeKey: 'error.changeset.passwordNoFirstName'
        };
      }

      if (changes.lastName && lowerCaseNewValue.includes((0, _string.safeLowercase)((0, _string.safeTrim)(changes.lastName))) || content.lastName && lowerCaseNewValue.includes((0, _string.safeLowercase)((0, _string.safeTrim)(content.lastName)))) {
        return {
          localeKey: 'error.changeset.passwordNoLastName'
        };
      }

      if (changes.email && lowerCaseNewValue.includes((0, _string.safeLowercase)((0, _string.safeTrim)(changes.email))) || content.email && lowerCaseNewValue.includes((0, _string.safeLowercase)((0, _string.safeTrim)(content.email)))) {
        return {
          localeKey: 'error.changeset.passwordNoEmail'
        };
      }

      var score = 0;
      score = newValue.match(/[0-9]/) ? score + 1 : score;
      score = newValue.match(/[A-Z]/) ? score + 1 : score;
      score = newValue.match(/[a-z]/) ? score + 1 : score;
      score = newValue.match(/[*@!#%&()^~{}]+/) ? score + 1 : score;
      return score > 2 ? true : {
        localeKey: 'error.changeset.passwordComplexity'
      };
    };
  }
});