define("chimaera/authenticators/ship", ["exports", "chimaera/authenticators/base", "chimaera/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ajax: Ember.inject.service('ajax'),
    userService: Ember.inject.service('user'),
    intl: Ember.inject.service('intl'),
    authenticate: function authenticate(userLoginObj) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax.post("".concat(_environment.default.APP.TENANCY_URL, "/login"), {
          data: JSON.stringify({
            user: userLoginObj
          })
        }).then(function (data) {
          _this.userService.setLoggedinUser(data.user);

          resolve(data.user);
          return data.user;
        }, function (err) {
          return reject(err);
        });
      });
    }
  });

  _exports.default = _default;
});