define("chimaera/pods/components/booking/booking-reference-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // State
    classNames: ['row', 'ref-row'],
    nonRemovableReferences: ['contract'],
    // Services
    store: Ember.inject.service(),
    // Computed Properties
    isCompleted: Ember.computed('bookingReference.{reference,referenceType}', {
      get: function get() {
        return !Ember.isBlank(this.bookingReference.referenceType) && !Ember.isBlank(this.bookingReference.reference);
      }
    }),
    isNonRemovable: Ember.computed('bookingReference.{reference,referenceType,editableReadOnly}', 'isCompleted', {
      get: function get() {
        var _this$bookingReferenc = this.bookingReference,
            reference = _this$bookingReferenc.reference,
            referenceType = _this$bookingReferenc.referenceType,
            editableReadOnly = _this$bookingReferenc.editableReadOnly;
        return editableReadOnly || this.nonRemovableReferences.includes(referenceType) && !Ember.isBlank(reference);
      }
    }),
    isReadOnly: Ember.computed('bookingReference', 'allReferenceTypes.[]', {
      get: function get() {
        var _this = this;

        var _this$bookingReferenc2 = this.bookingReference,
            referenceType = _this$bookingReferenc2.referenceType,
            editableReadOnly = _this$bookingReferenc2.editableReadOnly;

        if (editableReadOnly || Ember.isBlank(referenceType) || Ember.isBlank(this.allReferenceTypes)) {
          return false;
        }

        return this.allReferenceTypes.find(function (refType) {
          return refType.value == _this.bookingReference.referenceType;
        }).readOnly;
      }
    }),
    refDisplay: Ember.computed('bookingReference.referenceType', 'allReferenceTypes.[]', {
      get: function get() {
        var _this2 = this;

        if (Ember.isBlank(this.bookingReference.referenceType) || Ember.isBlank(this.allReferenceTypes)) {
          return;
        }

        return this.allReferenceTypes.filter(function (item) {
          return item.value === _this2.bookingReference.referenceType;
        })[0].display;
      }
    }),
    isReadOnlyOrNotNew: Ember.computed('isReadOnly', {
      get: function get() {
        var _this$bookingReferenc3 = this.bookingReference,
            isNew = _this$bookingReferenc3.isNew,
            editableReadOnly = _this$bookingReferenc3.editableReadOnly;
        return editableReadOnly || this.isReadOnly || !isNew;
      }
    }),
    isReferenceTypeSelected: Ember.computed('bookingReference.{referenceType,reference}', {
      get: function get() {
        return !Ember.isBlank(this.bookingReference.referenceType);
      }
    }),
    isOfflineReference: Ember.computed('bookingReference.referenceType', {
      get: function get() {
        return this.bookingReference.referenceType === 'offlineQuote';
      }
    }),
    // Actions
    actions: {
      removeReference: function removeReference(bookingReference) {
        this.allBookingReferences.removeObject(bookingReference);
        bookingReference.deleteRecord();
      },
      onTypeSelect: function onTypeSelect(type) {
        var display = type.display,
            value = type.value;
        Ember.setProperties(this.bookingReference, {
          displayName: display,
          referenceType: value
        });
      },
      scrollToFiles: function scrollToFiles() {
        var element = document.getElementsByClassName('booking-docs')[0];

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    }
  });

  _exports.default = _default;
});