define("chimaera/helpers/beautify-addy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var finalSt = [];
    params[0].split(',').forEach(function (token) {
      finalSt.push(token);
    });
    return Ember.String.htmlSafe(finalSt.join(',<br>'));
  });

  _exports.default = _default;
});