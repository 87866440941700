define("chimaera/mixins/dependent-relationships", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // this mixin is to fix this bug https://github.com/emberjs/data/issues/1829
  // just mixin to the model (see order)
  var _default = Ember.Mixin.create({
    save: function save() {
      var _this = this;

      var recordsToDelete = [];
      this.eachRelationship(function (name, descriptor) {
        if (descriptor.kind === 'hasMany') {
          _this.get(name).forEach(function (object) {
            if (object.get('isNew')) {
              recordsToDelete.push(object);
            }
          });
        }
      });

      var promise = this._super.apply(this, arguments);

      promise.then(function () {
        recordsToDelete.forEach(function (record) {
          record.deleteRecord();
        });
      });
      return promise;
    }
  });

  _exports.default = _default;
});