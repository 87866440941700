define("chimaera/pods/components/booking/lcl-load/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    welcomePack: Ember.inject.service(),
    // State
    locale: 'booking.lclLoad.',
    isExpanded: true,
    // Computed Properties
    bookingItemDetails: Ember.computed.alias('bookingItem.bookingItemDetails'),
    distanceUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.distanceUnits'),
    // Lifecycle Hooks
    init: function init() {
      this._super.apply(this, arguments);

      var commodities = this.commodities;

      if (this.bookingItemDetails.length) {
        commodities = this._getCommodities(this.bookingItemDetails);
      }

      Ember.set(this, 'commodities', commodities);
      Ember.set(this, 'bookingItemDetailsByCommodities', this._groupBookingItemDetailsByCommodities());
    },
    // Methods
    _groupBookingItemDetailsByCommodities: function _groupBookingItemDetailsByCommodities() {
      var _this = this;

      return this.commodities.reduce(function (a, commodity) {
        Ember.set(commodity, 'bookingItemDetails', _this._filterBookingItemDetails(commodity));
        return [].concat(_toConsumableArray(a), [commodity]);
      }, []);
    },
    _setCommodityTotalWeight: function _setCommodityTotalWeight() {
      return this.commodities.reduce(function (a, commodity) {
        var units = commodity.units,
            weightPerUnit = commodity.weightPerUnit;
        commodity.totalWeight = weightPerUnit * units;
        return [].concat(_toConsumableArray(a), [commodity]);
      }, []);
    },
    _filterBookingItemDetails: function _filterBookingItemDetails(commodity) {
      var _this2 = this;

      delete commodity.units;
      return this.bookingItemDetails.filter(function (bookingItemDetail) {
        var attrs = _this2._getCommodityDetails(bookingItemDetail);

        if (JSON.stringify(attrs) === JSON.stringify(commodity)) {
          return bookingItemDetail;
        }
      });
    },
    _getCommodities: function _getCommodities(bookingItemDetails) {
      var _this3 = this;

      return bookingItemDetails.reduce(function (commodities, bookingItemDetail) {
        var attrs = _this3._getCommodityDetails(bookingItemDetail);

        var existingCommodities = commodities.filter(function (commodity) {
          if (JSON.stringify(commodity) === JSON.stringify(attrs)) {
            return commodity;
          }
        });
        return !existingCommodities.length ? [].concat(_toConsumableArray(commodities), [attrs]) : commodities;
      }, []);
    },
    _getCommodityDetails: function _getCommodityDetails(bookingItemDetail) {
      var lengthPerUnit = bookingItemDetail.lengthPerUnit,
          widthPerUnit = bookingItemDetail.widthPerUnit,
          heightPerUnit = bookingItemDetail.heightPerUnit,
          weightPerUnit = bookingItemDetail.weightPerUnit,
          distanceUnit = bookingItemDetail.distanceUnit,
          weightUnit = bookingItemDetail.weightUnit;
      return {
        lengthPerUnit: lengthPerUnit,
        widthPerUnit: widthPerUnit,
        heightPerUnit: heightPerUnit,
        weightPerUnit: weightPerUnit,
        distanceUnit: distanceUnit,
        weightUnit: weightUnit
      };
    }
  });

  _exports.default = _default;
});