define("chimaera/models/logistics-detail", ["exports", "ember-data", "chimaera/services/welcome-pack", "ember-cli-string-helpers/utils/titleize"], function (_exports, _emberData, _welcomePack, _titleize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // Attributes
    eta: _emberData.default.attr('date-dd-mmmm-yyyy'),
    etd: _emberData.default.attr('date-dd-mmmm-yyyy'),
    ata: _emberData.default.attr('date-dd-mmmm-yyyy'),
    atd: _emberData.default.attr('date-dd-mmmm-yyyy'),
    vgmCutOffTime: _emberData.default.attr('datetime-utc'),
    siDocCutOffTime: _emberData.default.attr('datetime-utc'),
    latestPortArrivalTime: _emberData.default.attr('datetime-utc'),
    fromLocation: _emberData.default.attr('string'),
    fromCountry: _emberData.default.attr('string'),
    fromUNCode: _emberData.default.attr('string'),
    toLocation: _emberData.default.attr('string'),
    toCountry: _emberData.default.attr('string'),
    toUNCode: _emberData.default.attr('string'),
    machineName: _emberData.default.attr('string'),
    machineRegisteredCountry: _emberData.default.attr('string'),
    voyageNumber: _emberData.default.attr('string'),
    mode: _emberData.default.attr('string'),
    supplier: _emberData.default.attr('string'),
    childLegs: _emberData.default.attr(),
    // Relationships
    seaLegData: _emberData.default.belongsTo('sea-leg-data'),
    booking: _emberData.default.belongsTo('booking'),
    // Computed Properties
    fromLocationDisplayName: Ember.computed('fromLocation', {
      get: function get() {
        return this.fromLocation && this.fromLocation.capitalize();
      }
    }),
    toLocationDisplayName: Ember.computed('toLocation', {
      get: function get() {
        return this.toLocation && this.toLocation.capitalize();
      }
    }),
    fromLocWithCountry: Ember.computed('fromLocation', 'fromCountry', {
      get: function get() {
        return "".concat(this.fromLocation, ", ").concat((0, _titleize.default)(this.fromCountry));
      }
    }),
    toLocWithCountry: Ember.computed('toLocation', 'toCountry', {
      get: function get() {
        return "".concat(this.toLocation, ", ").concat((0, _titleize.default)(this.toCountry));
      }
    }),
    isMainLeg: Ember.computed('mode', function () {
      return (0, _welcomePack.isMainMode)(this.mode);
    }),
    locationType: Ember.computed('isMainLeg', function () {
      return this.isMainLeg ? 'Port' : 'City';
    })
  });

  _exports.default = _default;
});