define("chimaera/pods/components/file-uploader/dropzone/component", ["exports", "chimaera/utils/file-size"], function (_exports, _fileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Actions
    actions: {
      onFileAdd: function onFileAdd(file) {
        /*
          Note:
          Files which are auto removed need to be hidden in queuedFiles on listing
          See listing queuedFiles
        */
        var isDuplicatedInQueue = file.queue.files.filterBy('name', file.name).length > 1;
        var isDuplicatedOnModel = this.modelFiles && this.modelFiles.mapBy('displayFileName').includes(file.name);
        var isTooLarge = file.size > (0, _fileSize.mbToBytes)(this.maxFileSizeMb);

        if (isDuplicatedInQueue || isDuplicatedOnModel) {
          this.flashMessages.error("".concat(this.intl.t("".concat(this.locale, "notification.fileDuplicate")), " ").concat(file.name), {
            sticky: true
          }); // remove self from queue

          file.queue.remove(file);
          return;
        }

        if (isTooLarge) {
          this.flashMessages.error("".concat(this.intl.t("".concat(this.locale, "notification.fileTooLarge")), " ").concat(file.name), {
            sticky: true
          }); // remove self from queue

          file.queue.remove(file);
          return;
        }

        this.flashMessages.success(this.intl.t("".concat(this.locale, "notification.fileAdded")));
      }
    }
  });

  _exports.default = _default;
});