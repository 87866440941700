define("chimaera/pods/components/booking/lcl-load/lcl-load-commodity/component", ["exports", "chimaera/mixins/commodity-lookup-modal-help"], function (_exports, _commodityLookupModalHelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_commodityLookupModalHelp.default, {
    // Services
    currencyService: Ember.inject.service('currency'),
    commodityLookupService: Ember.inject.service('commodity-lookup'),
    // State
    locale: 'booking.lclLoadCommodity.',
    // Computed Properties
    currencyOptions: Ember.computed.alias('currencyService.currencyOptions'),
    actions: {
      onCurrencyChange: function onCurrencyChange(currency) {
        Ember.set(this, 'bookingItemDetail.valueCurrency', currency.value);
      }
    }
  });

  _exports.default = _default;
});