define("chimaera/pods/components/cookie-info/component", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cookieService: Ember.inject.service('cookies'),
    classNames: ['cookie-message'],
    classNameBindings: ['show'],
    show: false,
    autoAcceptCookie: false,
    wp: Ember.inject.service('welcome-pack'),
    didInsertElement: function didInsertElement() {
      this.set('show', Ember.isBlank(this.cookieService.read(_environment.default.APP.COOKIE_ACCEPT)));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isBlank(this.cookieService.read(_environment.default.APP.COOKIE_ACCEPT)) && this.autoAcceptCookie) {
        this.send('acceptCookie');
        Ember.set(this, 'show', false);
      }
    },
    actions: {
      acceptCookie: function acceptCookie() {
        var expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        this.cookieService.write(_environment.default.APP.COOKIE_ACCEPT, 'accept', {
          expires: expires,
          path: '/',
          domain: _environment.default.APP.DOMAIN,
          sameSite: 'Strict'
        });
        this.set('show', Ember.isBlank(this.cookieService.read(_environment.default.APP.COOKIE_ACCEPT)));
      }
    }
  });

  _exports.default = _default;
});