define("chimaera/components/core/tag", ["exports", "@cetus/kontainers-ui-core/components/core/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _tag.default;
    }
  });
});