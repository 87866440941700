define("chimaera/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    matched: _emberData.default.attr('string'),
    matchedCountry: _emberData.default.attr('string'),
    mode: _emberData.default.attr('string'),
    iata: _emberData.default.attr('string'),
    unCode: _emberData.default.attr('string'),
    equipmentAvailability: _emberData.default.attr('')
  });

  _exports.default = _default;
});