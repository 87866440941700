define("chimaera/pods/components/booking/booking-references/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kMzl4Gxa",
    "block": "{\"symbols\":[\"form\",\"reference\",\"index\",\"@booking\"],\"statements\":[[8,\"core/form\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"seven columns\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"info-note\"],[12],[1,[30,[36,0],[\"booking.reference.addMore\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"five columns\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,\"data-test-action\",\"add-another-reference\"],[16,0,[31,[\"bc_button btn_secondary btn_add action_addOtherRef \",[30,[36,1],[[32,0,[\"isDisabledAddMore\"]],\"disabled\"],null]]]],[4,[38,2],[[32,0],\"addReference\"],null],[12],[1,[30,[36,0],[\"booking.reference.addAnother\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"validation-alert\"]],[],[[\"@model\",\"@property\"],[[32,4],\"references\"]],null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4,[\"references\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"booking/booking-reference-item\",[],[[\"@allBookingReferences\",\"@bookingReference\",\"@selectableReferenceTypes\",\"@allReferenceTypes\",\"@index\"],[[32,4,[\"references\"]],[32,2],[32,0,[\"selectableReferenceTypes\"]],[32,0,[\"allReferenceTypes\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/booking/booking-references/template.hbs"
    }
  });

  _exports.default = _default;
});