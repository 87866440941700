define("chimaera/mirage/config", ["exports", "chimaera/mirage/endpoints/config", "chimaera/mirage/endpoints/booking", "chimaera/mirage/endpoints/logger", "chimaera/mirage/endpoints/tenancy", "chimaera/mirage/endpoints/tenancy-welcome-pack", "chimaera/mirage/endpoints/translation", "chimaera/mirage/endpoints/trip", "chimaera/config/environment"], function (_exports, _config, _booking, _logger, _tenancy, _tenancyWelcomePack, _translation, _trip, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var _Config$APP = _environment.default.APP,
      MIRAGE_PASSTHROUGH_ALL = _Config$APP.MIRAGE_PASSTHROUGH_ALL,
      MIRAGE_INTERCEPT = _Config$APP.MIRAGE_INTERCEPT;

  function interceptRoutes(server, routes) {
    if (Ember.isBlank(routes)) {
      (0, _booking.default)(server);
      (0, _config.default)(server);
      (0, _logger.default)(server);
      (0, _tenancy.default)(server);
      (0, _translation.default)(server);
      (0, _trip.default)(server);
      return;
    }

    switch (routes) {
      case 'tenancyWelcomePack':
        (0, _tenancyWelcomePack.default)(server);
        break;

      case 'booking':
        (0, _booking.default)(server);
        break;

      case 'config':
        (0, _config.default)(server);
        break;

      case 'logger':
        (0, _logger.default)(server);
        break;

      case 'tenancy':
        (0, _tenancy.default)(server);
        break;

      case 'translation':
        (0, _translation.default)(server);
        break;

      case 'trip':
        (0, _trip.default)(server);
        break;

      default:
        throw 'routes not found';
    }
  }
  /**
   * Tenant names (more Info: https://kontainers.atlassian.net/wiki/spaces/EKB/pages/1374683137/Switch+tenants+locally+with+Mirage)
   *
   * Developer Staging
   * NO RATES Developer Staging
   * Zim Staging (Referer: https://zimstaging.kontainers.io)
   * Ceva Staging (Referer: https://ceva.kclient-staging.com)
   * Ceva Automation: (Referer: https://ceva-auto.kclient-staging.com)
   * Hirthe, Littel and Hartmann and Sons (Referer: https://qa-staging.kclient-staging.com)
   * QA Staging (AUTO) (Referer: https://qa-staging-auto.kclient-staging.com)
   * MetroSSO (Referer: https://metro-sso.kclient-staging.com)
   * ZimSpot Staging (Referer: https://zimspot.kclient-staging.com)
   * oneline staging (Referer: https://oneline.kclient-staging.com/)
   * Dachser Staging (Referer: https://dachser.kclient-staging.com/)
   */


  function _default() {
    var _this = this;

    // Allows us to check the requests being made in tests
    this.handledRequests = [];

    this.pretender.handledRequest = function (verb, path, request) {
      _this.handledRequests.push({
        verb: verb,
        path: path,
        request: request
      });
    }; // Config


    this.urlPrefix = 'https://cetusapi-dev.kontainers.io'; // make this `http://localhost:8080`, for example, if your API is on a different server

    this.timing = 400; // delay for each request, automatically set to 0 during testing
    // Switch tenants, specify tenant name
    // Note, don't rely on welcome-pack fixture being up-to-date

    this.tenantName = 'Dachser Staging'; // see fixtures/welcome-packs

    if (MIRAGE_PASSTHROUGH_ALL) {
      var intercepts = MIRAGE_INTERCEPT.split(',');

      if (intercepts.length) {
        intercepts.forEach(function (routes) {
          interceptRoutes(_this, routes.trim());
        });
      } // Passthrough remaining routes


      this.passthrough();
    } else {
      // Mirage mocks
      interceptRoutes(this); // Don't intercept with Mirage

      this.passthrough('https://api-iam.intercom.io/messenger/web/ping');
    }
  }
});