define("chimaera/validators/number-by-unit", ["exports", "@cetus/kontainers-ui-core/utils/convert-units"], function (_exports, _convertUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNumberByUnit;

  function validateNumberByUnit(_ref) {
    var lt = _ref.lt,
        unit = _ref.unit,
        unitProperty = _ref.unitProperty;
    return function (key, newValue, oldValue, changes, content) {
      var threshold = lt;
      var toUnit = changes[unitProperty] || content[unitProperty];

      if (unit !== toUnit) {
        threshold = (0, _convertUnits.convertAs)({
          from: unit,
          to: toUnit,
          val: lt
        });
      }

      if (lt && parseFloat(newValue) > threshold) {
        if (!content.validationMessages || !content.validationMessages.validateNumberByUnit) {
          // eslint-disable-next-line no-console
          return console.error('No validationMessages method defined on the changeset');
        }

        return {
          localeKey: content.validationMessages.validateNumberByUnit(toUnit)
        };
      }

      return true;
    };
  }
});