define("chimaera/models/schedule", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ui-shared/utils/serializer-quote-query-params", "chimaera/services/welcome-pack"], function (_exports, _model, _attr, _relationships, _serializerQuoteQueryParams, _welcomePack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var schema = {
    // Attributes
    quoteId: (0, _attr.default)('string'),
    loadType: (0, _attr.default)('string'),
    overallTransitTime: (0, _attr.default)('number'),
    totalCost: (0, _attr.default)('stringToFloat'),
    rateType: (0, _attr.default)('string'),
    // contract||spot||contract and spot
    // Associations
    legs: (0, _relationships.hasMany)(),
    // Computed Properties
    isMultiLeg: Ember.computed.gt('legs.length', 1),
    etd: Ember.computed('legs.[]', {
      get: function get() {
        var legs = this.legs;
        var firstLeg = legs.firstObject;
        var latestPortArrivalTime = firstLeg.latestPortArrivalTime,
            etd = firstLeg.etd;

        if (this.loadType === 'lcl') {
          return latestPortArrivalTime || etd;
        }

        return etd;
      }
    }),
    transitTime: Ember.computed.alias('overallTransitTime'),
    eta: Ember.computed('legs.[]', {
      get: function get() {
        if (this.loadType === 'lcl') {
          var legs = this.legs;
          var cfsLeg = legs.find(function (leg) {
            return leg.mode === 'cfs';
          });

          if (cfsLeg) {
            return cfsLeg.eta;
          }
        }

        return this.legs.lastObject.eta;
      }
    }),
    via: Ember.computed('legs.[].children.[]', 'isMultileg', {
      get: function get() {
        if (!this.isMultileg) {
          return;
        }

        var mainLeg = this.legs.find(function (leg) {
          return (0, _welcomePack.isMainMode)(leg.mode);
        });
        var childSeaModes = this.legs.reduce(function (a, leg) {
          if (leg.children.length) {
            var filteredChildren = leg.children.filter(function (child) {
              return child.mode === 'sea';
            });
            return filteredChildren.length ? [].concat(_toConsumableArray(a), [filteredChildren]) : a;
          }

          return a;
        }, []); // If child sea, then get the last sea of the last leg

        if (childSeaModes.length) {
          var lastLeg = childSeaModes.lastObject;

          if (lastLeg.length) {
            return lastLeg.lastObject.to;
          }
        }

        return mainLeg ? mainLeg.from : '';
      }
    }),
    mainLeg: Ember.computed('legs.@each.mode', function () {
      return this.legs.find(function (leg) {
        return (0, _welcomePack.isMainMode)(leg.mode);
      });
    }),
    supplierName: Ember.computed('legs.@each.mode', function () {
      return (0, _serializerQuoteQueryParams.getMainModeSupplierName)(this.legs);
    })
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});