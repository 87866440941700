define("chimaera/pods/components/dashboard/copy-booking-modal/section/addresses/component", ["exports", "chimaera/pods/components/dashboard/copy-booking-modal/section/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // Computed Properties
    columnCount: Ember.computed('booking.{consignorBookingAddress,consigneeBookingAddress,notifyBookingAddress,brokerageAddress}', {
      get: function get() {
        var _this$booking = this.booking,
            consignorBookingAddress = _this$booking.consignorBookingAddress,
            consigneeBookingAddress = _this$booking.consigneeBookingAddress,
            notifyBookingAddress = _this$booking.notifyBookingAddress,
            brokerageAddress = _this$booking.brokerageAddress;
        var colNames = {
          0: 'twelve',
          1: 'twelve',
          2: 'six',
          3: 'four',
          4: 'three'
        };
        var cols = 0;
        if (consignorBookingAddress.content) cols++;
        if (consigneeBookingAddress.content) cols++;
        if (notifyBookingAddress.content) cols++;
        if (brokerageAddress.content) cols++;
        return colNames[cols];
      }
    }),
    availableAddresses: Ember.computed('booking.{consignorBookingAddress.willCopy,consigneeBookingAddress.willCopy,notifyBookingAddress.willCopy,brokerageAddress.willCopy}', {
      get: function get() {
        var booking = this.booking;
        var consignorBookingAddress = booking.consignorBookingAddress,
            consigneeBookingAddress = booking.consigneeBookingAddress,
            notifyBookingAddress = booking.notifyBookingAddress,
            brokerageAddress = booking.brokerageAddress;
        var all = [];
        if (consignorBookingAddress.content) all.pushObject(consignorBookingAddress);
        if (consigneeBookingAddress.content) all.pushObject(consigneeBookingAddress);
        if (notifyBookingAddress.content) all.pushObject(notifyBookingAddress);
        if (brokerageAddress.content) all.pushObject(brokerageAddress);
        return all;
      }
    }),
    selectedAll: Ember.computed('availableAddresses.@each.willCopy', {
      get: function get() {
        return this.availableAddresses.every(function (addr) {
          return addr.get('willCopy');
        });
      }
    }),
    selectedSome: Ember.computed('availableAddresses.@each.willCopy', {
      get: function get() {
        return this.availableAddresses.toArray().some(function (addr) {
          return addr.get('willCopy');
        });
      }
    }),
    // Actions
    actions: {
      toggleSelectAll: function toggleSelectAll(override) {
        var selectedAll = this.selectedAll,
            availableAddresses = this.availableAddresses;
        var shouldSelect = !Ember.isBlank(override) ? override : !selectedAll;

        if (shouldSelect) {
          availableAddresses.forEach(function (addr) {
            return Ember.set(addr, 'willCopy', true);
          });
        } else {
          availableAddresses.forEach(function (addr) {
            return Ember.set(addr, 'willCopy', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});