define("chimaera/pods/components/file-uploader/listing/file/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    intl: Ember.inject.service(),
    welcomePack: Ember.inject.service(),
    // State
    tagName: 'tr',
    // Lifecycle Hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var description = this.file.description;

      if (this.fileTypes.some(function (fileType) {
        return fileType.value === description;
      })) {
        Ember.set(this, 'selectedVal', description);
      } else if (Ember.isBlank(description)) {
        Ember.set(this, 'selectedVal', '');
      } else {
        Ember.set(this, 'selectedVal', 'other');
      }
    },
    // Computed Properties
    showDropdown: Ember.computed('selectedVal', {
      get: function get() {
        return this.selectedVal !== 'other';
      }
    }),
    // Actions
    actions: {
      confirmRemove: function confirmRemove(file) {
        Ember.set(this, 'showConfirmDeleteModal', false);
        this.doRemove(file);
      },
      resetDesc: function resetDesc() {
        Ember.setProperties(this, {
          selectedVal: '',
          'file.description': ''
        });
      },
      customDescriptionChanged: function customDescriptionChanged() {
        if (Ember.isBlank(this.file.errors)) {
          return;
        }

        this.file.errors.popObject();
      },
      selectedDescriptionChanged: function selectedDescriptionChanged(selected) {
        var value = selected.value;
        Ember.set(this, 'selectedVal', value);

        if (value === 'other') {
          Ember.set(this, 'file.description', '');
        } else {
          Ember.set(this, 'file.description', value);
        }
      }
    }
  });

  _exports.default = _default;
});