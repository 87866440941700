define("chimaera/mirage/factories/schedule", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    totalCost: function totalCost() {
      var totalCost = _faker.default.random.number({
        min: 1000,
        max: 4000
      });

      return "".concat(totalCost);
    },
    overallTransitTime: function overallTransitTime() {
      return _faker.default.random.number({
        min: 60,
        max: 120
      });
    },
    type: null,
    withMultiLegRates: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        var legs = [server.create('leg', {
          mode: 'road'
        }, 'fromDelhi', 'toAhmedabad', 'withRates', 'withIssues'), server.create('leg', {
          mode: 'cfs'
        }, 'fromAhmedabad', 'toSyracuse', 'withRates', 'withIssues'), server.create('leg', {
          mode: 'road'
        }, 'fromSyracuse', 'toBuffalo', 'withRates', 'withIssues')];
        schedule.update({
          legs: legs
        });
        schedule.save();
      }
    }),
    withMultiLegRatesAndChildren: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        var legs = [server.create('leg', {
          mode: 'road'
        }, 'fromDelhi', 'toAhmedabad', 'withRates', 'withChildren', 'withIssues'), server.create('leg', {
          mode: 'cfs'
        }, 'fromAhmedabad', 'toSyracuse', 'withRates', 'withChildren', 'withIssues'), server.create('leg', {
          mode: 'road'
        }, 'fromSyracuse', 'toBuffalo', 'withRates', 'withChildren', 'withIssues')];
        var totalCost = legs.map(function (leg) {
          return parseFloat(leg.total);
        }).reduce(function (a, b) {
          return a + b;
        }, 0);
        schedule.update({
          totalCost: totalCost,
          legs: legs
        });
        schedule.save();
      }
    }),
    withMultiLegNoRates: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        var legs = [server.create('leg', {
          mode: 'road'
        }, 'fromDelhi', 'toAhmedabad', 'withIssues'), server.create('leg', {
          mode: 'sea'
        }, 'fromAhmedabad', 'toSyracuse', 'withIssues'), server.create('leg', {
          mode: 'road'
        }, 'fromSyracuse', 'toBuffalo', 'withIssues')];
        schedule.update({
          legs: legs
        });
        schedule.save();
      }
    }),
    withMultiLegNoRatesZim: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        var legs = [server.create('leg', {
          mode: 'sea',
          supplierName: 'Zim'
        }, 'fromPipavav', 'toVancouver', 'vesselOoclHamburg', 'withIssues'), server.create('leg', {
          mode: 'road',
          supplierName: 'Zim'
        }, 'fromVancouver', 'toNewAlbany', 'withIssues')];
        schedule.update({
          legs: legs
        });
        schedule.save();
      }
    }),
    withSingleLegs: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(schedule, server) {
        var leg = server.create('leg', 'fromPipavav', 'toNewYork', 'vesselAplColumbus', 'withRates', 'withIssues');
        schedule.update({
          totalCost: leg.total,
          legs: [leg]
        });
        schedule.save();
      }
    })
  });

  _exports.default = _default;
});