define("chimaera/pods/components/booking/commodity-detail/component", ["exports", "chimaera/mixins/booking/si-check", "chimaera/mixins/commodity-lookup-modal-help"], function (_exports, _siCheck, _commodityLookupModalHelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_commodityLookupModalHelp.default, _siCheck.default, {
    // Services
    store: Ember.inject.service(),
    welcomePack: Ember.inject.service(),
    currencyService: Ember.inject.service('currency'),
    statusService: Ember.inject.service('status'),
    // Computed Properties
    weightUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.weightUnits'),
    volumeUnitOptions: Ember.computed.reads('welcomePack.measurementUnits.volumeUnits'),
    currencyOptions: Ember.computed.alias('currencyService.currencyOptions'),
    commodityDisplayIndex: Ember.computed('commodityDetailIndex', {
      get: function get() {
        return this.commodityDetailIndex + 1;
      }
    }),
    bookingState: Ember.computed('bookingStatus', {
      get: function get() {
        return this.statusService.getCategoryByTheirStatus(this.bookingStatus);
      }
    }),
    fieldsToHide: Ember.computed('welcomePack.{bookingFieldsToHideBi,bookingFieldsToHideSi}', 'bookingState', {
      get: function get() {
        var _this$welcomePack = this.welcomePack,
            bookingFieldsToHideBi = _this$welcomePack.bookingFieldsToHideBi,
            bookingFieldsToHideSi = _this$welcomePack.bookingFieldsToHideSi;
        var bookingStates = {
          BI: bookingFieldsToHideBi,
          SI: bookingFieldsToHideSi
        };
        return bookingStates[this.bookingState];
      }
    }),

    /**
      * This handles a specific requirement for a tenant i.e. only show Commodity Code for BI
      * UI Flow work should replace this
      * potential to use welcomePack.{bookingFieldsToHideBi,bookingFieldsToHideSi}
      * so using this config to determine layout
     */
    showCommodityCodeOnly: Ember.computed('bookingState', 'fieldsToHide', {
      get: function get() {
        var _this = this;

        var fields = ['marks', 'typeOfPackaging', 'totalValue', 'numberOfPackages', 'volume', 'weight'];
        var showCommodityCodeOnly = !fields.filter(function (field) {
          return !_this.fieldsToHide.includes(field);
        }).length;
        return this.bookingState === 'BI' && showCommodityCodeOnly;
      }
    }),
    // Lifecycle Hooks
    actions: {
      onCurrencyChange: function onCurrencyChange(currency) {
        Ember.set(this, 'commodityDetail.valueCurrency', currency.value);
      },
      removeCommodity: function removeCommodity(commodity) {
        this.onRemove(commodity);
      }
    }
  });

  _exports.default = _default;
});