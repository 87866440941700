define("chimaera/pods/components/booking/freight-collection-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RKXx+/vf",
    "block": "{\"symbols\":[\"locale\",\"form\",\"row\",\"@booking\"],\"statements\":[[6,[37,2],[\"booking.freightCollectionType.\"],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"core/form\",[[24,0,\"row booking-payment\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"core/row\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"column\"]],[[24,0,\"two\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"h3\"],[12],[1,[30,[36,1],[[30,[36,0],[[32,1],\"heading.freightTerms\"],null]],null]],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,3,[\"column\"]],[[24,0,\"ten\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"radio\"]],[],[[\"@groupProperty\",\"@property\"],[[32,4,[\"freightCollectionType\"]],\"Prepaid\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[[32,1],\"label.prepaid\"],null]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2,[\"radio\"]],[],[[\"@groupProperty\",\"@property\"],[[32,4,[\"freightCollectionType\"]],\"Collect\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[[32,1],\"label.collect\"],null]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2,[\"validation-alert\"]],[],[[\"@model\",\"@property\"],[[32,4],\"freightCollectionType\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"let\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/booking/freight-collection-type/template.hbs"
    }
  });

  _exports.default = _default;
});