define("chimaera/adapters/booking", ["exports", "chimaera/adapters/application", "chimaera/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.APP.BOOKING_URL,
    // https://github.com/emberjs/data/blob/v3.7.0/addon/adapters/rest.js#L740
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      if (snapshot.adapterOptions && !Ember.isBlank(snapshot.adapterOptions.submit)) {
        url += '?submit=' + snapshot.adapterOptions.submit;
      }

      return this.ajax(url, 'PUT', {
        data: data
      });
    }
  });

  _exports.default = _default;
});