define("chimaera/pods/components/notice-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UPYOMb68",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"k-top-banner-holder\"],[14,1,\"k-top-banner-holder\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"centered\"],[12],[1,[32,0,[\"noticeService\",\"notice\",\"noticeText\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[16,0,[31,[\"k-button icon icon_close \",[30,[36,0],[[32,0,[\"noticeService\",\"notice\",\"dismissable\"]],\"display-none\"],null]]]],[24,4,\"button\"],[4,[38,1],[[32,0],\"closeBar\"],null],[12],[1,[30,[36,2],[\"noticeBar.close\"],null]],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/notice-bar/template.hbs"
    }
  });

  _exports.default = _default;
});