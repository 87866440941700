define("chimaera/@cetus/kontainers-ui-core/tests/templates.template.lint-test", [], function () {
  "use strict";

  QUnit.module('TemplateLint');
  QUnit.test('addon/templates/components/core/action-button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/action-button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/check-button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/check-button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/dimensions-label.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/dimensions-label.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/flash-message.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/flash-message.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/changeset-validation-alert.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/changeset-validation-alert.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/checkbox.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/checkbox.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/input.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/input.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/label.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/label.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/model-validation-alert.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/model-validation-alert.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/radio.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/radio.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/select.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/select.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/tags.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/tags.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/tags/add-button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/tags/add-button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/tags/input.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/tags/input.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/tags/list.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/tags/list.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/tags/remove-button.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/tags/remove-button.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/textarea.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/textarea.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/form/validation-alert.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/form/validation-alert.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/integrations/chat/salesforce/template.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/integrations/chat/salesforce/template.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/modal.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/modal.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/modal/confirm.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/modal/confirm.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/notification-bar.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/notification-bar.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/panel-bg.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/panel-bg.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/row.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/row.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/row/column.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/row/column.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/core/tag.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/core/tag.hbs should pass TemplateLint.\n\n');
  });
  QUnit.test('addon/templates/components/textfield.hbs', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/templates/components/textfield.hbs should pass TemplateLint.\n\n');
  });
});