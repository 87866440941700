define("chimaera/mixins/booking/address-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    confirmedWithAddressDisplay: function confirmedWithAddressDisplay(booking) {
      if (Ember.isBlank(booking) || Ember.isBlank(booking.get('confirmedWithAddress'))) {
        return null;
      } else {
        var addr = booking.get('confirmedWithAddress');
        var name = this.concat(addr.get('firstName'), addr.get('lastName'), ' ');
        var nameWithEmail = this.concat(name, addr.get('email'), ', ');
        return this.concat(nameWithEmail, addr.get('phone'), ', ');
      }
    },
    concat: function concat(s1, s2, concatText) {
      if (Ember.isBlank(s1)) {
        return this.emptyToNull(s2);
      } else if (Ember.isBlank(s2)) {
        return this.emptyToNull(s1);
      } else {
        return [s1, s2].join(concatText);
      }
    },
    emptyToNull: function emptyToNull(input) {
      return Ember.isBlank(input) ? null : input;
    }
  });

  _exports.default = _default;
});