define("chimaera/mirage/endpoints/tenancy-welcome-pack", ["exports", "chimaera/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    // Get welcome pack
    server.get("".concat(_environment.default.APP.TENANCY_URL, "/welcomePacks"), function (schema) {
      var tenantName = server.tenantName;
      return schema.welcomePacks.findBy({
        tenantName: tenantName
      });
    });
  }
});