define("chimaera/models/booking-address", ["exports", "ember-data", "ember-data/model", "ember-data-copyable"], function (_exports, _emberData, _model, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    address3: _emberData.default.attr('string'),
    address4: _emberData.default.attr('string'),
    cityTown: _emberData.default.attr('string'),
    companyName: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    postCode: _emberData.default.attr('string'),
    vatNo: _emberData.default.attr('string'),
    customerCode: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    addressText: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    companyRole: _emberData.default.attr('string'),
    singleLine: Ember.computed('address1', 'address2', 'address3', 'address4', 'cityTown', 'companyName', 'region', 'country', 'postCode', 'vatNo', 'email', 'firstName', 'lastName', function () {
      var _this = this;

      var singleLineArr = [];
      var keys = ['firstName', 'lastName', 'address1', 'address2', 'address3', 'address4', 'cityTown', 'companyName', 'region', 'country', 'postCode', 'vatNo'];
      keys.forEach(function (k) {
        if (!Ember.isBlank(_this.get(k))) {
          singleLineArr.push("".concat(_this.get(k)));
        }
      });
      return singleLineArr.join(', ');
    }),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  };
  _exports.schema = schema;

  var _default = _model.default.extend(_emberDataCopyable.default, schema);

  _exports.default = _default;
});