define("chimaera/pods/components/dashboard/copy-booking-modal/section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // State
    tagName: '',
    // Lifecycle Hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.context.registerSection(this); // reset selected state

      this.send('toggleSelectAll', false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.context.deregisterSection(this);
    },
    // Actions
    actions: {
      toggleCopy: function toggleCopy(data, parentModel) {
        // default to top level booking, but can also be bookingItem for eg.
        var baseModel = parentModel ? parentModel : this.booking;
        /*
          Note:
          'data' here can be: string (attribute name) || object (model)
          - we either flag the model with a top level `willCopy` boolean
          - or else store the attribute name in `copyAttributes` on the baseModel (see above)
        */

        if (typeof data === 'string') {
          // init
          if (!baseModel.copyAttributes) {
            Ember.set(baseModel, 'copyAttributes', Ember.A());
          }

          if (baseModel.copyAttributes.includes(data)) {
            baseModel.copyAttributes.removeObject(data);
          } else {
            baseModel.copyAttributes.pushObject(data);
          }

          return;
        }

        Ember.set(data, 'willCopy', !Ember.get(data, 'willCopy'));
      }
    }
  });

  _exports.default = _default;
});