define("chimaera/utils/model-error", ["exports", "@cetus/kontainers-ui-core/utils/model-error"], function (_exports, _modelError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "attrNameFromPointer", {
    enumerable: true,
    get: function get() {
      return _modelError.attrNameFromPointer;
    }
  });
  Object.defineProperty(_exports, "addErrorToModel", {
    enumerable: true,
    get: function get() {
      return _modelError.addErrorToModel;
    }
  });
  Object.defineProperty(_exports, "addAdapterErrorsToModel", {
    enumerable: true,
    get: function get() {
      return _modelError.addAdapterErrorsToModel;
    }
  });
});