define("chimaera/mirage/utils/dates", ["exports", "faker", "luxon"], function (_exports, _faker, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateBetween = void 0;

  var dateBetween = function dateBetween(from, to) {
    return _luxon.DateTime.fromISO(_faker.default.date.between(from, to)).toFormat('yyyy-LL-dd');
  };

  _exports.dateBetween = dateBetween;
});