define("chimaera/pods/components/file-uploader/listing/component", ["exports", "chimaera/utils/file-size"], function (_exports, _fileSize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    // Services
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    welcomePack: Ember.inject.service(),
    userService: Ember.inject.service('user'),
    modelFiles: null,
    // Computed Properties
    savedFiles: Ember.computed('modelFiles.@each.{visible,isDeleted}', 'queueName', {
      get: function get() {
        return this.modelFiles && this.modelFiles.filter(function (f) {
          return f.visible && !f.isDeleted;
        });
      }
    }),
    queuedFiles: Ember.computed('uploadQueue.files.@each.{state,name}', 'queueName', {
      get: function get() {
        var _this = this;

        return this.uploadQueue.files && this.uploadQueue.files.filter(function (f) {
          /*
            Note:
            Files which are auto removed onFileAdd need to be hidden here
            See dropzone onFileAdd
          */
          var isUploaded = f.state === 'uploaded';
          var isTooLarge = f.size > (0, _fileSize.mbToBytes)(_this.maxFileSizeMb);
          var isDuplicatedInQueue = f.queue.files.filterBy('name', f.name).length > 1;

          var isDuplicatedOnModel = _this.modelFiles && _this.modelFiles.mapBy('displayFileName').includes(f.name);

          return !isUploaded && !isTooLarge && !isDuplicatedInQueue && !isDuplicatedOnModel;
        });
      }
    }),
    fileTypes: Ember.computed('welcomePack.bookingDocumentDropdown', {
      get: function get() {
        /*
          This is specific to booking for now, might need to become dynamic
        */
        var bookingDocumentDropdown = this.welcomePack.bookingDocumentDropdown;
        var emptyOption = {
          value: '',
          display: this.intl.t("".concat(this.locale, "file.label.selectFileType"))
        };
        var otherOption = {
          value: 'other',
          display: this.intl.t("".concat(this.locale, "file.label.other"))
        };
        return [emptyOption].concat(_toConsumableArray(bookingDocumentDropdown), [otherOption]);
      }
    }),
    // Actions
    actions: {
      removeFileFromQueue: function removeFileFromQueue(file) {
        this.uploadQueue.remove(file);
      },
      removeFileFromModel: function removeFileFromModel(file) {
        this.doRemoveFile(file);
      }
    }
  });

  _exports.default = _default;
});