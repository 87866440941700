define("chimaera/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RZopTzSg",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[8,\"custom-link-tags\",[],[[\"@urls\"],[[32,0,[\"customLinkTagUrls\"]]]],null],[2,\"\\n\\n\"],[10,\"style\"],[12],[2,\"\\n  :root {\\n    --primary-color: \"],[1,[32,0,[\"primaryColor\"]]],[2,\";\\n    --secondary-color: \"],[1,[32,0,[\"secondaryColor\"]]],[2,\";\\n  }\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,1,\"datetime\"],[12],[13],[2,\"\\n\\n\"],[8,\"notice-bar\",[],[[],[]],null],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"flashMessages\",\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"core/flash-message\",[],[[\"@flash\",\"@close\"],[[32,1],[32,0,[\"close\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"nav-bar\",[],[[],[]],null],[2,\"\\n\"],[8,\"refresh-token\",[],[[],[]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\\n\"],[8,\"modal/note-lookup\",[],[[\"@open\"],[[32,0,[\"noteModalOpen\"]]]],null],[2,\"\\n\"],[8,\"modal/commodity-lookup\",[],[[],[]],null],[2,\"\\n\"],[8,\"modal/draft-booking\",[],[[],[]],null],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"bclever-modal-bg \",[30,[36,4],[[32,0,[\"isBcleverModalOpen\"]],\"bclever-modal-open\"],null]]]],[12],[13],[2,\"\\n\\n\"],[8,\"core/integrations/chat/intercom\",[],[[\"@isAuthenticated\"],[[32,0,[\"session\",\"isAuthenticated\"]]]],null],[2,\"\\n\"],[8,\"core/integrations/chat/salesforce\",[],[[],[]],null],[2,\"\\n\"],[8,\"core/integrations/chat/live-chat\",[],[[\"@isAuthenticated\"],[[32,0,[\"session\",\"isAuthenticated\"]]]],null],[2,\"\\n\\n\"],[8,\"cookie-info\",[],[[\"@autoAcceptCookie\"],[[32,0,[\"cookie_accepted\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"ember-basic-dropdown-wormhole\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"-outlet\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});