define("chimaera/mirage/fixtures/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // prettier-ignore

  /* eslint-disable quotes, quote-props */
  // should be JSON formatted
  var _default = [{
    "locale": "en-us",
    "data": {
      "tel": {
        "num": "+1844-459-8927",
        "className": "US-version"
      },
      "acceptTandC": "I have read and accept the",
      "lcl": "displayNone",
      "poweredBy": {
        "label": {
          "poweredBy": "Powered by Kontainers"
        }
      },
      "dashboard": {
        "title": "Dashboard",
        "newBookingSuccess": "Thank you. Your order has been placed.",
        "editBookingSuccess": "Thank you. Your order has been updated.",
        "bookmarkDeleteSuccess": "Bookmark deleted successfully.",
        "bookmarkDeleteFailure": "Problem Deleting bookmark. Please try again later.",
        "bookmarkNameChangeSuccess": "Bookmark name changed successfully.",
        "bookmarkNameChangeFailure": "Problem Saving Bookmark. Please try again later.",
        "bookingNotFound": "Sorry, we could not find an order with that specific reference",
        "tabs": {
          "open": "Open Orders",
          "complete": "Completed Orders",
          "cancel": "Cancelled Orders",
          "quote": "Quotes",
          "sharedQuotes": "Shared Quotes",
          "bookmark": "Bookmarks",
          "empty": {
            "titleOpen": "You have no open orders",
            "titleComplete": "You have no completed orders",
            "titleCancel": "You have no cancelled orders",
            "titleQuote": "You have no Quotes",
            "titleBm": "You have no Bookmarks",
            "one": "Why not get a",
            "two": "New Booking",
            "three": "now?",
            "four": "If you have a shipment why not chat to our",
            "five": "Customer service.",
            "six": "To bookmark quotes, get a New Booking and click the Bookmark button.",
            "titleWithFilter": "No orders match your filters",
            "textWithFilter": "You can clear filters to view all orders."
          },
          "quoteTable": {
            "from": "From",
            "to": "To",
            "row": {
              "closeBreakdown": "Close Breakdown",
              "action": "Book",
              "accept": "Accept"
            }
          },
          "bookmarkTable": {
            "name": "Name",
            "type": "Type",
            "shipping": "Shipping",
            "from": "From",
            "to": "To",
            "row": {
              "quote": "Quotes",
              "del": "Delete Bookmark",
              "save": "Save"
            },
            "delete": {
              "label": "This will be permanently deleted",
              "cancel": "Cancel",
              "delete": "Delete"
            }
          },
          "sharedQuotesTable": {
            "retrieveQuotes": {
              "heading": {
                "retrieveQuotes": "Retrieve quotes"
              },
              "label": {
                "quoteReference": "Quote reference",
                "retrieve": "Retrieve",
                "continueManually": "Continue manually"
              },
              "placeholder": {
                "referenceQuote": "Enter your quote reference"
              }
            },
            "noResults": {
              "heading": {
                "noQuotes": "You have no shared quotes"
              },
              "label": {
                "shareNotePre": "You can share quotes by clicking",
                "newBooking": "New Booking",
                "shareNotePost": "and share from the quote results page"
              }
            },
            "quoteLabels": {
              "heading": {
                "sharedWith": "Shared with",
                "date": "Date",
                "expires": "Expires",
                "to": "To",
                "from": "From"
              }
            },
            "quoteModule": {
              "label": {
                "ref": "REF",
                "resend": "Resend",
                "deleteBookmark": "Delete Bookmark"
              },
              "notification": {
                "deleteSuccess": "Your quote has been deleted",
                "deleteError": "There is a problem deleting your quote",
                "reshareSuccess": "Your quote has been resent",
                "reshareError": "There is a problem resending your quote"
              }
            }
          },
          "openOrderTable": {
            "bol": "BOL",
            "preview": "Preview",
            "copy": "Copy<br>Booking",
            "edit": "Edit<br/>Booking",
            "editSI": "Edit<br/>SI",
            "failed": "FAILED",
            "cancelled": "CANCELLED",
            "estDepartureDate": "EST Departure Date",
            "estArrivalDate": "EST Arrival Date",
            "arrivalDate": "Arrival Date",
            "files": "Files",
            "docs": "Docs",
            "editOrder": "Edit Order",
            "from": "From",
            "to": "To",
            "vessel": "Vessel",
            "orderSummary": "Order Summary",
            "customerDetails": "Customer Details",
            "consignor": "SHIPPER",
            "consignee": "CONSIGNEE",
            "notifyParty": "NOTIFY PARTY",
            "brokerage": "BROKERAGE",
            "shipping": "Basic Ocean Freight",
            "basicOceanFreight": "Shipping",
            "fareType": "Fare type",
            "vesselName": "Vessel Name",
            "voyageNumber": "Voyage Number",
            "estimateDeparture": "Estimate Departure",
            "estimatedArrival": "Estimated Arrival",
            "vgmCutOff": "VGM cut off date",
            "documentCutOff": "Document cut off date",
            "subtotals": "Subtotals",
            "trucking": "Trucking",
            "handling": "Terminal Handling Service – Origin",
            "documentsFee": "Documentation Fee – Origin",
            "seaLegClosingDate": "Sea Leg Closing Date",
            "cutOff": "Cut Off",
            "service": "Export Service",
            "customsFee": "Customs Fee",
            "total": "Total",
            "booking_ref": "Booking Ref",
            "operator": "Operated by",
            "supplier": "Supplier",
            "more": "MORE",
            "less": "LESS",
            "viewEdit": "View & Edit",
            "viewApprove": "View & Approve"
          }
        },
        "fileUpload": {
          "title": "Upload Files",
          "orderReference": "Order Reference",
          "dragDrop": "Just drag and drop files here",
          "fileName": "File Name",
          "description": "Description",
          "selectFileType": "Select a file type",
          "bankSlip": "Bank Slip",
          "other": "other",
          "save": "save",
          "done": "done",
          "error": "Must be an image or PDF, no larger than 5 MB",
          "placeholder": {
            "enterAFileType": "Enter a file type"
          },
          "validation": {
            "fileTypeIsMissing": "File type is missing"
          }
        },
        "bol": {
          "share": "share",
          "saveChanges": "save changes",
          "approveBol": "Approve SI",
          "billSaved": "Bill Successfully Saved.",
          "billNotSaved": "There is a problem saving.",
          "shareWithOthers": "Share with Others",
          "shareText": "You can share the link by copying it and pasting it into an email or instant messenger.",
          "shareableLink": "Shareable Link",
          "shareChoice": "...or let us send the link to an email address of your choice.",
          "emailAddress": "Email Address",
          "emailLink": "EMAIL LINK",
          "error": {
            "reference": "reference",
            "share": "Problem sharing this BOL. Please try again later."
          }
        },
        "bookingFilter": {
          "showFilters": "Show filters",
          "filters": "Filters",
          "clearFilter": "Clear filter",
          "orderStatus": "Order status",
          "selectStatus": "Select a status",
          "customerRef": "Customer Ref.",
          "bookingRef": "Booking Ref.",
          "customerRefLabel": "Customer reference",
          "bookingRefLabel": "Booking reference",
          "customerRefPlaceholder": "Any customer reference",
          "bookingRefPlaceholder": "Any booking reference"
        },
        "dashItem": {
          "label": {
            "fcl": "FCL",
            "lcl": "LCL",
            "air": "Air Export"
          }
        },
        "signpost": {
          "heading": {
            "whatDoYouWantToDo": "What do you want to do?",
            "retrieveQuotes": "Retrieve quotes"
          },
          "label": {
            "quoteReference": "Quote reference"
          },
          "button": {
            "closeThisDialog": "Close this dialog",
            "retrieveAQuote": "Retrieve a quote",
            "startANewQuote": "Start a new quote",
            "placeBooking": "Place a booking",
            "retrieve": "Retrieve",
            "viewMyDashboard": "View my dashboard"
          },
          "validation": {
            "referenceNotRecognized": "The Quote reference was not recognized"
          }
        },
        "copyBookingModal": {
          "heading": {
            "copyOrderDetails": "Copy Order Details",
            "fromOrder": "From order",
            "shipmentDetails": "Shipment Details",
            "customerDetails": "Customer Details",
            "consignor": "CONSIGNOR",
            "consignee": "CONSIGNEE",
            "notifiedParty": "NOTIFIED PARTY",
            "brokerage": "BROKERAGE",
            "filesAndDocuments": "Files &amp; Documents",
            "from": "FROM",
            "to": "TO",
            "collection": "Collection",
            "delivery": "Delivery",
            "shippingDetailsRequired": "Shipping details required"
          },
          "label": {
            "copy": "Copy",
            "copyAll": "Copy all",
            "selectAll": "Select all",
            "selectNone": "Select none",
            "cancelAndDiscard": "Cancel &amp; Discard",
            "createBooking": "Create booking",
            "yes": "Yes",
            "collectionDate": "Collection Date",
            "collectionTime": "Collection Time",
            "deliveryToDestination": "Delivery to destination",
            "fromToRequired": "The FROM and TO above must be selected before copying cargo/load details."
          },
          "validation": {
            "setupFailed": "Sorry, we couldn't load the booking."
          },
          "lcl": {
            "heading": {
              "lclShipment": "LCL Shipment",
              "cargo": "Cargo",
              "cargoLoad": "Commodity {index}",
              "lclLoads": "LCL Loads"
            },
            "label": {
              "cargoDetails": "Cargo Details",
              "commodityCode": "Commodity Code",
              "loadDetails": "Load Details",
              "dimensions": "Dimensions"
            }
          },
          "fcl": {
            "heading": {
              "containers": "Containers",
              "container": "Container",
              "commodity": "Commodity {index}"
            },
            "label": {
              "containerType": "Container Type",
              "containerContents": "Container Contents",
              "commodityCode": "Commodity Code",
              "grossWeight": "Gross Weight"
            }
          }
        }
      },
      "addressFields": {
        "department": "Department",
        "company": "Company",
        "firstName": "First Name",
        "lastName": "Last Name",
        "email": "Email",
        "addy1": "Address Line 1",
        "addy2": "Address Line 2",
        "country": "Country / Region",
        "city": "City",
        "county": "County",
        "state": "State",
        "fax": "Fax",
        "tel": "Telephone",
        "vat": "Tax ID / VAT Number",
        "dan": "DAN Number",
        "danConfirm": "Used for customs clearance",
        "billing": "Billing Address",
        "sameAsCompanyAddress": "Billing address is the same as company address",
        "customerCode": "Customer Code"
      },
      "error": {
        "adapter": {
          "booking": "There is a problem saving your booking"
        },
        "heading": {
          "mainHeading": "Ooops!",
          "subHeading": "There seems to be a problem. Please try again"
        },
        "label": {
          "getQuotes": "Get Quotes"
        },
        "cannotInviteActiveUser": "Cannot issue invite for active user.",
        "confirmPasswordEmpty": "The confirm password field cannot be empty",
        "confirmPasswordWrong": "Your passwords do not match",
        "noQuotes": "Problem getting quotes",
        "unauthenticatedBookmark": "You need to be logged in to bookmark",
        "invalidSelection": "Invalid selection",
        "deletingFile": "There is a problem deleting your file.",
        "savingBooking": "There is a problem saving your booking, please make sure all of the required fields are entered.",
        "savingInvite": "There is a problem sending the invite.",
        "savingOrder": "There is a problem saving your changes. Please refresh the page.",
        "reference": "Please specify a reference",
        "address": "Please select a to address.",
        "notifyParty": "Please select a Notify Party.",
        "containerContent": "Please specify Container's Content",
        "containerWeight": "Please specify Content's Weight",
        "termCondition": "Please accept terms and conditions.",
        "blank": "This cannot be blank.",
        "fieldBlank": "This field cannot be blank",
        "notValidCountry": "We cannot find a country with this name. Please double-check the spelling.",
        "fieldFormat": "This field must be alphanumeric or .-_@",
        "asciiFormat": "This field must be alphanumeric or simple punctuation",
        "dateBlank": "You must enter a date",
        "forgotPassword1": "You Need to enter your email.",
        "forgotPassword2": "If your email is registered, you will receive an email.",
        "numberOnly": "Please only enter numbers",
        "positiveNumberOnly": "Please only enter positive numbers",
        "emailNotAvailable": "This email address is not available. Choose a different address.",
        "usernameNotAvailable": "This username is not available. Choose a different one.",
        "registrationFailed": "Registration Failed. Email Address may already be in use. If you think you might already have an account, try the Forgot Password option to reset your password. Otherwise, please contact Support.",
        "email": "Email must be a valid email address",
        "invalidNegativeNumber": "Number must be greater than 0",
        "lcl": {
          "containerContent": "Please specify cargo details"
        },
        "changeset": {
          "username": "Username must be alphanumeric or .-_@",
          "firstName": "First Name is required",
          "lastName": "Last Name is required",
          "email": "Email must be a valid email address",
          "nameBlank": "Name must be alphanumeric or '.-_@",
          "passwordComplexity": "Password must contain characters from 3 of the following categories: uppercase, lowercase, numeric, special characters",
          "passwordNoEmail": "Password may not contain your email",
          "passwordNoFirstName": "Password may not contain your first name",
          "passwordNoLastName": "Password may not contain your last name",
          "passwordNotNew": "New password must not match existing password.",
          "passwordBlank": "Password can't be blank",
          "passwordTooShort": "Password must contain at least 8 characters",
          "passwordsCannotMatch": "Passwords cannot be the same",
          "passwordsMustMatch": "Please make sure password matches."
        },
        "updateAccountFailed": "Failed to update account",
        "emptyCommodity": "Please enter commodity"
      },
      "cookie": {
        "text1": "cookie policy",
        "text2": "to see how we use cookies to give you the best possible online experience.",
        "text": "View our <a href='{url}' target='_blank' title='opens in a new tab/window'>cookie policy</a> to see how we use cookies to give you the best possible online experience.",
        "accept": "Accept Cookies"
      },
      "team": {
        "class": "displayNone",
        "email": "help@kontainers.com"
      },
      "login": {
        "here": "login here.",
        "registerSuccess": "Thanks for registering. Please login below",
        "my_account": "My Account",
        "explain": "Enter your email and password for instant access.",
        "email": {
          "field": "Username or Email Address",
          "placeholder": "Username or Email Address"
        },
        "password": {
          "field": "Password",
          "placeholder": "Password"
        },
        "actions": {
          "create": "Create Account",
          "sign_in": "Sign in",
          "forget": "Forgot Password?",
          "oauth2": "Sign in with SSO"
        },
        "error": {
          "emailPassword": "Email and/or password incorrect",
          "noKyc": "This account is awaiting KYC approval. Please try again later.",
          "oauth2": "Sign in failed. Please try again.",
          "captcha": "Please verify that you're not a robot"
        }
      },
      "selector": "current-USA",
      "abbreviation": {
        "notApplicable": "n/a"
      },
      "confirmation": {
        "costSummary": {
          "label": {
            "checkoutCurrency": "Checkout Currency",
            "localCurrency": "Local Currency"
          }
        }
      },
      "dimensionsLabel": {
        "label": {
          "times": "x",
          "width": "W",
          "height": "H",
          "length": "L"
        }
      },
      "incoterm": {
        "exw": {
          "desc": "Ex-works. Cargo is available at seller's warehouse. The buyer is responsible of the risks and charges from the cargo loading at this place."
        },
        "fca": {
          "desc": "Free carrier. Cargo is cleared and loaded at seller's or trucker's facilities at origin."
        },
        "fas": {
          "desc": "Free alongside ship. Cargo is released from the seller to the buyer alongside the ship at port of loading."
        },
        "fob": {
          "desc": "Free on board. Cargo is released from the seller to the buyer when it has been loaded onboard the vessel at port of loading."
        },
        "cfr": {
          "desc": "Cost and Freight. The seller is responsible of all charges until the port of destination."
        },
        "cif": {
          "desc": "Cost and Insurance Freight. The seller is responsible of all charges until the unloading at the port of destination and the insurance."
        },
        "cpt": {
          "desc": "Carriage Paid To. The seller pays all fees until the destination location."
        },
        "cip": {
          "desc": "Carriage and Insurance Paid To. Simialr to CPT. In that case, seller pays also the insurance for the transportation."
        },
        "dap": {
          "desc": "Delivery At Place. Cargo is delivered (but not unloaded) at destination location. The buyer is in charge of the customs clearance and unloading."
        },
        "ddp": {
          "desc": "Delivery Duty Paid. The seller is in charge of all fees until cargo delivery at destination location including the customs clearance but excluded the cargo unloading."
        },
        "dpu": {
          "desc": "Delivered At Place Unloaded. The seller is in charge of all fees until the delivery and the unloading of the cargo at the defined destination location."
        }
      },
      "application": {
        "modal": {
          "cancel": "Cancel",
          "confirm": "Are you sure?",
          "closeDialogue": "Close this dialogue",
          "delete": "Delete"
        }
      },
      "addressBar": {
        "header": "Contacts",
        "buttons": {
          "new": "New",
          "edit": "Edit"
        },
        "nores": {
          "one": "You have no contacts yet",
          "two": "Use the",
          "three": "New Contact button",
          "four": "above to add your first."
        },
        "delete": {
          "label": "Sure you want to delete this contact?",
          "yes": "Yes",
          "no": "No"
        },
        "notification": {
          "success": "Address updated",
          "error": "Problem updating address, please try again later"
        },
        "contactDetail": "Contact Details",
        "action": "Save Contact Details"
      },
      "register": {
        "header": "Create your account",
        "error": {
          "invalidInviteTitle": "This invite is no longer valid",
          "invalidInviteBody1": "This invite may have already been activated or there may be a system problem.",
          "invalidInviteBody2": "If you have already created your account you can",
          "invalidInviteBody3": "If you have not created your account yet please contact an adminstrator."
        },
        "account": {
          "accountDetail": "Account Details",
          "username": "Desired username",
          "firstName": "First Name",
          "lastName": "Last Name",
          "email": "Email",
          "password": "Password",
          "customerCode": "Customer Code",
          "role": "Role",
          "bco": "BCO",
          "freightForwarder": "Freight Forwarder",
          "tiers": "Tiers",
          "noTiersSet": "No tiers set"
        },
        "company": {
          "header": "Company Details"
        },
        "billing": {
          "header": "Billing Address"
        },
        "termsOfService": {
          "header": "Privacy and Cookie Policy",
          "note": "You must read and accept the privacy and cookie policy",
          "accept": "I have read and accept the privacy and cookie policy"
        },
        "action": "Register"
      },
      "noticeBar": {
        "close": "Close"
      },
      "tandcChecked": "Please accept our T&Cs to register.",
      "forgotPassword": {
        "forgotPassword": "Forgot Password",
        "enterYourEmail": "Enter your email we'll send you instructions.",
        "resetMyPassword": "Reset my password",
        "checkYourEmail": "If your email is registered, you will receive an email",
        "emailAddress": "Email Address",
        "signIn": "Sign in",
        "signUp": "Sign up"
      },
      "quote": {
        "from": "From",
        "to": "To",
        "intro": "The Future of Freight Shipping",
        "sub_text": "Booking and tracking your container and LCL shipments just got easier.",
        "action": "Get Quotes",
        "optionsText": "Options",
        "multiLegRateListForShare": {
          "label": {
            "supplier": "Supplier",
            "total": "Total",
            "book": "Book"
          },
          "rateItem": {
            "label": {
              "schedule": "Schedule",
              "of": "of",
              "originCfsCutOff": "Origin CFS Cut Off",
              "transitTimePortToPort": "Transit Time Port to Port",
              "etaCfs": "ETA CFS",
              "showHidePriceBreakdown": "Show / Hide price breakdown"
            }
          },
          "supplierItem": {
            "label": {
              "via": "Via:",
              "select": "Select"
            }
          }
        },
        "airRateList": {
          "rates": {
            "productName": {
              "value": "Value",
              "premium": "Premium",
              "now": "Now"
            },
            "truckingName": {
              "consolidationService": "Consolidation Service",
              "priority": "Priority",
              "speedService": "Speed Service"
            }
          },
          "heading": {
            "quotes": "Quotes"
          },
          "label": {
            "fareType": "Fare Type",
            "transitTime": "Typical Transit Time<i>(Airport to Airport)</i>"
          },
          "rateItem": {
            "label": {
              "moreInformation": "More Information",
              "closeBreakdown": "Close Breakdown",
              "to": "to",
              "flyingFrom": "Flying From",
              "days": "days"
            },
            "button": {
              "book": "Book"
            }
          }
        },
        "imp_exp": {
          "question": "Are you Importing or Exporting?",
          "import": "Import"
        },
        "options": {
          "label": "Choose your options",
          "yes": "yes",
          "no": "no",
          "q1": "Shall we complete customs for you?",
          "q2": "Are you shipping Hazardous Goods?",
          "q3": "Do you need Refrigerated Containers?"
        },
        "location": {
          "placeholder": "Any Port or Place",
          "no_result": "No Results. Got a location in mind?",
          "no_result_1": "Click",
          "no_result_2": "here",
          "no_result_3": "to chat to one of us.",
          "no_result_without_action": "There are no results. Please check the spelling or try again.",
          "localCharges": "Local Charges",
          "lcl": {
            "placeholder": {
              "cfsOrPlace": "Any CFS or Place",
              "airportOrPlace": "Any Airport or Place"
            },
            "label": {
              "fromConfirm": "I would like pickup",
              "toConfirm": "I would like delivery"
            }
          }
        },
        "datePicker": {
          "heading": {
            "question_port": "When would you like the vessel to sail?",
            "question_city": "When would you like pick up?",
            "question_road": "When is the cargo ready for collection?"
          },
          "lcl": {
            "label": {
              "heading": "When would you expect your cargo to be shipped?"
            }
          },
          "air": {
            "label": {
              "heading": "Cargo expected delivery date at Warehouse or Terminal?",
              "pickup": "When will the cargo be ready for pickup?"
            }
          }
        },
        "childBreakdown": {
          "label": {
            "days": "days",
            "by": "by",
            "with": "with",
            "on": "on",
            "etd": "ETD",
            "eta": "ETA",
            "transitTime": "Transit Time",
            "cfs": {
              "etd": "ETD CFS",
              "etdPreviousSiblingSea": "ETD Destination Port",
              "eta": "ETA CFS",
              "etaNextSiblingSea": "ETA Origin Port"
            },
            "sea": {
              "etd": "ETD Origin Port",
              "eta": "ETA Destination Port"
            }
          }
        },
        "containerSelector": {
          "title": "Add Containers",
          "standard": "Standard",
          "highCube": "High Cube",
          "question": "How many containers?",
          "quotes": "View Quotes",
          "standardContainer": "Standard Container",
          "remove": "Remove",
          "noContainersAvailable": "There are no containers available.",
          "selectDifferentPlace": "Try selecting a different <strong>From</strong> or <strong>To</strong> place or port.",
          "removeType": "Remove this container type",
          "addType": "Add another container type",
          "quantity": "Quantity",
          "eqType": "Equipment Type",
          "plus": "+",
          "minus": "-",
          "dropdownDefault": "Select an Equipment type",
          "button": {
            "viewSchedules": "View Schedules"
          }
        },
        "additionalService": {
          "title": "Additional services",
          "noResults": "There are no additional services available for this quote",
          "cevaBrokerage": {
            "shortDesc": "CEVA Brokerage short desc",
            "longDesc": "CEVA Brokerage long desc",
            "tncDesc": "CEVA Brokerage T&C"
          },
          "oneSgsDry20": {
            "shortDesc": "ONE SGS DRY 20 short desc",
            "longDesc": "ONE SGS DRY 20 long desc",
            "tncDesc": ""
          },
          "oneSgsDry40": {
            "shortDesc": "ONE SGS DRY 20 short desc",
            "longDesc": "ONE SGS DRY 20 long desc",
            "tncDesc": ""
          },
          "oneSgsDry40h": {
            "shortDesc": "ONE SGS DRY 20 short desc",
            "longDesc": "ONE SGS DRY 20 long desc",
            "tncDesc": ""
          },
          "oneSgsDry45h": {
            "shortDesc": "ONE SGS DRY 20 short desc",
            "longDesc": "ONE SGS DRY 20 long desc",
            "tncDesc": ""
          },
          "externalBrokerage": {
            "shortDesc": "External Brokerage short desc",
            "longDesc": "External Brokerage long desc"
          },
          "moreInfo": "More information",
          "product": {
            "label": {
              "from": "FROM",
              "perContainer": "PER CONTAINER",
              "per": "PER BOOKING",
              "total": "Total"
            },
            "button": {
              "viewProduct": "View Product",
              "subscribe": "Subscribe",
              "unsubscribe": "Unsubscribe"
            },
            "notification": {
              "fixValidationIssues": "Fix validation issues"
            },
            "validation": {
              "termsAndConditions": "You must agree to the terms &amp; conditions"
            }
          },
          "basketBar": {
            "heading": {
              "shippingSummary": "Shipping Summary"
            },
            "label": {
              "type": "Type",
              "shipping": "Shipping",
              "from": "From",
              "to": "To",
              "equipment": "Equipment",
              "total": "Total",
              "load": "Load",
              "packages": "Packages",
              "charges": "charges",
              "freight": "Freight",
              "origin": "Origin",
              "destination": "Destination",
              "vgmCutOff": "VGM cut off date",
              "documentCutOff": "Document cut off date"
            },
            "button": {
              "sendQuotes": "Send order",
              "continue": "Continue with booking",
              "without": "without additional services"
            },
            "placeholder": {
              "removeProduct": "Remove this product"
            }
          }
        },
        "incoterm": {
          "heading": {
            "incoterm": "Incoterm"
          },
          "label": {
            "moreInfo": "More information"
          }
        },
        "rateList": {
          "header": {
            "quotes": "Quotes",
            "schedules": "Schedules",
            "tableCol2": "Order by",
            "quickest": "Quickest",
            "cheapest": "Cheapest",
            "etd": "ETD",
            "buttons": {
              "offer": "Make Offer",
              "bookmark": "Bookmark"
            },
            "offer": {
              "text1": "Our cheapest quote is",
              "text2": "but if you usually pay less, make an offer...",
              "offerInputLabel": "Your offer amount",
              "offerInputPlaceholder": "Offer",
              "contactInputLabel": "Your contact details",
              "contactInputPlaceholder": "Email Address",
              "action": "Send Offer"
            },
            "lcl": {
              "label": {
                "originCfsCutOff": "Origin CFS cut off"
              }
            }
          },
          "sendQuotePanel": {
            "label": {
              "yourReference": "Your reference",
              "recipientEmail": "Recipient email address",
              "termsAndConditions": "By clicking on this you have agree to our <a href='{url}' target='_blank' title='opens in a new tab/window'>terms &amp; conditions</a>"
            },
            "button": {
              "sendQuote": "Send quote"
            },
            "placeholder": {
              "emailAddress": "Email Address",
              "yourReference": "Your reference",
              "recipientEmail": "Recipient email address"
            },
            "notification": {
              "shareSuccess": "Your quote has been shared",
              "shareError": "There is a problem sharing your quote",
              "allowedCharsError": "Sorry, the following characters are not allowed:"
            }
          },
          "results": {
            "wait": "Hold on while we fetch your rates...",
            "nores": "There are currently no results.",
            "nowres2": "Try selecting a different",
            "bookmarkSaved": "Your bookmark has been saved to your Dashboard",
            "from": "from",
            "to": "to",
            "or": "or",
            "pickup": "Pick Up",
            "date": "Date",
            "more": "More information",
            "copyBookingError1": "Booking can no longer be copied.",
            "copyBookingError2": "You must create a ",
            "newBooking": "New Booking",
            "share": "Share",
            "table": {
              "currencySelector": "Currency selector",
              "supplier": "Supplier",
              "etd": "ETD",
              "duration": "Duration",
              "eta": "ETA",
              "total": "Total Price",
              "days": "days",
              "breakdown": {
                "sailing": "Sailing",
                "actionPickup": "Aboard Truck",
                "actionSailing": "Aboard Ship",
                "arrive": "Arrive",
                "closeBreakdown": "Close Breakdown",
                "trucking": "Trucking",
                "pickup": "Pick Up",
                "shipping": "Freight",
                "delivery": "(Delivery)",
                "bol": "BOL",
                "handling": "Handling",
                "customs": "Customs",
                "total": "Total",
                "action": "Book",
                "by": "by",
                "with": "with",
                "on": "on",
                "from": "from",
                "cutOff": "Cut off",
                "days": "days",
                "via": "Via",
                "x": "x",
                "quote": "'",
                "charges": "charges",
                "freight": "Freight",
                "origin": "Origin",
                "destination": "Destination",
                "lcl": {
                  "label": {
                    "roadPickup": "ETD",
                    "seaPickup": "Origin Port ETD",
                    "actionSailing": "Transit Time CFS to CFS",
                    "roadArrive": "ETA",
                    "seaArrive": "Destination Port ETA",
                    "seaCharges": "All charges from CFS to CFS",
                    "roadCharges": "All charges from CFS to door"
                  }
                }
              },
              "lcl": {
                "label": {
                  "etd": "Origin CFS",
                  "eta": "ETA CFS",
                  "duration": "Transit Time"
                }
              }
            }
          },
          "label": {
            "cfs": "CFS",
            "to": "to",
            "leg": "Leg",
            "total": "Total",
            "vgmCutOff": "VGM cut off date",
            "documentCutOff": "Document cut off date"
          }
        },
        "shipmentType": {
          "heading": {
            "areYouImportingOrExporting": "Are you importing or exporting?"
          },
          "button": {
            "importing": "Importing",
            "exporting": "Exporting"
          }
        },
        "leftNav": {
          "heading": {
            "lclLoads": "LCL Loads",
            "loads": "Loads",
            "shipping": "Shipping",
            "transport": "Transport",
            "typeAir": "Plane Departs",
            "typePort": "Ship Departs",
            "typeCity": "Pickup",
            "importExport": "Import/Export",
            "preferredDepartureDate": "Preferred Departure Date",
            "preferredPickupDate": "Preferred Pickup Date"
          },
          "label": {
            "packages": "Packages",
            "load": "Load",
            "noOfPackages": "no of packages",
            "ETACFSPickup": "ETA CFS/Pickup",
            "ETA": "ETA",
            "selectDifferentFromTo": "Try selecting a different <strong>From</strong>, <strong>To</strong> or <strong>Pick Up</strong> date."
          },
          "button": {
            "edit": "Edit"
          },
          "lcl": {
            "label": {
              "dateHeading": "Origin CFS"
            }
          }
        },
        "lclLoadWrapper": {
          "heading": {
            "addLclLoads": "Add LCL Loads",
            "addLoads": "Add Loads",
            "noLclLoadsAvailable": "There are no LCL loads available.",
            "noLoadsAvailable": "There are no loads available."
          },
          "label": {
            "addAFromAndTo": "Add a <strong>From</strong> and <strong>To</strong> CFS or Place.",
            "addAFromAndToAirportOrPlace": "Add a <strong>From</strong> and <strong>To</strong> Airport or Place."
          }
        },
        "fcl": {
          "label": {
            "hazardousCargo": "I confirm that this load does not contain any cargo classes as hazardous and is not considered flamable."
          },
          "validation": {
            "hazardousCargo": "We do not currently support hazardous cargo"
          }
        },
        "lclLoad": {
          "heading": {
            "whatIsYourLCLCargo": "What is your LCL cargo?",
            "yourCargoDetails": "Your cargo details",
            "lclLoad": "LCL Load {num} <span>(of same dimensions and weight)</span>",
            "load": "Load {num} <span>(of same dimensions and weight)</span>",
            "dimensionsPerUnit": "Dimensions per unit",
            "totals": "Totals",
            "totalsCalculated": "Totals calculated from unit dimensions.",
            "grandTotals": "Grand totals"
          },
          "label": {
            "length": "Length",
            "width": "Width",
            "height": "Height",
            "weight": "Weight",
            "volume": "Volume",
            "packages": "Packages",
            "howManyPackages": "How many packages",
            "cbm": "Cbm",
            "cft": "Cbf",
            "kg": "Kg",
            "removeLoad": "Remove load",
            "viewQuotes": "View Quotes",
            "viewSchedules": "View Schedules",
            "viewServices": "View Services",
            "addAnotherLoad": "Add another load",
            "nonStackable": "Non-Stackable",
            "hazardousCargo": " I confirm that this load does not contain any cargo classes as hazardous and is not considered flamable.",
            "requestThis": "Request this",
            "requested": "Requested"
          },
          "validation": {
            "howManyPackages": "You must set the number of packages",
            "noSpecialChars": "This is invalid. No special characters please.",
            "cannotBeZero": "This cannot be 0",
            "numbersOnly": "You can only use numbers.",
            "volumeMinimum": "Cbm should be at least 0.001",
            "overweight": "Overweight package",
            "oversized": "Oversized package",
            "overweightImperial": "Overweight package",
            "oversizedImperial": "Oversized package",
            "nonStackable": "We do not currently support non-stackable loads",
            "hazardousCargo": "We do not currently support hazardous cargo"
          },
          "placeholder": {
            "eg": "e.g. {num}",
            "selectAUnit": "select a unit of {dimension}"
          },
          "notification": {
            "fixValidationIssues": "Fix validation issues",
            "nonStackableRequested": "Your request has been submitted, we will be in touch.",
            "nonStackableRequestFailed": "Your request could not be submitted at this time. Please try again later or contact support."
          }
        },
        "loadType": {
          "header": {
            "question": "What are you shipping?"
          },
          "label": {
            "fcl": "Full Container Load",
            "lcl": "Less than Container Load",
            "air": "Air Cargo"
          }
        },
        "share": {
          "notification": {
            "sharedWith": "Thank you, your quotes have been sent to",
            "pleaseSelectQuote": "Please select a quote",
            "problemWithShare": "There was a problem sharing the quote",
            "quoteNotFound": "Sorry, we could not find an order with that reference",
            "problemWithRetrieve": "There was a problem retrieving the quote",
            "pleaseEnterRef": "Please enter a reference"
          }
        }
      },
      "currency": {
        "name": "USD",
        "symbol": "$"
      },
      "address": {
        "postcode": "Zip / Post Code",
        "country": "united states of america"
      },
      "mode": {
        "sea": {
          "label": {
            "port": "Port",
            "type": "Shipping",
            "from": "Sailing from",
            "to": "Sailing to",
            "aboard": "Aboard Ship",
            "transitTime": "Aboard Ship",
            "transitTimeLabel": {
              "default": "Aboard Ship",
              "portToPort": "Port to Port"
            },
            "etd": "Origin Port ETD",
            "eta": "Destination Port ETA",
            "charge": "charges from CFS to CFS",
            "chargeLabel": {
              "default": "charges",
              "portToPort": "charges Port to Port"
            }
          }
        },
        "cfs": {
          "label": {
            "cfs": "CFS",
            "type": "Shipping",
            "from": "From",
            "to": "To",
            "aboard": "Aboard Ship",
            "transitTime": "Transit Time CFS to CFS",
            "transitTimeLabel": {
              "default": "CFS to CFS",
              "portToCfs": "Port to CFS"
            },
            "etd": "Origin CFS Cut Off",
            "etdLabel": {
              "default": "Origin CFS ETD",
              "cutOff": "Origin CFS Cut Off"
            },
            "eta": "Destination CFS ETA",
            "charge": "charges from CFS to CFS",
            "chargeLabel": {
              "default": "charges CFS to CFS",
              "portToCfs": "charges Port to CFS"
            }
          }
        },
        "road": {
          "label": {
            "road": "Road",
            "type": "Road",
            "truck": "truck",
            "from": "Picked up from",
            "to": "Dropped off to",
            "aboard": "Aboard Truck",
            "transitTime": "Transit Time",
            "transitTimeLabel": {
              "default": "Transit Time",
              "cfsToDoor": "CFS to Door",
              "doorToCfs": "Door to CFS"
            },
            "etd": "ETD",
            "eta": "ETA",
            "charge": "charges from CFS to door",
            "chargeLabel": {
              "default": "charges",
              "cfsToDoor": "charges CFS to Door",
              "doorToCfs": "charges Door to CFS"
            }
          }
        },
        "rail": {
          "label": {
            "rail": "Rail",
            "type": "Rail",
            "from": "Picked up from",
            "to": "Dropped off to",
            "aboard": "Aboard Train",
            "transitTime": "Transit Time",
            "transitTimeLabel": {
              "default": "Transit Time",
              "cfsToRail": "CFS to Rail",
              "railToCfs": "Rail to CFS"
            },
            "etd": "ETD",
            "eta": "ETA",
            "charge": "charges from CFS to rail",
            "chargeLabel": {
              "default": "charges",
              "cfsToRail": "charges CFS to Rail",
              "railToCfs": "charges Rail to CFS"
            }
          }
        },
        "air": {
          "label": {
            "air": "Air",
            "type": "Air",
            "from": "Picked up from",
            "to": "Dropped off to",
            "aboard": "Aboard plane",
            "transitTime": "Transit time",
            "transitTimeLabel": {
              "default": "Transit time",
              "cfsToAir": "CFS to airport",
              "airToCfs": "Airport to CFS"
            },
            "etd": "ETD",
            "eta": "ETA",
            "charge": "charges from CFS to airport",
            "chargeLabel": {
              "default": "charges",
              "cfsToAir": "charges CFS to airport",
              "railToCfs": "charges airport to CFS"
            }
          }
        }
      },
      "settings": {
        "title": "Settings",
        "contacts": "Contacts",
        "primary": "Primary",
        "saveDetails": "Save Details",
        "saveSetting": "Save Settings",
        "saveAddress": "Save Address",
        "saveBilling": "Save Billing",
        "accountUpdated": "Account updated.",
        "terms": "Privacy policy",
        "roleInfo": "Only Admin users can edit this setting",
        "resetPassword": "Change my password",
        "changePwd": {
          "header": "Change Password",
          "currentPwd": "Current password",
          "showButton": "Show",
          "hideButton": "Hide",
          "newPwd": "New password",
          "action": "Change Password",
          "newPassPlaceHolder": "Enter your new password",
          "currentPassPlaceHolder": "Enter your current password",
          "cantBeBlank": "Cannot be Blank",
          "pwdChanged": "Password changed",
          "pwdChangedFailed": "Password change error"
        },
        "team": {
          "header": "Team Settings",
          "title": "Team",
          "desc": "Your team can create and edit orders as well as share contacts for your Kontainers account.",
          "emailAddress": "Enter Email Address",
          "noTeamMembers": "You have no team members yet. You can invite team members above.",
          "noInvites": "You have no pending invites. You can invite team members above.",
          "inviteTeamMember": "Invite Team Member",
          "inviteSent": "The invite has been sent",
          "resendInvite": "Resend invite",
          "pendingInvites": "Pending Invites",
          "pendingText": "When invited team members are sent an email to allow them to sign up.",
          "teamMembers": "Team Members",
          "teamMembersText": "Your current team members who have signed up are shown here.",
          "noResults": "No team member found",
          "primarySaved": "Role updated",
          "deleteMember": "This team member will be permanently deleted"
        }
      },
      "resetPass": {
        "header": "Reset Password",
        "instruction": "Enter your new password, then click the <strong>Reset</strong> Button ↓",
        "invalidTokenWarning": "The reset password token is unknown. It may have been used already.",
        "invalidTokenWarning2": "Try resetting your password again.",
        "noPass": "Please enter a password.",
        "newPassword": "New Password",
        "newPasswordRetype": "Re-type New Password",
        "reset": "Reset",
        "system": "There is a problem with the server. Please try again."
      },
      "validation": {
        "landlord": {
          "avatarTooLong": "Avatar is too long",
          "cannotIssueInviteForActiveUser": "Cannot issue invite for active user",
          "emailInvalid": "Email address appears to be in wrong format",
          "emailTooLong": "Email address is too long",
          "firstNameTooLong": "First name is too long",
          "lastNameTooLong": "Last name is too long",
          "forgetEmailTokenTooLong": "Forget email token is too long",
          "passwordHashTooLong": "Password hash is too long"
        },
        "trip": {
          "supplierNotFound": "Supplier does not exist"
        },
        "quote": {
          "noLocationsSet": "You need to enter locations, from and to"
        },
        "company": {
          "address1TooLong": "Address 1 is too long",
          "address2TooLong": "Address 2 is too long",
          "address3TooLong": "Address 3 is too long",
          "address4TooLong": "Address 4 is too long",
          "cityTownTooLong": "City is too long",
          "companyCodeTooLong": "Company code is too long",
          "danNumberTooLong": "Dan number is too long",
          "departmentTooLong": "Department is too long",
          "faxTooLong": "Fax is too long",
          "firstNameTooLong": "First name is too long",
          "lastNameTooLong": "Last name is too long",
          "phoneTooLong": "Phone is too long",
          "postCodeTooLong": "Post Code is too long",
          "referenceTooLong": "Reference is too long",
          "regionTooLong": "Region is too long",
          "vatNoTooLong": "VAT number is too long"
        },
        "team": {
          "cannotIssueInviteForActiveUser": "Cannot issue invite for active user"
        },
        "address": {
          "address1TooLong": "Address 1 is too long",
          "address2TooLong": "Address 2 is too long",
          "cityTownTooLong": "City is too long",
          "customerCodeTooLong": "Customer code is too long",
          "emailTooLong": "Email address is too long",
          "faxTooLong": "Fax is too long",
          "firstNameTooLong": "First name is too long",
          "lastNameTooLong": "Last name is too long",
          "phoneTooLong": "Phone is too long",
          "postCodeTooLong": "Post Code is too long",
          "regionTooLong": "State is too long",
          "vatNoTooLong": "VAT number is too long"
        },
        "user": {
          "avatarTooLong": "Avatar is too long",
          "emailInvalid": "Email address appears to be in wrong format",
          "emailTooLong": "Email address is too long",
          "firstNameTooLong": "First name is too long",
          "lastNameTooLong": "Last name is too long",
          "forgetEmailTokenTooLong": "Forget email token is too long",
          "passwordHashTooLong": "Password hash is too long",
          "ssoSubjectTooLong": "SSO subject is too long"
        },
        "booking": {
          "address1ExceedsNadSegmentMaxLength": "Address line 1 is too long",
          "address2ExceedsNadSegmentMaxLength": "Address line 2 is too long",
          "address3ExceedsNadSegmentMaxLength": "Address line 3 is too long",
          "address4ExceedsNadSegmentMaxLength": "Address line 4 is too long",
          "addressCountryExceedsNadSegmentMaxLength": "Address Country exceeds maximum length",
          "airFlowAppliesToReefersOnly": "Equipment is not a reefer",
          "airflowCannotBeNegative": "Negative Air Flow",
          "airflowUnitMustBePresent": "Missing reefer parameter",
          "bookingMustHaveExactlyOneMainLegDetail": "Booking must have exactly one Sea / CFS logistics detail",
          "bookingOfflineQuoteReferenceMandatory": "Offline Quote Reference is required",
          "cityAndPostcodeExceedNadSegmentMaxLength": "City and Postcode exceed maximum length",
          "cityTownExceedsMaxLength": "City exceeds maximum length",
          "commodityCodeInvalid": "Commodity Code invalid",
          "commodityVolumeNan": "Commodity volume is not a number",
          "commodityVolumeNegativeNumber": "Commodity volume value is negative",
          "commodityWeightNan": "Commodity weight is not a number",
          "commodityWeightNegativeNumber": "Commodity weight value is negative",
          "containerDetailStartsWithBlankLine": "Container detail starts with empty line",
          "containerNumberNotUnique": "Container number is not unique",
          "containerWeightExceeded": "Commodity weights exceed container weight",
          "containerWeightNan": "Container weight is not a number",
          "containerWeightNegativeNumber": "Container weight value is negative",
          "containerVolumeNan": "Container volume is not a number",
          "containerVolumeNegativeNumber": "Container volume value is negative",
          "contractReferenceTooLong": "Contract reference is too long",
          "customerCodeTooLong": "Customer code is too long",
          "customerReferenceRequired": "Customer reference is required",
          "customerReferenceTooLong": "Customer reference is too long",
          "equipmentMaxWeightExceeded": "Container exceeds maximum weight",
          "faxExceedsMaxLength": "Address has fax number that is too long",
          "fieldContainInvalidCharacters": "Field contains invalid characters",
          "firstNameExceedsMaxLength": "First name exceeds maximum length",
          "humidityAppliesToReefersOnly": "Equipment is not a reefer",
          "inconsistentCommodityWeightUnits": "Commodity weight units must be consistent",
          "inconsistentCommodityVolumeUnits": "Commodity volume units must be consistent",
          "inconsistentWeightUnits": "Container weight units must be consistent",
          "inconsistentVolumeUnits": "Container volume units must be consistent",
          "incorrectContainerNumber": "Container number is incorrect",
          "invalidHumidity": "Invalid Humidity",
          "lastNameExceedsMaxLength": "Last name exceeds maximum length",
          "lclBookingMustHaveExactlyOneItem": "LCL booking must have exactly one bookingItem",
          "lclBookingMustHaveAtLeastOnItemDetail": "LCL booking must have at least one commodity",
          "marksAndNumbersTooLong": "Marks and Numbers line is too long",
          "missingCommodityCode": "Commodity Code is missing",
          "missingContainerDetail": "Container Detail is missing",
          "missingContainerNumber": "Container number is missing",
          "missingExpectedCargoReadyTime": "Container should have a valid expectedCargoReadyTime when there are nested schedules",
          "missingNumberOfPackages": "Number of Packages is missing",
          "missingPackagingCode": "Packaging Code is missing",
          "missingRequiredAddress": "Address is required",
          "missingSealNumber": "Seal Number is missing",
          "missingTypeOfPackaging": "Type of Packaging is missing",
          "missingWeight": "Weight is missing",
          "nameExceedsNadSegmentMaxLength": "Address has name that is too long",
          "noCommoditiesSpecifiedForContainer": "No commodities specified for container",
          "noContractReferenceSpecified": "No contract reference specified for booking",
          "phoneExceedsMaxLength": "Address has phone number that is too long",
          "postCodeExceedsMaxLength": "Postcode exceeds maximum length",
          "referenceRequiredForConfirmedBookings": "Booking Reference is required for Confirmed Bookings",
          "sealNumberLineTooLong": "Seal number is too long",
          "temperatureAndUnitMustBePresent": "Missing reefer parameter",
          "temperatureAppliesToReefersOnly": "Equipment is not a reefer",
          "temperatureMustBePresent": "Missing reefer parameter",
          "temperatureUnitMustBePresent": "Missing reefer parameter",
          "tooManyCommoditiesSpecifiedForContainerInBIMode": "Container should have exactly one commodity in BI mode",
          "totalValueNan": "Total value is not a number",
          "vatNoTooLong": "VAT number is too long"
        }
      },
      "booking": {
        "titleBi": "Booking Details",
        "titleSi": "Shipping instructions",
        "from": "From",
        "to": "To",
        "vessel": "Vessel",
        "with": "With",
        "etd": "ETD",
        "date": "Date",
        "ref": "Customer Reference",
        "custDetail": "Customer Details",
        "consignor": "Shipper",
        "addConsignorAddy": "Add Shipper Address",
        "consignee": "Consignee",
        "addConsigneeAddy": "Add Consignee Address",
        "notify": "NOTIFY PARTY",
        "addNotifyAddy": "Add a Notified Party",
        "brokerage": "Brokerage",
        "addBrokerageAddy": "Add a brokerage address",
        "customerReferenceHelpText": "Enter a customer reference for this booking",
        "weightSelector": "Gross weight unit selector",
        "pleaseSelect": "Please select",
        "notes": "Notes",
        "optional": "(Optional)",
        "creatingModal": {
          "heading": {
            "creatingBooking": "Creating booking",
            "uploadError": "File upload error"
          },
          "label": {
            "uploadFiles": "Uploading files",
            "infoNote": "There has been a file upload error. You can return to the booking and try the file upload again or continue with the booking and try again any time using the 'Files &amp; Docs' button on the order on the dashboard.",
            "return": "Return to booking",
            "continue": "Continue booking and upload files later"
          }
        },
        "freightCollectionType": {
          "heading": {
            "freightTerms": "Freight Terms"
          },
          "label": {
            "prepaid": "Prepaid",
            "collect": "Collect"
          }
        },
        "lclLoad": {
          "heading": {
            "lclLoad": "LCL Loads",
            "cargoLoads": "Cargo Loads",
            "cargoDetails": "Cargo Details",
            "load": "Load",
            "dimensions": "Dimensions",
            "weight": "Weight",
            "collection": "Collection",
            "expectedCargoReady": "Specify the expected cargo ready date and time",
            "delivery": "Delivery"
          },
          "placeholder": {
            "containerDetail": "e.g. 50 pallets of caps"
          },
          "label": {
            "lclLoad": "Click here to enter cargo details",
            "containerDetail": "Cargo",
            "width": "width",
            "height": "height",
            "length": "length",
            "times": "x",
            "from": "From",
            "changeAddress": "Change Address",
            "loadingReference": "Loading Reference",
            "date": "Date",
            "time": "Time",
            "expectedCargoReadyDate": "Ready date",
            "expectedCargoReadyTime": "Ready time",
            "deliveryAddress": "Delivery Address"
          }
        },
        "lclLoadCommodity": {
          "heading": {
            "commodity": "Commodity"
          },
          "label": {
            "description": "Commodity Description",
            "marks": "Marks and Numbers",
            "commodityCode": "Commodity Code",
            "find": "Find",
            "typeOfPackaging": "Type of Packaging",
            "packagingType": "Packaging Type",
            "totalValue": "Product Value",
            "numberOfPackages": "How many Packages"
          },
          "placeholder": {
            "eg": "e.g. {str}",
            "description": "e.g. 50 pallets of caps",
            "packagingType": "Enter a packaging type"
          }
        },
        "formType": {
          "title": "Bill of Lading",
          "description": "Please choose between <strong>Seaway Bill of Lading</strong> and <strong>Original Bill of Lading</strong>. If original bill of lading is selected please also add the number of originals.",
          "expressLabel": "<strong>Seaway</strong> Bill of Lading",
          "originalLabel": "<strong>Original</strong> Bill of Lading",
          "numberOfOriginalBills": "Number of original bills",
          "numberOfOriginalBillsPlaceholder": "e.g. 2"
        },
        "draft": {
          "notification": {
            "restoreSuccess": "We have restored your incomplete draft booking",
            "restoreFailed": "Sorry, we could not restore your draft booking",
            "bookingNotFound": "This booking could not be found"
          }
        },
        "draftModal": {
          "heading": "Would you like to restore this draft booking?",
          "label": {
            "shipmentFrom": "shipment from",
            "to": "to",
            "createdOn": "created on"
          },
          "button": {
            "discard": "Discard",
            "later": "Maybe later",
            "restore": "Restore now"
          }
        },
        "notesModal": {
          "header": "Booking Note",
          "label": "Notes",
          "note": "This note will be visible to colleagues and administrators.",
          "action": "Save Note",
          "placeholder": "Enter note text"
        },
        "notesBar": {
          "header": "Booking Notes",
          "noNotes": "There are no notes",
          "new": "Add a New Note"
        },
        "contactButton": {
          "new": "New",
          "existing": "Contacts"
        },
        "containers": "Containers",
        "consignment": "Consignment",
        "bol": {
          "title": "Bill of Lading",
          "one": "Your Kontainers order already includes",
          "two": "Seaway Bill of Lading",
          "three": "but you can also include the Original Bill of Lading if you wish.",
          "four": "Include Original Bill of Lading"
        },
        "tAndC": {
          "one": "Tick to confirm you have read the",
          "two": "terms &amp; conditions",
          "three": ", goods include no personal effects,",
          "four": "no refrigerated goods in dry containers",
          "five": "&amp; all information is accurate.",
          "tc": "Terms &amp; Conditions"
        },
        "actions": {
          "saveBooking": "Save",
          "submitBI": "Submit BI",
          "submitSI": "Submit SI",
          "saveChanges": "Save Changes",
          "viewSummary": "View Order Summary"
        },
        "containerDetails": {
          "temperature": "Reefer Details",
          "temperatureLabel": "Temperature",
          "temperatureFieldDesc": "What is cargo temperature for this reefer?",
          "temperaturePlaceHolder": "e.g. 8",
          "airflow": "Ventilation",
          "humid": "Dehumidification",
          "cargoHeader": "Cargo Details",
          "containerNumber": "Container Number",
          "containerNumberPlaceholder": "e.g. CSQU3054383",
          "sealNumber": "Seal Number",
          "sealNumberPlaceholder": "e.g. 333,444 separated by comma",
          "copyDetails": "Copy Details",
          "deliveryAddyPlaceHolder": "Click the change address button to set the delivery address",
          "expectedCargoReady": "Expected cargo ready",
          "expectedCargoReadyLabel": "Specify the expected cargo ready date and time",
          "readyDate": "Ready date",
          "readyTime": "Ready time",
          "flexitank": {
            "header": "Flexitank",
            "label": "Does the container include a flexitank?",
            "unknown": "Not known yet",
            "true": "Yes, this container has a Flexitank",
            "false": "No, this container does not have a Flexitank"
          },
          "soc": {
            "header": "Shipper Owned Container",
            "label": "Is the container owned by the shipper?",
            "unknown": "Not known yet",
            "true": "Yes, this container IS owned by the shipper",
            "false": "No, this container is NOT owned by the shipper"
          },
          "lcl": {
            "title": "LCL Cargo",
            "detailLabel": "Cargo",
            "detailPlaceHolder": "e.g. 100 Pallets of Caps",
            "dimension": {
              "header": "Pallet Details"
            }
          },
          "fcl": {
            "containerCount": "Container",
            "detailLabel": "Cargo",
            "detailPlaceHolder": "e.g. 100 Pallets of Caps",
            "weight": "Weight",
            "weight2": "(Cargo Gross Weight)"
          },
          "trucking": {
            "pickup": "Collection",
            "from": "From",
            "pickupAddress": "Add Pickup Address",
            "change": "Change Address",
            "confirm": "Confirm",
            "date": "Date",
            "time": "Time",
            "delivery": "Delivery",
            "deliveryAddy": "Add Delivery Address"
          },
          "customs": {
            "import": {
              "title": "Import Customs",
              "info": "Each commodity",
              "info1": "must be entered separately",
              "addAnother": "Add another Commodity",
              "confirm": "Please confirm the goods are not leaving the EU",
              "confirmLabel": "Confirm"
            },
            "export": {
              "title": "Customs",
              "info": "Each commodity",
              "info1": "must be entered separately",
              "confirm": "Please confirm this is a permanent export",
              "confirmLabel": "Confirm",
              "addAnother": "Add another Commodity"
            }
          },
          "reefer": {
            "title": "Temperature",
            "info": "What is the cargo temperature for this reefer?",
            "label": "Temperature"
          },
          "commodity": {
            "infoNote": "<strong>Each commodity</strong> must be entered separately →",
            "add": "Add Commodity",
            "commodity": "Commodity",
            "remove": "Remove this Content Type",
            "description": "Commodity Description",
            "descriptionPlaceholder": "e.g. 50 Pallets of plastic contents",
            "code": "Commodity Code",
            "codePlaceHolder": "e.g. SCTG 37210",
            "marksAndNumbers": "Marks and Numbers",
            "marksAndNumbersPlaceholder": "e.g. PO_123456",
            "typeOfPackaging": "Type of Packaging",
            "typeOfPackagingPlaceholder": "e.g. Boxes",
            "noPackagingCode": "No matching result to select from. Please just enter a description.",
            "numberOfPackages": "How many Packages",
            "weight": "Weight",
            "volume": "Volume",
            "productValue": "Product Value",
            "commodityCodeLookup": "Commodity Code Lookup",
            "commodityCodeLookupDesc": "Write the name of your product in the code look up and pick the one that nearest describes your commodity.",
            "productDesc": "Product Description",
            "productDescPlaceholder": "e.g. Bags & Accessories of leather",
            "noResults": "No Results. Try searching again.",
            "noCodeError": "We could not find the code you are looking for",
            "enterCode": "...or if you know the Commodity Code type it below.",
            "enterCodePlaceholder": "e.g. SCTG 37210",
            "commodityCode": "Commodity Code",
            "continue": "Continue",
            "dismiss": "dismiss"
          },
          "copyContainerModal": {
            "title1": "Copy &amp; Paste Details",
            "title2": "From",
            "title3": "Container",
            "selectContainer": "Select details to copy",
            "selectAll": "Select All",
            "cargoDetails": "Cargo Details",
            "commodities": "Commodities",
            "temperature": "Reefer Details",
            "ifApplicable": "(If applicable)",
            "paste": "Paste",
            "toContainer": "Containers<span>Select containers to paste details to</span>",
            "excludingReefer": "(Excluding reefer details)",
            "notificationText1": "Details from Container",
            "notificationText2": "copied to Container",
            "selectDetailError": "You must select some details",
            "selectContainerError": "You must select some containers",
            "nonReeferError": "You cannot copy reefer details to a non-reefer container"
          }
        },
        "reference": {
          "addAnother": "Add another reference",
          "addMore": "Add one or more other references →",
          "delete": "Delete reference",
          "placeholder": "e.g. booking-0001",
          "refName": "Ref. name",
          "refValue": "Ref. value",
          "infoNote": "To facilitate processing of the booking, you can <a>attach a rate agreement record</a> (pdf,  e-mail and image files accepted)."
        },
        "summary": {
          "title": "Order Summary",
          "customerDetails": "Customer Details",
          "consignor": "SHIPPER",
          "consignee": "CONSIGNEE",
          "notifyParty": "NOTIFY PARTY",
          "brokerage": "BROKERAGE",
          "shipping": "Basic Ocean Freight",
          "basicOceanFreight": "Shipping",
          "fareType": "Fare type",
          "shipName": "Ship Name",
          "voyageNumber": "Voyage Number",
          "estimateDeparture": "Estimated Departure",
          "estimatedArrival": "Estimated Arrival",
          "cutOff": "Cut off",
          "vgmCutOff": "VGM cut off date",
          "documentCutOff": "Document cut off date",
          "container": "Container",
          "containerContents": "Container Contents",
          "grossWeight": "Gross Weight",
          "summary": "Summary",
          "promotionalDiscountCode": "Promotional Discount Code",
          "enterCode": "Enter code",
          "apply": "APPLY",
          "trucking": "Trucking",
          "handling": "Terminal Handling Service - Origin",
          "billOfLadingFee": "Documentation Fee - Origin",
          "service": "Export Service",
          "total": "Total",
          "confirmBooking": "CONFIRM BOOKING",
          "confirmAndBook": "Confirm and Book",
          "editBooking": "Edit Booking",
          "goToDashboard": "GO TO DASHBOARD",
          "goToPayment": "Payment Conditions",
          "saveError": "Problem saving booking. Please try again later.",
          "note": "Please note booking cost is subject to our",
          "tandc": "Terms &amp; Conditions",
          "percentageNotice": "% discount will be applied to the BAS rate in the final invoice.",
          "containerDetailView": {
            "heading": {
              "lclShipment": "LCL Shipment",
              "airShipment": "Air Shipment",
              "cargo": "Cargo",
              "containerContents": "Container Contents",
              "collection": "Collection",
              "from": "FROM",
              "to": "TO",
              "delivery": "Delivery",
              "customs": "Customs",
              "refrigeration": "Refrigeration",
              "container": "Container"
            },
            "label": {
              "cargoDetails": "Cargo Details",
              "commodityCode": "Commodity Code",
              "loadDetails": "Load {index} Details",
              "dimensions": "Dimensions",
              "grandTotals": "Grand Totals",
              "volume": "Volume",
              "weight": "Weight",
              "collectionDate": "Collection Date",
              "collectionTime": "Collection Time",
              "yes": "Yes",
              "valueOfProduct": "Value of product",
              "isThisContainerRefrigerated": "Is this container refrigerated?",
              "temperature": "Temperature",
              "grossVolume": "Gross Volume",
              "grossWeight": "Gross Weight",
              "deliveryToDestination": "Delivery to destination"
            }
          }
        },
        "payment": {
          "assignPartyHeader": "Assign paying party",
          "assignPartyBody": "Choose one of your contacts to assign this payment to and click",
          "assignPartyBody2": "Confirm and book",
          "assignPartyBody3": "We will send you the invoice once your order is on board the ship.",
          "assignPartyBody4": "After you pay, please submit your Remittance advice for this booking in ‘Files & Docs’ under ‘Open Orders’ in your dashboard.",
          "selectContact": "Select a contact",
          "assignAndBook": "Confirm and book",
          "paymentOptions": "Payment",
          "message1": "Assign paying parties",
          "message2": "Choose one of your contacts to assign each payment to and click <strong>Confirm and book</strong>.",
          "message3": "We will send you the invoice once your order is on board the ship.",
          "message4": "After you pay, please submit your Remittance advice for this booking in ‘Files &amp; Docs’ under ‘Open Orders’ in your dashboard.",
          "noPayerError": "You must set a payer for all charges",
          "incoTermWarning1": "The payment terms is set to",
          "incoTermWarning2": "Incoterm.",
          "incoTermWarning3": "Please note the payment term does not conform to either FOB or CFR, however you can still create this booking.",
          "savingBookingError": "There is a problem saving your booking.",
          "unknownIncotermMsg1": "Unknown Incoterm",
          "unknownIncotermMsg2": "The incoterm for this shipment could not be calculated from the payment details entered. Please select one below.",
          "unknownIncotermMsg3": "Find out more information about incoterms on the <a href='https://iccwbo.org/resources-for-business/incoterms-rules/incoterms-2020/' target='_blank'>ICC website</a>.",
          "unknownIncotermError": "An incoterm must be selected",
          "cost": {
            "name": "Name",
            "description": "Description",
            "amount": "Amount",
            "payingParty": "Paying party",
            "setPayerOriginCharges": "Set payer for all <strong>origin charges</strong>",
            "setPayerFreightCharges": "Set payer for all <strong>freight charges</strong>",
            "setPayerDestinationCharges": "Set payer for all <strong>destination charges</strong>",
            "setPayerConsignor": "Consignor",
            "setPayerConsignee": "Consignee",
            "setPayerNotifiedParty": "Notified party",
            "setPayerBookedByParty": "Booked by party",
            "selectPayer": "Select payer",
            "selectPayerAbove": "Select payer above",
            "label": {
              "backToSummary": "Back to Summary"
            },
            "heading": {
              "origin": "Origin Charges",
              "freight": "Freight Charges",
              "destination": "Destination Charges"
            }
          }
        },
        "status": {
          "created": "Created",
          "booked": "Booked",
          "confirmed": "Confirmed",
          "customsCleared": "Customs cleared",
          "shippedOnBoard": "Shipped on board",
          "billOfLadingApproved": "Bill of lading (verify copy)",
          "arrived": "Arrived",
          "vesselDeparted": "Vessel departed",
          "vesselArrived": "Vessel arrived",
          "truckDeparted": "Truck departed",
          "confirmSI": "Confirm SI",
          "invoiceAvailable": "Invoice"
        },
        "fileUpload": {
          "heading": {
            "filesAndDocuments": "Files &amp; Documents"
          },
          "dropzone": {
            "notification": {
              "fileAdded": "File added and ready for upload when booking created",
              "fileTooLarge": "Sorry, the file is larger than the allowed 5 MB: ",
              "fileDuplicate": "Sorry, it seems a file with the same name has already been added: "
            },
            "label": {
              "dropToUpload": "Drop to upload",
              "invalidType": "Sorry, invalid file type",
              "dropHere": "Drop files here to upload"
            }
          },
          "listing": {
            "heading": {
              "fileName": "File Name",
              "description": "Description",
              "actions": "Actions"
            },
            "file": {
              "label": {
                "save": "Save",
                "selectFileType": "Select a file type",
                "other": "other"
              },
              "placeholder": {
                "enterAFileType": "Enter a file type"
              },
              "validation": {
                "fileTypeIsMissing": "File type is missing",
                "error": "Must be a PDF, no larger than 5 MB"
              }
            }
          }
        }
      },
      "navBar": {
        "className": "usa",
        "newQuote": "New Booking",
        "retrieveQuote": "Retrieve a Quote",
        "placeBooking": "Place a booking",
        "logout": "Logout",
        "dashboard": "Dashboard",
        "settings": "Settings",
        "signIn": "Sign in / Register",
        "language": "English",
        "help": "Help"
      }
    }
  }];
  _exports.default = _default;
});