define("chimaera/utils/measurement-units", ["exports", "@cetus/kontainers-ui-core/utils/measurement-units"], function (_exports, _measurementUnits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "defaultMeasurementUnits", {
    enumerable: true,
    get: function get() {
      return _measurementUnits.defaultMeasurementUnits;
    }
  });
});