define("chimaera/services/web-storage", ["exports", "@cetus/kontainers-ui-core/services/web-storage"], function (_exports, _webStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _webStorage.default;
    }
  });
});