define("chimaera/pods/components/dashboard/copy-booking-modal/section/files-and-documents/component", ["exports", "chimaera/pods/components/dashboard/copy-booking-modal/section/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // Computed Properties
    selectedAll: Ember.computed('files.@each.willCopy', {
      get: function get() {
        var files = this.files;
        return files.every(function (file) {
          return file.willCopy;
        });
      }
    }),
    selectedSome: Ember.computed('files.@each.willCopy', {
      get: function get() {
        var files = this.files;
        return files.toArray().some(function (file) {
          return file.willCopy;
        });
      }
    }),
    // Actions
    actions: {
      toggleSelectAll: function toggleSelectAll(override) {
        var selectedAll = this.selectedAll,
            files = this.files;
        var shouldSelect = !Ember.isBlank(override) ? override : !selectedAll;

        if (shouldSelect) {
          files.forEach(function (file) {
            return Ember.set(file, 'willCopy', true);
          });
        } else {
          files.forEach(function (file) {
            return Ember.set(file, 'willCopy', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});