define("chimaera/pods/invalid-invite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nvaeCkFW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"accounts_holder\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content_holder\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"register.header\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"twelve columns\"],[12],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"eleven columns\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"register.error.invalidInviteTitle\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,0],[\"register.error.invalidInviteBody1\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,0],[\"register.error.invalidInviteBody2\"],null]],[2,\" \"],[8,\"link-to\",[],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"login.here\"],null]]],\"parameters\":[]}]]],[2,\" \"],[1,[30,[36,0],[\"register.error.invalidInviteBody3\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "chimaera/pods/invalid-invite/template.hbs"
    }
  });

  _exports.default = _default;
});