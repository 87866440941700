define("chimaera/adapters/stored-quote", ["exports", "chimaera/adapters/application", "chimaera/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.APP.TRIP_URL,
    pathForType: function pathForType() {
      return 'storedQuote';
    },
    // to access query params in response
    query: function query(store, type, _query) {
      return this._super.apply(this, arguments).then(function (response) {
        response.queryParams = _query;
        return response;
      });
    }
  });

  _exports.default = _default;
});