define("chimaera/pods/components/confirmation/cost-summary/component", ["exports", "ui-shared/mixins/hide-charge"], function (_exports, _hideCharge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hideCharge.default, {
    classNames: ['seven', 'columns', 'cost-breakdown'],
    currencyService: Ember.inject.service('currency'),
    userService: Ember.inject.service('user'),
    wp: Ember.inject.service('welcome-pack'),
    loadType: Ember.computed.reads('model.loadType'),
    bookingCosts: Ember.computed.alias('model.bookingCosts'),
    currencyType: 'checkout',
    booking: Ember.computed.alias('model'),
    actions: {
      setCurrencyType: function setCurrencyType(currencyType) {
        Ember.set(this, 'currencyType', currencyType);
      }
    }
  });

  _exports.default = _default;
});