define("chimaera/services/ajax", ["exports", "ember-ajax/services/ajax", "chimaera/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    // Services
    userService: Ember.inject.service('user'),
    welcomePack: Ember.inject.service(),
    session: Ember.inject.service(),
    // State
    trustedHosts: _environment.default.APP.TRUSTED_HOSTS,
    sessionDate: new Date().getTime(),
    contentType: 'application/json; charset=utf-8',
    // Computed Properties
    headers: Ember.computed('userService.loggedinUser.token', function () {
      var token = this.get('userService.loggedinUser.token');
      var sessionDate = this.sessionDate;
      return {
        Authorization: "Bearer ".concat(token),
        'X-Tenant-Id': this.get('welcomePack.wp.id'),
        'X-Session-Id': sessionDate,
        'Content-Type': 'application/json; charset=utf-8'
      };
    }),
    // Methods
    request: function request() {
      var _this = this;

      return this._super.apply(this, arguments).catch(function (error) {
        if (error.status === 401) {
          _this.get('session').invalidate();
        }

        throw error;
      });
    }
  });

  _exports.default = _default;
});