define("chimaera/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.loadFixtures('countries');
    server.loadFixtures('welcome-packs');
    server.loadFixtures('translations');
    server.loadFixtures('suppliers');
    server.loadFixtures('statusMappings');
    server.loadFixtures('bookingReferenceTypes');
    server.loadFixtures('chargeCodes');
    server.loadFixtures('storedQuotes');
    var company = server.create('company');
    server.create('user', {
      companyId: company.id,
      company: company
    });
    server.create('notice'); // Schedules

    server.createList('schedule', 5, {
      type: 'fcl'
    }, 'withSingleLegs');
    server.createList('schedule', 2, {
      type: 'fcl'
    }, 'withMultiLegNoRatesZim');
    server.createList('schedule', 3, {
      type: 'lcl'
    }, 'withMultiLegNoRates');
    server.createList('schedule', 2, {
      type: 'lcl'
    }, 'withMultiLegRatesAndChildren'); // Equipment

    server.create('equipment', {
      name: '20DRY',
      displayName: '20\' Standard',
      isoCode: '22G0'
    });
    server.create('equipment', {
      name: '40HDRY',
      displayName: '40\' High Cube',
      isoCode: '45G0'
    });
    server.create('equipment', {
      name: '40REEFER',
      displayName: '41\' Reefer',
      isoCode: '42R1',
      isReefer: true
    });
    server.create('equipment', {
      name: '40DRY',
      displayName: '40\' Standard',
      isoCode: '42G0',
      deleted: true
    }); // Locations

    server.create('location', {
      matched: 'Delhi',
      matchedCountry: 'india',
      unCode: 'INDEL',
      mode: 'road'
    });
    server.create('location', {
      matched: 'Buffalo',
      matchedCountry: 'united states',
      unCode: 'USBUF',
      mode: 'road'
    }); // Bookings

    server.createList('booking', 1, 'withBookingItems', 'withBookingCosts', 'withPayingParties', 'withLogisticsDetails', 'withReferences', 'withStatusOutForDelivery');
    server.createList('booking', 7, 'withBookingItems', 'withBookingCosts', 'withPayingParties', 'withLogisticsDetails', 'withReferences', 'withStatusContainerInGate');
    server.createList('booking', 15, 'withBookingItems', 'withBookingCosts', 'withPayingParties', 'withLogisticsDetails', 'withReferences', 'withStatusBookingReceived');
    server.createList('booking', 3, 'withBookingItems', 'withBookingCosts', 'withPayingParties', 'withLogisticsDetails', 'withReferences', 'withStatusAtd');
  }
});