define("chimaera/models/booking-item-detail", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable", "chimaera/services/welcome-pack"], function (_exports, _model, _attr, _relationships, _emberDataCopyable, _welcomePack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    // Attributes
    commodityCode: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    distanceUnit: (0, _attr.default)('string'),
    heightPerUnit: (0, _attr.default)('string'),
    lengthPerUnit: (0, _attr.default)('string'),
    marks: (0, _attr.default)('string'),
    numberOfPackages: (0, _attr.default)('number'),
    packagingCode: (0, _attr.default)('string'),
    totalValue: (0, _attr.default)('string'),
    typeOfPackaging: (0, _attr.default)('string'),
    valueCurrency: (0, _attr.default)('string'),
    volume: (0, _attr.default)('string'),
    volumeUnit: (0, _attr.default)('string', {
      defaultValue: 'CubicMeters'
    }),
    weight: (0, _attr.default)('string'),
    weightPerUnit: (0, _attr.default)('string'),
    weightUnit: (0, _attr.default)('string'),
    widthPerUnit: (0, _attr.default)('string'),
    // Associations
    bookingItem: (0, _relationships.belongsTo)(),
    // Computed Properties
    mainLegCountries: Ember.computed('bookingItem.booking.logisticsDetails.[]', {
      get: function get() {
        var logisticsDetails = Ember.get(this.bookingItem, 'booking.logisticsDetails');
        var mainLeg = logisticsDetails.find(function (logisticsDetail) {
          return (0, _welcomePack.isMainMode)(logisticsDetail.mode);
        });
        return {
          fromCountry: mainLeg.fromCountry,
          toCountry: mainLeg.toCountry
        };
      }
    }),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  };
  _exports.schema = schema;

  var _default = _model.default.extend(_emberDataCopyable.default, schema);

  _exports.default = _default;
});