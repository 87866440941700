define("chimaera/models/additional-service", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    // Attributes
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    longDescriptionCode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    shortDescriptionCode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    tncTranslation: (0, _attr.default)('string'),
    imageUrl: (0, _attr.default)('string'),
    costs: (0, _attr.default)(),
    // array
    fromAmount: (0, _attr.default)('string'),
    costsAmountTotal: (0, _attr.default)('string'),
    isDefault: (0, _attr.default)('boolean')
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});