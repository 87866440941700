define("chimaera/models/child", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    // Attributes
    eta: (0, _attr.default)('date-yyyy-mm-dd'),
    etd: (0, _attr.default)('date-yyyy-mm-dd'),
    from: (0, _attr.default)('string'),
    fromCountry: (0, _attr.default)('string'),
    fromUNCode: (0, _attr.default)('string'),
    lloydsNumber: (0, _attr.default)('string'),
    mode: (0, _attr.default)('string'),
    supplierName: (0, _attr.default)('string'),
    to: (0, _attr.default)('string'),
    toCountry: (0, _attr.default)('string'),
    toUNCode: (0, _attr.default)('string'),
    transit: (0, _attr.default)('string'),
    // UI only
    transitTime: (0, _attr.default)('number'),
    vesselName: (0, _attr.default)('string'),
    vesselRegisteredCountry: (0, _attr.default)('string'),
    voyageNumber: (0, _attr.default)('string'),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});