define("chimaera/pods/booking/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yys4lOSo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"model\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"booking/booking-form\",[],[[\"@bookingId\",\"@model\",\"@modelFiles\",\"@bookingNote\",\"@onSave\",\"@showNewBookingNote\",\"@commodities\",\"@offlineQuoteRef\",\"@storedQuoteRef\",\"@fromStoredQuote\",\"@importExport\",\"@filteredAllQueryParams\",\"@onFileRemoved\",\"@onFileUploaded\"],[[32,0,[\"bookingId\"]],[32,0,[\"model\"]],[32,0,[\"modelFiles\"]],[32,0,[\"bookingNote\"]],[30,[36,0],[[32,0],\"onSave\"],null],true,[32,0,[\"commodities\"]],[32,0,[\"offlineQuoteRef\"]],[32,0,[\"storedQuoteRef\"]],[32,0,[\"fromStoredQuote\"]],[32,0,[\"importExport\"]],[32,0,[\"filteredAllQueryParams\"]],[30,[36,0],[[32,0],\"refreshModelFiles\"],null],[30,[36,0],[[32,0],\"refreshModelFiles\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"k-loading\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "chimaera/pods/booking/new/template.hbs"
    }
  });

  _exports.default = _default;
});