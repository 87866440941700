define("chimaera/mirage/factories/booking-item-detail", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    typeOfPackaging: 'BAG-IN-BOX',
    packagingCode: 'BIB',
    numberOfPackages: function numberOfPackages() {
      return _faker.default.random.number({
        min: 100,
        max: 1000
      });
    },
    description: function description() {
      return _faker.default.lorem.sentence();
    },
    commodityCode: function commodityCode() {
      return '4819.30';
    },
    marks: function marks() {
      return "PO_".concat(_faker.default.random.number({
        min: 500,
        max: 1000
      }));
    },
    weight: function weight() {
      return _faker.default.random.number({
        min: 100,
        max: 1000
      });
    },
    weightUnit: function weightUnit() {
      return 'KG';
    },
    volume: function volume() {
      return _faker.default.random.number({
        min: 100,
        max: 1000
      });
    },
    volumeUnit: function volumeUnit() {
      return 'CubicMeters';
    },
    totalValue: function totalValue() {
      return _faker.default.random.number({
        min: 100,
        max: 1000
      });
    },
    valueCurrency: function valueCurrency() {
      return 'USD';
    }
  });

  _exports.default = _default;
});