define("chimaera/pods/components/booking/booking-notes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('bookingNotes', []);
    },
    classNames: ['notes-panel'],
    classNameBindings: ['obsShow:show'],
    bookingNoteService: Ember.inject.service('booking-note'),
    store: Ember.inject.service('store'),
    booking: null,
    bookingNotes: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.store.query('booking-note', {
        bookingId: this.booking.id
      }).then(function (notes) {
        _this.set('bookingNotes', notes.toArray());
      });
    },
    hideNotesList: Ember.computed('bookingNotes.[]', {
      get: function get() {
        return Ember.isBlank(this.bookingNotes) && this.bookingNotes.length == 0;
      }
    }),
    obsShow: Ember.computed('bookingNoteService.noteBarState', {
      get: function get() {
        return this.bookingNoteService.noteBarState === 'show';
      }
    }),
    actions: {
      openModal: function openModal() {
        this.bookingNoteService.openNoteModal(this.booking.id, this.bookingNotes);
      },
      closeNoteBar: function closeNoteBar() {
        this.bookingNoteService.setNoteBarState('hide');
      }
    }
  });

  _exports.default = _default;
});