define("chimaera/models/paying-party", ["exports", "ember-data", "ember-data-copyable"], function (_exports, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_emberDataCopyable.default, {
    companyName: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    address3: _emberData.default.attr('string'),
    address4: _emberData.default.attr('string'),
    cityTown: _emberData.default.attr('string'),
    postCode: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    vatNo: _emberData.default.attr('string'),
    booking: _emberData.default.belongsTo('booking')
  });

  _exports.default = _default;
});