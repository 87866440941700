define("chimaera/models/leg", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    // Attributes
    eta: (0, _attr.default)('date-yyyy-mm-dd'),
    etd: (0, _attr.default)('date-yyyy-mm-dd'),
    from: (0, _attr.default)('string'),
    fromCountry: (0, _attr.default)('string'),
    fromUNCode: (0, _attr.default)('string'),
    vgmCutOffTime: (0, _attr.default)('datetime-utc'),
    siDocCutOffTime: (0, _attr.default)('datetime-utc'),
    latestPortArrivalTime: (0, _attr.default)('datetime-utc'),
    lloydsNumber: (0, _attr.default)('string'),
    mode: (0, _attr.default)('string'),
    supplierName: (0, _attr.default)('string'),
    to: (0, _attr.default)('string'),
    toCountry: (0, _attr.default)('string'),
    total: (0, _attr.default)('stringToFloat'),
    toUNCode: (0, _attr.default)('string'),
    transit: (0, _attr.default)('string'),
    // UI only
    transitTime: (0, _attr.default)('number'),
    vesselName: (0, _attr.default)('string'),
    vesselRegisteredCountry: (0, _attr.default)('string'),
    voyage: (0, _attr.default)('string'),
    // Associations
    rates: (0, _relationships.hasMany)(),
    children: (0, _relationships.hasMany)(),
    issues: (0, _relationships.hasMany)(),
    rateGroupings: Ember.computed('rates.[]', {
      get: function get() {
        return this.rates.reduce(function (a, rate) {
          if (!a[rate.chargeEnd]) {
            a[rate.chargeEnd] = [];
          }

          a[rate.chargeEnd].pushObject(rate);
          return a;
        }, {
          Freight: [],
          Origin: [],
          Destination: []
        });
      }
    }),
    // Methods
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  };
  _exports.schema = schema;

  var _default = _model.default.extend(schema);

  _exports.default = _default;
});