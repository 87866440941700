define("chimaera/serializers/schedule", ["exports", "ember-data", "chimaera/serializers/application", "ui-shared/utils/serializer-quote-query-params", "ui-shared/utils/modes"], function (_exports, _emberData, _application, _serializerQuoteQueryParams, _modes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      legs: {
        embedded: 'always'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'schedule';
    },
    normalize: function normalize(model, hash) {
      var legs = hash.legs;
      hash.loadType = legs.filter(function (leg) {
        return leg.mode === 'cfs';
      }).length ? 'lcl' : 'fcl';
      hash.etd = legs.firstObject.etd;
      hash.rateType = (0, _serializerQuoteQueryParams.getRateType)(hash.legs);
      hash.legs = this._setLegsTransit(hash.legs);
      return this._super.apply(this, arguments);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, this._filterSchedulesWithoutIssues(payload), id, requestType);
    },
    _filterSchedulesWithoutIssues: function _filterSchedulesWithoutIssues(_ref) {
      var id = _ref.id,
          multiLegScheduleWithRate = _ref.multiLegScheduleWithRate;
      var issueItems = [];
      var filteredSchedules = multiLegScheduleWithRate.filter(function (item) {
        var issueLegs = item.legs.filter(function (legItem) {
          return legItem.issues && legItem.issues.length;
        });

        if (issueLegs.length) {
          issueItems.pushObject(item);
          return false;
        } // add parent quote id


        item['quoteId'] = id;
        return item;
      });

      if (issueItems.length) {
        // eslint-disable-next-line no-console
        console.log("The following schedules by id have issues: ".concat(issueItems.mapBy('id').join(' ,')));
      }

      return {
        multiLegScheduleWithRate: filteredSchedules
      };
    },
    _setLegsTransit: function _setLegsTransit(legs) {
      var _this = this;

      return legs.reduce(function (a, leg, i) {
        leg.transit = 'default';

        if (!i) {
          if (_modes.roadModes.includes(leg.mode) && legs[1] && legs[1].mode === 'cfs') {
            leg.transit = 'doorToCfs';
          }

          if (_modes.railModes.includes(leg.mode) && legs[1] && legs[1].mode === 'cfs') {
            leg.transit = 'railToCfs';
          }
        }

        if (i > 0) {
          if (_modes.roadModes.includes(leg.mode) && legs[i - 1].mode === 'cfs') {
            leg.transit = 'cfsToDoor';
          }

          if (_modes.railModes.includes(leg.mode) && legs[i - 1].mode === 'cfs') {
            leg.transit = 'cfsToRail';
          }
        }

        if (leg.children.length) {
          legs.children = _this._setChildrenTransit(leg.children);
        }

        return [].concat(_toConsumableArray(a), [leg]);
      }, []);
    },
    _setChildrenTransit: function _setChildrenTransit(children) {
      return children.reduce(function (a, child, i) {
        child.transit = 'default';

        if (child.mode === 'sea') {
          child.transit = 'portToPort';
        }

        if (i > 0) {
          if (child.mode === 'cfs' && children[i - 1].mode === 'sea') {
            child.transit = 'portToCfs';
          }

          if (_modes.railModes.includes(child.mode) && children[i - 1].mode === 'cfs') {
            child.transit = 'cfsToRail';
          }
        }

        return [].concat(_toConsumableArray(a), [child]);
      }, []);
    }
  });

  _exports.default = _default;
});