define("chimaera/pods/components/dashboard/copy-booking-modal/section/shipment-details/component", ["exports", "chimaera/pods/components/dashboard/copy-booking-modal/section/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // State
    selectAllAttributes: ['to', 'from'],
    // Computed Properties
    selectedAll: Ember.computed('booking.copyAttributes.[]', {
      get: function get() {
        var selectAllAttributes = this.selectAllAttributes,
            booking = this.booking;
        var copyAttributes = booking.copyAttributes;

        if (!copyAttributes) {
          return;
        }

        return selectAllAttributes.every(function (attr) {
          return copyAttributes.includes(attr);
        });
      }
    }),
    selectedSome: Ember.computed('booking.copyAttributes.[]', {
      get: function get() {
        var selectAllAttributes = this.selectAllAttributes,
            booking = this.booking;
        var copyAttributes = booking.copyAttributes;

        if (!copyAttributes) {
          return;
        }

        return selectAllAttributes.toArray().some(function (attr) {
          return copyAttributes.includes(attr);
        });
      }
    }),
    // Actions
    actions: {
      toggleSelectAll: function toggleSelectAll(override) {
        var selectAllAttributes = this.selectAllAttributes,
            selectedAll = this.selectedAll,
            booking = this.booking;

        if (!booking.copyAttributes) {
          Ember.set(booking, 'copyAttributes', []);
        }

        var copyAttributes = booking.copyAttributes;
        var shouldSelect = !Ember.isBlank(override) ? override : !selectedAll;

        if (shouldSelect) {
          selectAllAttributes.forEach(function (attr) {
            if (!copyAttributes.includes(attr)) {
              copyAttributes.pushObject(attr);
            }
          });
        } else {
          selectAllAttributes.forEach(function (attr) {
            if (copyAttributes.includes(attr)) {
              copyAttributes.removeObject(attr);
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});