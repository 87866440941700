define("chimaera/pods/components/booking/form-type/component", ["exports", "chimaera/mixins/booking/si-check"], function (_exports, _siCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_siCheck.default, {
    classNames: ['row', 'bc_lading'],
    classNameBindings: ['hideSiFields:display-none'],
    fullNameChanged: Ember.observer('originalForms', function () {
      if (!this.originalForms) {
        this.set('numberOfForms', null);
      }
    })
  });

  _exports.default = _default;
});