define("chimaera/pods/settings/registration/route", ["exports", "chimaera/pods/user/validation", "ember-changeset-validations", "ember-changeset"], function (_exports, _validation, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service('user'),
    model: function model(params) {
      if (this.userService.invitedUser) {
        var changeset = new _emberChangeset.default(this.store.createRecord('user', {
          email: this.userService.invitedUser.email,
          isPrimary: false,
          inviteToken: this.userService.invitedUser.inviteToken
        }), (0, _emberChangesetValidations.default)(_validation.default), _validation.default);
        return {
          user: changeset,
          tandcChecked: false
        };
      } else {
        var _changeset = new _emberChangeset.default(this.store.createRecord('user', {
          email: params.email,
          isPrimary: true
        }), (0, _emberChangesetValidations.default)(_validation.default), _validation.default);

        return {
          user: _changeset,
          company: this.store.createRecord('company'),
          tandcChecked: false,
          companyAddress: this.store.createRecord('company-address', {
            addressType: 'primary',
            sameAsInvoice: true
          }),
          billingAddress: this.store.createRecord('company-address', {
            addressType: 'invoice'
          })
        };
      }
    }
  });

  _exports.default = _default;
});