define("chimaera/utils/string", ["exports", "@cetus/kontainers-ui-core/utils/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "safeLowercase", {
    enumerable: true,
    get: function get() {
      return _string.safeLowercase;
    }
  });
  Object.defineProperty(_exports, "safeTrim", {
    enumerable: true,
    get: function get() {
      return _string.safeTrim;
    }
  });
  Object.defineProperty(_exports, "strToPojo", {
    enumerable: true,
    get: function get() {
      return _string.strToPojo;
    }
  });
  Object.defineProperty(_exports, "pojoToStr", {
    enumerable: true,
    get: function get() {
      return _string.pojoToStr;
    }
  });
});