define("chimaera/pods/components/nav-bar/component", ["exports", "chimaera/mixins/addressbar-help"], function (_exports, _addressbarHelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_addressbarHelp.default, {
    session: Ember.inject.service('session'),
    bookingService: Ember.inject.service('booking'),
    wp: Ember.inject.service('welcome-pack'),
    intl: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    closeIcon: false,
    showCountryDropdown: false,
    logo: Ember.computed('wp', {
      get: function get() {
        return this.wp.wp.get('companyLogo') || '/assets/images/logo.svg';
      }
    }),
    hideDashAndSettingNav: Ember.computed('bookingService.hideDashAndSettingNav', {
      get: function get() {
        return this.bookingService.hideDashAndSettingNav;
      }
    }),
    logoUrl: Ember.computed('wp.logoUrl', {
      get: function get() {
        return Ember.isBlank(this.wp.logoUrl) ? '#' : this.wp.logoUrl;
      }
    }),
    customerUiLogo: Ember.computed.alias('wp.general.customerUiLogo'),
    actions: {
      togglePancake: function togglePancake() {
        this.toggleProperty('closeIcon');
      },
      closePancake: function closePancake() {
        if (this.closeIcon) {
          this.toggleProperty('closeIcon');
        }
      },
      logout: function logout() {
        var _this = this;

        this.session.invalidate().then(function () {
          var endSessionUrl = _this.wp.wp.config['kontainers.auth.oauth2.endSession.url'];

          if (Ember.isBlank(endSessionUrl)) {
            window.location = '/';
          } else {
            window.location = _this.wp.wp.config['kontainers.auth.oauth2.endSession.url'];
          }
        });
      },
      successMessageAction: function successMessageAction(msg) {
        this.flashMessages.success(msg);
      },
      errorMessageAction: function errorMessageAction(msg) {
        this.flashMessages.error(msg, {
          sticky: true
        });
      }
    }
  });

  _exports.default = _default;
});