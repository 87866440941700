define("chimaera/mirage/serializers/schedule", ["exports", "chimaera/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var scheduleRelationships = ['legs'];
  var legRelationships = ['rates', 'children', 'issues'];

  var setRelationships = function setRelationships(parentModel, model, relationships) {
    var _iterator = _createForOfIteratorHelper(relationships),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var relationship = _step.value;

        if (model[relationship]) {
          if (Ember.isArray(model[relationship])) {
            parentModel[relationship] = model[relationship].models.reduce(function (a, m) {
              return [].concat(_toConsumableArray(a), [m.attrs]);
            }, []);
          } else {
            parentModel[relationship] = model[relationship].attrs;
          }
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return parentModel;
  };

  var _default = _application.default.extend({
    serialize: function serialize(schedulesCollection
    /*request*/
    ) {
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _application.default.prototype.serialize.apply(this, arguments);

      var schedule = json.schedule,
          schedules = json.schedules;

      if (schedules) {
        // Relationships
        var _schedule = schedules.reduce(function (a, schedule, i) {
          var model = schedulesCollection.models[i];
          schedule = setRelationships(schedule, model, scheduleRelationships);
          schedule.legs.reduce(function (a, leg, j) {
            var model = schedulesCollection.models[i]._tempAssociations.legs.models[j];
            leg = setRelationships(leg, model, legRelationships);
            return [].concat(_toConsumableArray(a), [leg]);
          }, []);
          return [].concat(_toConsumableArray(a), [schedule]);
        }, []);

        return {
          multiLegScheduleWithRate: _schedule
        };
      }

      schedule = setRelationships(schedule, schedulesCollection, scheduleRelationships);
      schedule.legs.reduce(function (a, leg, i) {
        var model = schedulesCollection.legs.models[i];
        leg = setRelationships(leg, model, legRelationships);
        return [].concat(_toConsumableArray(a), [leg]);
      }, []);
      return {
        schedule: schedule
      };
    }
  });

  _exports.default = _default;
});