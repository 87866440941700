define("chimaera/pods/components/modal/draft-booking/component", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    webStorage: Ember.inject.service(),
    bookingService: Ember.inject.service('booking'),
    // Computed Properties
    draftBooking: Ember.computed.alias('bookingService.draftBooking'),
    createdAt: Ember.computed('draftBooking.createdAt', {
      get: function get() {
        if (!this.draftBooking || !this.draftBooking.createdAt) {
          return;
        }

        return _luxon.DateTime.fromMillis(Date.parse(this.draftBooking.createdAt));
      }
    }),
    // Actions
    actions: {
      restoreDraftBooking: function restoreDraftBooking() {
        var params = this.draftBooking.params;
        params['restoreDraft'] = true;
        this.router.transitionTo('booking.new', {
          queryParams: params
        });
        this.set('draftBooking', null);
      },
      clearDraftBooking: function clearDraftBooking() {
        var webStorage = this.webStorage;
        webStorage.removeLocal('draft-booking');
        this.set('draftBooking', null);
      }
    }
  });

  _exports.default = _default;
});