define("chimaera/pods/components/booking/file-item/component", ["exports", "chimaera/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fileSaver.default, {
    ajax: Ember.inject.service('ajax'),
    userService: Ember.inject.service('user'),
    intl: Ember.inject.service(),
    wp: Ember.inject.service('welcome-pack'),
    tagName: 'tr',
    companyId: null,
    showVaidation: false,
    init: function init() {
      this._super.apply(this, arguments);

      var emptyOption = {
        value: '',
        display: this.intl.t('dashboard.fileUpload.selectFileType')
      };
      var otherOption = {
        value: 'other',
        display: this.intl.t('dashboard.fileUpload.other')
      };
      this.fileTypes = Array.from(this.wp.bookingDocumentDropdown);
      this.fileTypes.unshift(emptyOption);
      this.fileTypes.push(otherOption);
    },
    didInsertElement: function didInsertElement() {
      var description = this.get('file.description');

      if (this.fileTypes.some(function (fileType) {
        return fileType.value === description;
      })) {
        this.set('selectedVal', description);
      } else if (Ember.isBlank(description)) {
        this.set('selectedVal', '');
      } else {
        this.set('selectedVal', 'other');
      }
    },
    showSave: Ember.computed.oneWay('file.hasDirtyAttributes'),
    showDropdown: Ember.computed('selectedVal', {
      get: function get() {
        return this.selectedVal !== 'other';
      }
    }),
    isBookingOwner: Ember.computed('file.uploadedBy', {
      get: function get() {
        return this.file.uploadedBy === this.userService.loggedinUser.id;
      }
    }),
    secureFileUrl: Ember.computed('file.{bookingId,displayFileName,id}', {
      get: function get() {
        return "".concat(_environment.default.APP.BOOKING_URL, "/bookings/").concat(this.file.bookingId, "/files/").concat(this.file.id, "/content/").concat(this.file.displayFileName);
      }
    }),
    actions: {
      resetDesc: function resetDesc() {
        this.set('selectedVal', '');
        this.set('file.description', '');
      },
      save: function save() {
        Ember.set(this, 'showValidation', false);
        var description = this.file.description;

        if (this.selectedVal === 'other') {
          if (Ember.isBlank(description)) {
            Ember.set(this, 'showValidation', true);
            return;
          }
        }

        this.file.save();
      },
      selectedDescriptionChanged: function selectedDescriptionChanged() {
        if (this.selectedVal === 'other') {
          this.set('file.description', '');
        } else {
          this.set('file.description', this.selectedVal);
        }
      },
      removeFile: function removeFile(file) {
        this.doRemove(file);
      },
      ajaxFileDownload: function ajaxFileDownload(fileName) {
        var _this = this;

        this.ajax.request(this.secureFileUrl, {
          method: 'GET',
          dataType: 'arraybuffer',
          processData: false
        }).then(function (file) {
          _this.saveFileAs(fileName, file);
        });
      }
    }
  });

  _exports.default = _default;
});