define("chimaera/pods/components/modal/note-lookup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "50BNpuiN",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[10,\"a\"],[14,0,\"close-bclever-modal btn_close\"],[15,\"onclick\",[30,[36,0],[[32,0],\"closeModal\"],null]],[12],[13],[2,\"\\n\"],[10,\"h2\"],[12],[1,[30,[36,1],[\"booking.notesModal.header\"],null]],[13],[2,\"\\n\"],[8,\"core/form\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"one columns\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ten columns\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-group field_note\"],[12],[2,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,[36,2],[[32,0],\"noteValue\"],null]],[12],[1,[30,[36,1],[\"booking.notesModal.label\"],null]],[13],[2,\"\\n        \"],[8,[32,1,[\"textarea\"]],[],[[\"@placeholder\",\"@model\",\"@property\",\"@context\"],[[30,[36,1],[\"booking.notesModal.placeholder\"],null],[32,0],\"noteValue\",[32,0]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"one columns\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"one columns\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"eight columns\"],[12],[10,\"em\"],[14,0,\"note-info icon icon_info\"],[12],[1,[30,[36,1],[\"booking.notesModal.note\"],null]],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"two columns\"],[12],[10,\"a\"],[14,0,\"bc_button close-bclever-modal\"],[15,\"onclick\",[30,[36,0],[[32,0],\"saveNote\"],null]],[12],[1,[30,[36,1],[\"booking.notesModal.action\"],null]],[13],[2,\" \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"guid-for\"]}",
    "meta": {
      "moduleName": "chimaera/pods/components/modal/note-lookup/template.hbs"
    }
  });

  _exports.default = _default;
});