define("chimaera/models/booking-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-copyable"], function (_exports, _model, _attr, _relationships, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.schema = void 0;
  var schema = {
    // Attributes
    airFlow: (0, _attr.default)('number'),
    airFlowUnit: (0, _attr.default)('string', {
      defaultValue: 'CubicMeters'
    }),
    containerDetail: (0, _attr.default)('string'),
    containerNumber: (0, _attr.default)('string'),
    containerType: (0, _attr.default)('string'),
    copiedFromBookingId: (0, _attr.default)('string'),
    expectedCargoReadyTime: (0, _attr.default)('string'),
    flexitank: (0, _attr.default)('boolean', {
      allowNull: true,
      defaultValue: null
    }),
    humidity: (0, _attr.default)('number'),
    loadingReference: (0, _attr.default)('string'),
    pickupTime: (0, _attr.default)('string'),
    sealNumber: (0, _attr.default)('string'),
    shipperOwnedContainer: (0, _attr.default)('boolean', {
      allowNull: true,
      defaultValue: null
    }),
    shippingMark: (0, _attr.default)('string'),
    temperature: (0, _attr.default)('number'),
    temperatureUnit: (0, _attr.default)('string'),
    vgm: (0, _attr.default)('string'),
    volume: (0, _attr.default)('string'),
    weight: (0, _attr.default)('string'),
    weightUnit: (0, _attr.default)('string', {
      defaultValue: 'KG'
    }),
    // Associations
    bookingItemDetails: (0, _relationships.hasMany)('booking-item-detail'),
    deliveryAddress: (0, _relationships.belongsTo)('booking-address'),
    pickupAddress: (0, _relationships.belongsTo)('booking-address'),
    booking: (0, _relationships.belongsTo)({
      async: false
    }),
    // Computed Properties
    containerDetailDisplay: Ember.computed('containerDetail', function () {
      if (Ember.isBlank(this.containerDetail)) {
        return 'Click here to enter cargo details';
      } else {
        return this.containerDetail;
      }
    }),
    // Methods
    addNullBookingItemDetail: function addNullBookingItemDetail() {
      var currentBookingItemDetail = this.store.createRecord('booking-item-detail', {
        typeOfPackaging: '',
        numberOfPackages: null
      });
      this.bookingItemDetails.addObject(currentBookingItemDetail);
      return currentBookingItemDetail;
    },
    toJSON: function toJSON(options) {
      return this.serialize(options);
    }
  };
  _exports.schema = schema;

  var _default = _model.default.extend(_emberDataCopyable.default, schema);

  _exports.default = _default;
});