define("chimaera/mixins/addressbar-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    openContacts: false,
    addressContext: '',
    openNewAddress: false,
    bookingNoteService: Ember.inject.service('booking-note'),
    addressTypes: Object.freeze(['consignorBookingAddress', 'consigneeBookingAddress', 'notifyBookingAddress']),
    _copyAddy: function _copyAddy(addToCopy, model, property) {
      var copied = {
        address1: addToCopy.get('address1'),
        address2: addToCopy.get('address2'),
        address3: addToCopy.get('address3'),
        address4: addToCopy.get('address4'),
        cityTown: addToCopy.get('cityTown'),
        companyName: addToCopy.get('companyName'),
        region: addToCopy.get('region'),
        country: addToCopy.get('country'),
        postCode: addToCopy.get('postCode'),
        firstName: addToCopy.get('firstName'),
        lastName: addToCopy.get('lastName'),
        email: addToCopy.get('email'),
        addressText: addToCopy.singleLine,
        phone: addToCopy.get('phone'),
        fax: addToCopy.get('fax'),
        customerCode: addToCopy.get('customerCode'),
        vatNo: addToCopy.get('vatNo')
      };

      if (!Ember.isBlank(model)) {
        if (Ember.isBlank(model.get("".concat(property, ".content")))) {
          model.set(property, this.store.createRecord('booking-address', copied));
        } else {
          model.get(property).setProperties(copied);
        }
      }

      return copied;
    },
    actions: {
      toggleContacts: function toggleContacts() {
        this.set('openContacts', true);
        this.bookingNoteService.setNoteBarState('hide');
      },
      toggleContactsWithContext: function toggleContactsWithContext(context, booking) {
        if (booking) {
          this._removeAddressValidationFromBooking(booking);
        }

        this.set('addressContext', context);
        this.set('openContacts', true);
        this.set('openNewAddress', false);
        this.bookingNoteService.setNoteBarState('hide');
      },
      newAddy: function newAddy(context, booking) {
        if (booking) {
          this._removeAddressValidationFromBooking(booking);
        }

        this.set('addressContext', context);
        this.set('openNewAddress', true);
        this.set('openContacts', false);
        this.bookingNoteService.setNoteBarState('hide');
      },
      setAddy: function setAddy(addy) {
        switch (this.addressContext) {
          case 'to':
            this._copyAddy(addy, this.model, 'consigneeBookingAddress');

            break;

          case 'from':
            this._copyAddy(addy, this.model, 'consignorBookingAddress');

            break;

          case 'notifyParty':
            this._copyAddy(addy, this.model, 'notifyBookingAddress');

            break;

          case 'brokerage':
            this._copyAddy(addy, this.model, 'brokerageAddress');

            break;

          default:
            {
              var biModel = this.model.bookingItems.objectAt(this.addressContext.split(':')[1]);

              if (this.addressContext.indexOf('pickup') > -1) {
                this._copyAddy(addy, biModel, 'pickupAddress');
              } else {
                this._copyAddy(addy, biModel, 'deliveryAddress');
              }
            }
        }
      }
    },

    /**
     * When the user opens the address bar, we want to clear any
     * address model validation, which will reappear if 422 response
     * for address attrs
     */
    _removeAddressValidationFromBooking: function _removeAddressValidationFromBooking(booking) {
      var _iterator = _createForOfIteratorHelper(this.addressTypes),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var addressType = _step.value;

          if (booking.errors.has(addressType)) {
            booking.errors.remove(addressType);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  });

  _exports.default = _default;
});